import React, { useEffect, useState } from 'react'
import NamedMultiSelect from '../../../common/NamedMultiSelect'
import { getShopifyCollections } from '../../../../api/integrations/shopify'
import { err } from '../../../../utils/functions'
import { ShopifyCustomCollection } from '../../../../types/Shopify.types'
import Typography from '@mui/joy/Typography'

export default function ShopifyCollectionSelect({
  label,
  value,
  collections: defaultCollections,
  addValues,
  onSelect,
  onBlur,
}: {
  label?: string
  value: number[]
  addValues?: string[]
  collections?: ShopifyCustomCollection[]
  onSelect?: (values: string[]) => void
  onBlur?: (values: string[]) => void
}): JSX.Element {
  const [collections, setCollections] = useState<
    ShopifyCustomCollection[] | undefined
  >(defaultCollections)

  const [selectedCollections, setSelectedCollections] = useState<
    ShopifyCustomCollection[]
  >(defaultCollections?.filter((c) => value.includes(c.id)) || [])

  useEffect(() => {
    if (collections) return
    getShopifyCollections()
      .then((res) => {
        if (res.success && res.data) {
          setCollections(res.data)
        }
      })
      .catch((e) => err(e))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSelect = (values: string[]) => {
    const newSelectedCollections = collections?.filter((c) =>
      values.includes(`${c.title}`),
    )
    setSelectedCollections(newSelectedCollections || [])
    // Call on blur for clear events
    if (!values.length) {
      onBlur?.(values)
    }
    onSelect?.(values)
  }

  return (
    <NamedMultiSelect
      limitTags={1}
      name={label}
      placeholder="Collections"
      value={selectedCollections.map((c) => c.title) || []}
      onBlur={() => onBlur?.(selectedCollections.map((c) => c.title))}
      onChange={(value) => handleSelect(value)}
      options={collections?.map((c) => c.title)}
      freeSolo={true}
      helperText={
        addValues?.length ? (
          <Typography level="body-sm">
            Add new collections:{' '}
            {addValues.map((title, i) => (
              <Typography level="title-sm" key={title}>
                <strong>{title}</strong>
                {i !== addValues.length - 1 ? ', ' : ''}
              </Typography>
            ))}
          </Typography>
        ) : null
      }
    />
  )
}
