import * as React from 'react'
import Grid from '@mui/material/Grid'
import FloatLabelInput from './FloatLabelInput'
import { SxProps, Theme } from '@mui/material'
import Typography from '@mui/joy/Typography'

export default function NamedInput({
  value,
  name,
  renderName,
  onChange,
  onNameChange,
  onClick,
  onBlur,
  onNameBlur,
  onFocus,
  onKeyDown,
  label,
  nameLabel,
  placeholder,
  namePlaceholder,
  type,
  size,
  fullWidth,
  required,
  disabled,
  disableInput,
  readOnly,
  errorText,
  helperText,
  autoComplete,
  autoCapitalize,
  autoCorrect,
  nameEditable,
  xs,
  sm,
  md,
  lg,
  format,
  endDecorator,
  sx,
  split,
  button,
}: {
  value?: string | number
  name?: string | number
  renderName?: boolean
  onNameChange?: (value: string) => void
  onChange?: (value: string) => void
  onBlur?: (e?: React.FocusEvent<HTMLInputElement, Element>) => void
  onNameBlur?: (e?: React.FocusEvent<HTMLInputElement, Element>) => void
  onClick?: () => void
  onFocus?: () => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void
  label?: string | JSX.Element
  nameLabel?: string | JSX.Element
  placeholder?: string
  namePlaceholder?: string
  type?: React.HTMLInputTypeAttribute
  size?: 'sm' | 'md' | 'lg'
  fullWidth?: boolean
  required?: boolean
  disabled?: boolean
  disableInput?: boolean
  readOnly?: boolean
  errorText?: string
  helperText?: string | React.ReactNode
  autoComplete?: string
  autoCapitalize?: boolean
  autoCorrect?: boolean
  nameEditable?: boolean
  xs?: [number, number]
  sm?: [number, number]
  md?: [number, number]
  lg?: [number, number]
  format?: 'USD'
  endDecorator?: React.ReactNode
  sx?: SxProps<Theme> | undefined
  split?: [number, number]
  button?: React.ReactNode
}): JSX.Element {
  return (
    // <Input
    //   value={value}
    //   onChange={(e) => onChange?.(e.target.value)}
    //   fullWidth={fullWidth}
    //   error={!!errorText}
    //   slots={{ input: (props) => <InnerInput {...props} label={label} /> }}
    //   slotProps={{
    //     input: { placeholder: 'A placeholder' },
    //   }}
    //   sx={{
    //     '--Input-minHeight': '56px',
    //     '--Input-radius': '6px',
    //   }}
    // />

    <Grid container spacing={1} sx={sx}>
      {renderName !== false && (
        <Grid
          item
          xs={xs?.[0] || 12}
          sm={sm?.[0] || 12}
          md={md?.[0] || 3}
          lg={lg?.[0] || 2}
        >
          {nameEditable ? (
            <FloatLabelInput
              value={name}
              onChange={onNameChange}
              onBlur={onNameBlur}
              label={nameLabel}
              placeholder={namePlaceholder}
              type={type}
              size={size}
              fullWidth={fullWidth}
              required={required}
              disabled={disabled}
              readOnly={readOnly}
              errorText={errorText}
              helperText={helperText}
              autoComplete={autoComplete}
              autoCapitalize={autoCapitalize}
              autoCorrect={autoCorrect}
              format={format}
            />
          ) : (
            <Typography
              level="title-md"
              sx={{
                pt: '0.5em',
                color: disabled ? 'rgba(0, 0, 0, 0.38);' : undefined,
              }}
            >
              <strong>{name}</strong>
            </Typography>
          )}
        </Grid>
      )}
      <Grid
        item
        xs={renderName === false ? 12 : xs?.[1] || 12}
        sm={renderName === false ? 12 : sm?.[1] || 12}
        md={renderName === false ? 12 : md?.[1] || 9}
        lg={renderName === false ? 12 : lg?.[1] || 10}
      >
        <FloatLabelInput
          value={value}
          onChange={onChange}
          onClick={onClick}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          label={label}
          placeholder={placeholder}
          type={type}
          size={size}
          fullWidth={fullWidth}
          required={required}
          disabled={disabled || disableInput}
          readOnly={readOnly}
          errorText={errorText}
          helperText={helperText}
          autoComplete={autoComplete}
          autoCapitalize={autoCapitalize}
          autoCorrect={autoCorrect}
          format={format}
          endDecorator={endDecorator}
          button={button}
        />
      </Grid>
    </Grid>
  )
}
