import React, { useEffect, useState } from 'react'
import { GetProduct, GetProductTemplate } from '../../../../api/product'
import Grid from '@mui/material/Grid'
import { ProductAttributeInputText } from '../../../../pages/products/product/ProductAttributeInputText'
import {
  GetIntegrationResult,
  IntegrationName,
  listProduct,
  unlistProduct,
} from '../../../../api/integration'
import { getDescriptionValue, getTitleValue } from '../../../../utils/functions'
import Alert, { AlertInput, handleAlert } from '../../../common/Alert'
import { DataFields } from '../../../../api/types'

import ListSyncComponent from '../common/ListSyncComponent'
import { ProductTitleDescription } from '../../ProductTitleDescription'
import ListUnlistButtons from '../common/ListUnlistButtons'
import MissingIntegration from '../MissingIntegration'
import NamedSingleSelect from '../../../common/NamedSingleSelect'
import NamedInput from '../../../common/NamedInput'
import MissingTemplateIntegration from '../MissingTemplateIntegration'
import Box from '@mui/joy/Box'
import CircularProgress from '@mui/joy/CircularProgress'
import ShopifyCollectionSelect from './ShopifyCollectionSelect'
import {
  ShopifyCustomCollection,
  ShopifyLocation,
  ShopifyProductIntegration,
  ShopifyTemplateIntegration,
} from '../../../../types/Shopify.types'
import AttributeSelect from '../../../common/AttributeSelect'

interface ShopifyIntegrationComponentProps {
  integration: GetIntegrationResult<IntegrationName.SHOPIFY>
  templateIntegration: ShopifyTemplateIntegration
  productIntegration?: ShopifyProductIntegration
  locations: ShopifyLocation[]
  collections: ShopifyCustomCollection[]
  template: GetProductTemplate
  product?: GetProduct
  onTemplateChange?: (
    name: string,
    templateIntegration: DataFields<ShopifyTemplateIntegration> | undefined,
  ) => void
  onProductChange?: (
    name: string,
    productIntegration: DataFields<ShopifyProductIntegration> | undefined,
  ) => void
  onUpdate?: () => void
}

export default function ShopifyIntegrationComponent({
  integration,
  templateIntegration,
  productIntegration,
  template,
  product,
  locations,
  collections,
  onTemplateChange,
  onProductChange,
  onUpdate,
}: ShopifyIntegrationComponentProps): JSX.Element {
  const [title, setTitle] = useState<string>(
    productIntegration?.title ?? templateIntegration.title ?? '',
  )
  const [description, setDescription] = useState<string>(
    productIntegration?.description ?? templateIntegration.description ?? '',
  )

  const [productType, setProductType] = useState<string>(
    productIntegration?.productType ?? templateIntegration.productType ?? '',
  )
  const [vendor, setVendor] = useState<string>(
    productIntegration?.vendor ?? templateIntegration.vendor ?? '',
  )
  const [tags, setTags] = useState<string>(
    productIntegration?.tags ?? templateIntegration.tags ?? '',
  )
  // const [collectionIds, setCollectionIds] = useState<string>(
  //   productIntegration?.collectionIds ??
  //     templateIntegration.collectionIds ??
  //     '',
  // )
  // const [addCollectionTitles, setAddCollectionTitles] = useState<string[]>([])
  const [selectedCollections, setSelectedCollections] = useState<
    ShopifyCustomCollection[]
  >(
    collections.filter((c) =>
      (productIntegration?.collectionIds || templateIntegration.collectionIds)
        ?.split(',')
        ?.includes(`${c.id}`),
    ),
  )
  const [selectedLocation, setSelectedLocation] = useState<{
    name: string
    id: number
  }>()

  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const [list, setList] = useState<boolean>(
    productIntegration?.list ?? templateIntegration.list ?? false,
  )
  const [sync, setSync] = useState<boolean>(
    productIntegration?.sync ?? templateIntegration.sync ?? false,
  )
  const [syncQuantity, setSyncQuantity] = useState<boolean>(
    productIntegration?.syncQuantity ??
      templateIntegration.syncQuantity ??
      false,
  )

  const [detailUpdate, setDetailUpdate] = useState<number>(0)

  const handleSelectLocation = (locationName: string | undefined) => {
    if (!locationName) return
    const location = locations.find((l) => l.name === locationName)
    if (!location) {
      setSelectedLocation(undefined)
      return
    }
    setSelectedLocation({ id: location.id, name: location.name })
  }

  const handleListProduct = () => {
    const productId = product?.product.id
    if (!productId) {
      return
    }
    listProduct(IntegrationName.SHOPIFY, productId)
      .then((res) => {
        handleAlert(setAlert, res, 'Successfully listed product.')
      })
      .catch((e) => {
        setAlert({
          open: true,
          severity: 'error',
          message: `${e.response.data.message}`,
        })
      })
  }

  const handleUnlistProduct = () => {
    const productId = product?.product.id
    if (!integration.integration?.id || !productId) return
    unlistProduct(
      IntegrationName.SHOPIFY,
      productId,
      integration.integration.id,
    )
      .then((res) => {
        handleAlert(setAlert, res, 'Successfully Unlisted.')
      })
      .catch((e) =>
        setAlert({
          open: true,
          severity: 'error',
          message: e.response.data.message,
        }),
      )
  }

  // Set title and description state
  useEffect(() => {
    const title = getTitleValue(
      productIntegration,
      templateIntegration,
      product,
    )
    const description = getDescriptionValue(
      productIntegration,
      templateIntegration,
      product,
    )

    setTitle(title)
    setDescription(description)
    setDetailUpdate(detailUpdate + 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, template, productIntegration, templateIntegration])

  useEffect(() => {
    if (!integration.integration?.id) return

    if (product) {
      const newShopifyProductIntegration: DataFields<ShopifyProductIntegration> =
        {
          templateIntegrationId: templateIntegration?.id,
          productId: product.product.id,
          title,
          description,
          locationId: selectedLocation ? `${selectedLocation.id}` : '',
          productType,
          collectionIds: selectedCollections.length
            ? selectedCollections.map((c) => c.id).join(',')
            : '',
          vendor,
          tags,
          list,
          sync,
          syncQuantity,
          selected: true,
        }
      onProductChange?.(templateIntegration.name, newShopifyProductIntegration)
      return
    }

    const newShopifyTemplateIntegration: DataFields<ShopifyTemplateIntegration> =
      {
        integrationId: integration.integration.id,
        templateId: template?.template.id,
        title: title || '',
        description: description || '',
        locationId: selectedLocation ? `${selectedLocation.id}` : '',
        productType,
        collectionIds: selectedCollections.length
          ? selectedCollections.map((c) => c.id).join(',')
          : '',
        vendor,
        tags,
        list,
        sync,
        syncQuantity,
        name: templateIntegration.name,
        index: templateIntegration.index,
      }
    onTemplateChange?.(templateIntegration.name, newShopifyTemplateIntegration)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    title,
    description,
    list,
    sync,
    syncQuantity,
    selectedLocation,
    selectedCollections,
  ])

  // const handleSave = async () => {
  //   // Add New Collections
  //   let allCollectionIds = collectionIds
  //   if (addCollectionTitles?.length) {
  //     await createShopifyCollections(addCollectionTitles)
  //       .then((res) => {
  //         if (res.success && res.data) {
  //           // Add created collections
  //           setCollections((collections || []).concat(res.data)) // add created to collections
  //           setAddCollectionTitles([]) // clear add
  //           setSelectedCollections(selectedCollections.concat(res.data)) // set as selected

  //           const newCollectionIds = res.data.map((c) => `${c.id}`)
  //           allCollectionIds = allCollectionIds
  //             .split(',')
  //             .concat(newCollectionIds)
  //             .join(',')
  //         }
  //       })
  //       .catch((e) => err(e))
  //   }
  // }

  const handleSelectCollection = (collectionNames: string[]) => {
    const selectedCollections =
      collections?.filter((c) => collectionNames.includes(c.title)) || []
    setSelectedCollections(selectedCollections)

    // const newCollectionTitles = collectionNames.filter(
    //   (c) => !selectedCollections?.map((s) => s.title).includes(c),
    // )

    // const addCollections = newCollectionTitles.map(
    //   (c): ShopifyCustomCollection => ({
    //     id: 0,
    //     title: c,
    //   }),
    // )

    // setSelectedCollections(selectedCollections.concat(addCollections))
    // setAddCollectionTitles(newCollectionTitles)
    // if (!selectedCollections?.length) {
    //   setCollectionIds('')
    //   return
    // }
    // setCollectionIds(selectedCollections.map((c) => c.id).join(','))
  }

  if (!integration) {
    return <MissingIntegration />
  }

  if (product && !templateIntegration) {
    return (
      <MissingTemplateIntegration
        templateId={product.product.templateId}
        channelName={IntegrationName.SHOPIFY}
      />
    )
  }

  const attributeNames =
    template?.attributes.map((a) => a.attribute.name) ||
    product?.attributes.map((a) => a.templateAttribute.name)

  return (
    <Grid container justifyContent="center" spacing={6} pt={2} px={1}>
      <Grid item xs={12}>
        <ListSyncComponent
          title={true}
          list={list}
          sync={sync}
          syncQuantity={syncQuantity}
          onListChange={setList}
          onSyncChange={setSync}
          onSyncQuantityChange={setSyncQuantity}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <ProductTitleDescription
              key={`product-title-description-${detailUpdate}`}
              template={template}
              product={product}
              title={title}
              description={description}
              required={!!product}
              onTitleChange={setTitle}
              onDescriptionChange={setDescription}
              disableUnparsedEdit={true}
            />
          </Grid>

          {product && (
            <>
              <Grid item xs={12}>
                <ProductAttributeInputText
                  label="SKU"
                  value={product.product.sku}
                  disabled={true}
                />
              </Grid>

              <Grid item xs={12}>
                <ProductAttributeInputText
                  label="Condition"
                  value={product.product.condition}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12}>
                <NamedInput
                  name="Price"
                  nameEditable={false}
                  value={product.product.price}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12}>
                <NamedInput
                  name="Quantity"
                  nameEditable={false}
                  value={product.product.quantity}
                  disabled={true}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <NamedSingleSelect
              name="Shopify Location"
              nameEditable={false}
              value={selectedLocation?.name || ''}
              onChange={(location) => handleSelectLocation(location)}
              options={locations.map((l) => l.name)}
            />
          </Grid>

          <Grid item xs={12}>
            <AttributeSelect
              name="Product Type"
              nameEditable={false}
              value={productType}
              attributeNames={attributeNames}
              multiple={false}
              onChange={(v) => setProductType(v || '')}
            />
          </Grid>

          <Grid item xs={12}>
            <AttributeSelect
              name="Vendor"
              nameEditable={false}
              value={vendor}
              multiple={false}
              attributeNames={attributeNames}
              onChange={(v) => setVendor(v || '')}
            />
          </Grid>

          <Grid item xs={12}>
            <AttributeSelect
              name="Tags"
              values={tags ? tags.split(',') : []}
              onSelect={(values) => setTags((values as string[]).join(','))}
              attributeNames={attributeNames}
              multiple={true}
            />
          </Grid>

          <Grid item xs={12}>
            {collections ? (
              <ShopifyCollectionSelect
                label="Collections"
                collections={collections}
                value={selectedCollections.map((c) => c.id)}
                // addValues={addCollectionTitles}
                onSelect={(value) => handleSelectCollection(value)}
              />
            ) : (
              <Box>
                <CircularProgress />
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <ListUnlistButtons
          title={true}
          list={list}
          hasProductIntegration={!!productIntegration}
          product={product}
          onList={handleListProduct}
          onUnlist={handleUnlistProduct}
        />
      </Grid>

      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Grid>
  )
}
