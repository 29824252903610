import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import {
  Aspect,
  EbayTemplateIntegration,
  SetEbayAspectProductIntegration,
} from '../../../../types/Ebay.types'
import {
  GetProduct,
  GetProductTemplateAttribute,
} from '../../../../api/product'
import { useMemo } from 'react'
import Alert, { AlertInput } from '../../../common/Alert'
import { getAttributeName } from '../../../../utils/functions'
import AttributeSelect from '../../../common/AttributeSelect'
import VisibilityIcon from '@mui/icons-material/Visibility'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import Button from '@mui/joy/Button'

export default function EbayProductAspects({
  product,
  templateIntegration,
  aspects,
  aspectIntegrations,
  templateAttributes,
  disabled,
  onChange,
  onUpdate,
}: {
  product: GetProduct
  templateIntegration?: EbayTemplateIntegration
  aspects: Aspect[]
  aspectIntegrations: SetEbayAspectProductIntegration[]
  templateAttributes: GetProductTemplateAttribute[]
  disabled?: boolean
  onChange: (aspect: SetEbayAspectProductIntegration) => void
  onUpdate?: () => void
}): JSX.Element {
  const [alert, setAlert] = useState<AlertInput>({ open: false })
  const [parseDetails, setParseDetails] = useState<boolean>(true)

  // const handleChange = (aspect: AspectIntegrationInput) => {
  //   onChange(aspect)
  //   // onUpdate?.()
  // }

  const handleAspectValueChange = (
    aspect: Aspect,
    aspectIntegration: SetEbayAspectProductIntegration | undefined,
    newValue: string | undefined,
  ) => {
    // Add new
    if (!aspectIntegration) {
      const newAspectIntegration: SetEbayAspectProductIntegration = {
        aspectName: aspect.localizedAspectName,
        value: newValue || '',
        dataType: aspect.aspectConstraint.aspectDataType,
        required: aspect.aspectConstraint.aspectRequired,
      }
      onChange(newAspectIntegration)
      return
    }

    // Update value of existing
    aspectIntegration.value = newValue || ''
    onChange(aspectIntegration)
  }

  const handleToggleParseDetails = () => {
    setParseDetails(!parseDetails)
  }

  const attributeNames = product.attributes.map((a) => a.templateAttribute.name)
  const aspectIntegrationsRecord: Record<
    string,
    SetEbayAspectProductIntegration
  > = useMemo(() => {
    const aspectIntegrationsRecord: Record<
      string,
      SetEbayAspectProductIntegration
    > = {}
    aspectIntegrations.forEach((aspectIntegration) => {
      aspectIntegrationsRecord[aspectIntegration.aspectName] = aspectIntegration
    })
    return aspectIntegrationsRecord
  }, [aspectIntegrations])

  const productAspectAttributeValues: { [k: string]: string | undefined } =
    useMemo(() => {
      const aspectIntegrationValues: { [k: string]: string | undefined } = {}
      aspectIntegrations.forEach((aspectIntegration) => {
        const aspectValue = aspectIntegration.value
        const attributeName = getAttributeName(aspectValue)
        const attribute = product.attributes.find(
          (a) => a.templateAttribute.name === attributeName,
        )
        if (attribute && parseDetails && attributeName) {
          aspectIntegrationValues[aspectIntegration.aspectName] =
            attribute.attribute?.value
          return
        }
        aspectIntegrationValues[aspectIntegration.aspectName] =
          aspectIntegration.value
      })
      return aspectIntegrationValues
    }, [aspectIntegrations, product.attributes, parseDetails])

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={1}></Grid>
      <Grid item xs={10}>
        <Typography level="h4">Item Specifics</Typography>
      </Grid>
      <Grid item xs={1}>
        <Button
          sx={{ float: 'right' }}
          size="sm"
          variant="plain"
          color="neutral"
          onClick={handleToggleParseDetails}
          endDecorator={
            !parseDetails ? (
              <VisibilityIcon fontSize="small" />
            ) : (
              <AlternateEmailIcon fontSize="small" />
            )
          }
        >
          <Typography level="body-sm">
            {!parseDetails ? 'Parse' : 'View'}
          </Typography>
        </Button>
      </Grid>
      {aspects.map((aspect) => {
        const aspectName = aspect.localizedAspectName
        const aspectIntegration = aspectIntegrationsRecord[aspectName]
        const value = productAspectAttributeValues[aspectName] || ''
        let valueInOptions = false
        const options: string[] = []

        parseDetails &&
          aspect.aspectValues?.forEach((v) => {
            if (v.localizedValue === value) {
              valueInOptions = true
            }
            options.push(v.localizedValue)
          })

        let errorText = ''
        if (!value && aspect.aspectConstraint.aspectRequired && parseDetails) {
          errorText = `Value is required`
        } else if (
          value &&
          aspect.aspectConstraint.aspectMode === 'SELECTION_ONLY' &&
          !valueInOptions &&
          parseDetails
        ) {
          errorText = `"${value}" is not accepted by Ebay`
        }

        let warningText = ''
        if (
          value &&
          aspect.aspectConstraint.aspectMode === 'FREE_TEXT' &&
          aspect.aspectValues?.length &&
          !valueInOptions &&
          parseDetails
        ) {
          warningText = `"${value}" is not in Ebay suggested values`
        }

        return (
          <Grid item xs={12} key={aspectName}>
            {aspect.aspectValues?.length ? (
              <AttributeSelect
                name={aspectName}
                nameEditable={false}
                nameLevel="title-sm"
                freeSolo={aspect.aspectConstraint.aspectMode === 'FREE_TEXT'}
                required={aspect.aspectConstraint.aspectRequired}
                label={aspectName}
                // disabled={!parseDetails}
                errorText={errorText}
                warningText={warningText}
                options={options}
                attributeNames={!parseDetails ? attributeNames : []}
                value={value}
                onChange={(newValue) =>
                  handleAspectValueChange(aspect, aspectIntegration, newValue)
                }
              />
            ) : (
              <AttributeSelect
                name={aspectName}
                nameEditable={false}
                nameLevel="title-sm"
                required={aspect.aspectConstraint.aspectRequired}
                label={aspectName}
                // disabled={!parseDetails}
                value={value}
                errorText={errorText}
                attributeNames={!parseDetails ? attributeNames : []}
                warningText={warningText}
                onChange={(newValue) =>
                  handleAspectValueChange(aspect, aspectIntegration, newValue)
                }
              />
            )}
          </Grid>
        )
      })}

      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Grid>
  )
}
