import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import {
  AspectMetadata,
  CategoryTree,
  EbayAspectProductIntegration,
  EbayAspectTemplateIntegration,
  EbayProductIntegration,
  EbayTemplateIntegration,
  OfferResponse,
  SetEbayAspectProductIntegration,
} from '../../../../types/Ebay.types'
import { getAttributeValue, log } from '../../../../utils/functions'
import {
  GetProduct,
  getProductAspectsForCategory,
  GetProductTemplate,
} from '../../../../api/product'
import { DataFields } from '../../../../api/types'

import EbayCategories, { EbayCategory } from './EbayCategories'
import Alert, { AlertInput, handleAlert } from '../../../common/Alert'
import EbayIntegrationConditions from '../../ConditionsInput'
import EbayProductAspects from './EbayProductAspects'
import { ProductAttributeInputText } from '../../../../pages/products/product/ProductAttributeInputText'
import { ProductTitleDescription } from '../../ProductTitleDescription'
import NamedInput from '../../../common/NamedInput'
import AttributeSelect from '../../../common/AttributeSelect'
import ListSyncComponent from '../common/ListSyncComponent'
import ListUnlistButtons from '../common/ListUnlistButtons'
import {
  IntegrationName,
  listProduct,
  unlistProduct,
} from '../../../../api/integration'
import { APIResult } from '../../../../utils/types'

export type AspectIntegrationInput = Omit<
  DataFields<EbayAspectTemplateIntegration>,
  'ebayTemplateIntegrationId' | 'type'
>

interface EbayProductIntegrationConfigurationComponentProps {
  templateIntegration: EbayTemplateIntegration
  productIntegration?: EbayProductIntegration
  template?: GetProductTemplate
  product: GetProduct
  categories: CategoryTree
  onChange?: (name: string, productIntegration: EbayProductIntegration) => void
  onUpdate?: () => void
}
export default function EbayProductIntegrationConfigurationComponent({
  templateIntegration,
  productIntegration,
  template,
  product,
  categories,
  onChange,
  onUpdate,
}: EbayProductIntegrationConfigurationComponentProps): JSX.Element {
  const [category, setCategory] = useState<EbayCategory | undefined>(
    templateIntegration.ebayCategoryId && templateIntegration.ebayCategoryName
      ? {
          id: templateIntegration.ebayCategoryId,
          name: templateIntegration.ebayCategoryName,
        }
      : undefined,
  )
  const [categoryVersion, setCategoryVersion] = useState<number>(
    templateIntegration.ebayCategoryVersion || 0,
  )
  const [productAspects, setProductAspects] = useState<
    AspectMetadata | undefined
  >()

  const [ebayAspectIntegrations, setEbayAspectIntegrations] = useState<
    SetEbayAspectProductIntegration[]
  >(
    productIntegration?.aspects ??
      templateIntegration.aspects?.map((a) => ({
        aspectName: a.aspectName,
        value: a.value,
        dataType: a.dataType,
        required: a.required,
      })) ??
      [],
  )

  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const [title, setTitle] = useState<string | undefined>(
    productIntegration?.title ??
      templateIntegration.title ??
      product.product.title ??
      '',
  )
  const [subtitle] = useState<string>(
    productIntegration?.subtitle ?? templateIntegration?.subtitle ?? '',
  )
  const [description, setDescription] = useState<string | undefined>(
    productIntegration?.description ??
      templateIntegration?.description ??
      product.product.description ??
      '',
  )
  const [brand, setBrand] = useState<string>(
    productIntegration?.brand ??
      templateIntegration?.brand ??
      getAttributeValue(product, 'Brand') ??
      '',
  )

  const [conditionPrimary, setConditionPrimary] = useState<string>(
    productIntegration?.condition ??
      templateIntegration?.condition ??
      product?.product.condition ??
      'New',
  )
  const [conditionSecondary, setConditionSecondary] = useState<
    string | undefined
  >()
  const [conditionDescription, setConditionDescription] = useState<string>(
    productIntegration?.conditionDescription ??
      templateIntegration?.conditionDescription ??
      getAttributeValue(product, 'Condition Description') ??
      '',
  )

  const [sku] = useState<string>(product?.product.sku || '')
  const [price] = useState<number>(product?.product.price ?? 0)
  const [quantity] = useState<number>(product?.product.quantity ?? 0)

  const [list, setList] = useState<boolean>(
    productIntegration?.list ?? templateIntegration?.list ?? false,
  )
  const [sync, setSync] = useState<boolean>(
    productIntegration?.sync ?? templateIntegration?.sync ?? false,
  )
  const [syncQuantity, setSyncQuantity] = useState<boolean>(
    productIntegration?.syncQuantity ??
      templateIntegration?.syncQuantity ??
      false,
  )

  const [errorMessage, setErrorMessage] = useState<string | undefined>()

  const [detailUpdate] = useState<number>(0)
  const templateAttributes = template?.attributes || []

  const handleChange = () => {
    if (!templateIntegration.id) return

    const newProductIntegration: EbayProductIntegration = {
      productId: product.product.id,
      templateIntegrationId: templateIntegration.id,
      name: templateIntegration.name,
      index: templateIntegration.index,
      title: title || '',
      subtitle,
      description: description || '',
      condition: conditionPrimary,
      conditionDescription,
      brand: brand,
      list: list,
      sync: sync,
      syncQuantity: syncQuantity,
      aspects: ebayAspectIntegrations,
      selected: false,
    }

    onChange?.(templateIntegration.name, newProductIntegration)
  }

  useEffect(() => {
    handleChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    category,
    title,
    description,
    conditionPrimary,
    brand,
    list,
    sync,
    ebayAspectIntegrations,
  ])

  const handleSelectCategory = (
    categoryVersion: number,
    category: EbayCategory | undefined,
  ) => {
    setCategoryVersion(categoryVersion)
    setCategory(category)
  }

  const handleAspectChange = (aspect: SetEbayAspectProductIntegration) => {
    // find index
    const indexOf = ebayAspectIntegrations.findIndex(
      (a) => a.aspectName === aspect.aspectName,
    )

    const newAspectIntegrations = ebayAspectIntegrations.slice()

    // Aspect not found in product aspects
    if (indexOf < 0) {
      // check if aspect in template integrations
      const templateAspect = templateIntegration?.aspects?.find(
        (a) => a.aspectName === aspect.aspectName,
      )
      if (!templateAspect) return

      // push missing aspect
      newAspectIntegrations.push(aspect)
      setEbayAspectIntegrations(newAspectIntegrations)
      return
    }

    // update index
    newAspectIntegrations[indexOf] = aspect as EbayAspectProductIntegration
    setEbayAspectIntegrations(newAspectIntegrations)
  }

  const handleListProduct = () => {
    const productId = product?.product.id
    if (!productId) {
      return
    }
    listProduct(IntegrationName.EBAY, productId)
      .then((res) => {
        handleAlert(setAlert, res, 'Successfully listed product.')
      })
      .catch((e) => {
        const res = e.response.data as APIResult<OfferResponse>
        if (res.data) {
          const errors = res.data.errors || []
          const warnings = res.data.warnings || []

          // const severity = errors.length ? 'error' : 'warning'
          const msg = errors
            .concat(warnings)
            .map(
              (e) =>
                e.message +
                '<br />' +
                (e.parameters
                  ?.map((p) => `${p.name}: ${p.value}`)
                  .join('<br />') || ''),
            )
            .join('<br /><br />')

          setErrorMessage(msg)
        } else {
          setAlert({
            open: true,
            severity: 'error',
            message: `${e.response.data.message}`,
          })
        }
      })
  }

  const handleUnlistProduct = () => {
    const productId = product?.product.id
    if (!productId) {
      return
    }
    if (!templateIntegration.integrationId) {
      return
    }
    unlistProduct(
      IntegrationName.EBAY,
      productId,
      templateIntegration.integrationId,
    )
      .then((res) => {
        handleAlert(setAlert, res, 'Successfully Unlisted.')
      })
      .catch((e) =>
        setAlert({
          open: true,
          severity: 'error',
          message: e.response.data.message,
        }),
      )
  }

  useEffect(() => {
    category?.id &&
      getProductAspectsForCategory(category.id)
        .then((res) => setProductAspects(res?.data || ({} as AspectMetadata)))
        .catch((e) => log(e))
  }, [category])

  const attributeNames =
    template?.attributes.map((a) => a.attribute.name) ||
    product?.attributes.map((a) => a.templateAttribute.name)

  return (
    <Grid container justifyContent="center" spacing={8} pt={2} px={1}>
      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={3}>
          <Grid item xs={12}>
            <ListSyncComponent
              title={true}
              list={list}
              sync={sync}
              syncQuantity={syncQuantity}
              onListChange={setList}
              onSyncChange={setSync}
              onSyncQuantityChange={setSyncQuantity}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {/* Default Template Integration */}
        <Grid container justifyContent="center" spacing={2} mt={1}>
          {/* Category */}
          <Grid item xs={12}>
            <Typography level="h4">Details</Typography>
          </Grid>
          <Grid item xs={12}>
            {template ? (
              <EbayCategories
                categories={categories}
                category={category}
                categoryVersion={categoryVersion}
                onChange={handleSelectCategory}
                label="Category"
              />
            ) : (
              <ProductAttributeInputText
                label="Category"
                value={
                  '' +
                  category?.name +
                  (category?.id ? ` - ${category.id}` : '')
                }
                disabled={true}
              />
            )}
          </Grid>

          {/* Title and Description */}
          <Grid item xs={12}>
            <ProductTitleDescription
              key={`product-title-description-${detailUpdate}`}
              template={template}
              product={product}
              title={title}
              description={description}
              required={!!product}
              onTitleChange={setTitle}
              onDescriptionChange={setDescription}
              disableUnparsedEdit={true}
            />
          </Grid>

          {/* Product Values */}
          <Grid item xs={12}>
            <ProductAttributeInputText
              label="SKU"
              value={sku}
              disabled={true}
            />
          </Grid>

          <Grid item xs={12}>
            <EbayIntegrationConditions
              conditionPrimary={conditionPrimary}
              conditionSecondary={conditionSecondary}
              disabled={true}
              onChange={(primary, secondary) => {
                setConditionPrimary(primary)
                setConditionSecondary(secondary)
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <NamedInput
              name="Price"
              nameEditable={false}
              value={price}
              disabled={true}
              helperText={
                <Typography level="body-xs">
                  Price may vary according to pricing configuration.
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <NamedInput
              name="Quantity"
              nameEditable={false}
              value={quantity}
              disabled={true}
            />
          </Grid>

          <Grid item xs={12}>
            <AttributeSelect
              name="Condition Description"
              nameEditable={false}
              value={conditionDescription}
              attributeNames={attributeNames}
              multiple={false}
              onChange={(v) => setConditionDescription(v || '')}
            />
          </Grid>

          <Grid item xs={12}>
            <AttributeSelect
              name="Brand"
              nameEditable={false}
              value={brand}
              attributeNames={attributeNames}
              multiple={false}
              onChange={(v) => setBrand(v || '')}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <EbayProductAspects
          product={product}
          templateIntegration={templateIntegration}
          aspects={productAspects?.aspects || []}
          aspectIntegrations={ebayAspectIntegrations}
          templateAttributes={templateAttributes}
          disabled={true}
          onChange={handleAspectChange}
          onUpdate={onUpdate}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={3}>
          <Grid item xs={12}>
            <ListUnlistButtons
              title={true}
              list={list}
              hasProductIntegration={!!productIntegration}
              product={product}
              onList={handleListProduct}
              onUnlist={handleUnlistProduct}
            />
          </Grid>
          <Grid item xs={12}>
            {errorMessage ? (
              <Grid item xs={12}>
                <Typography>{errorMessage}</Typography>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>

      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Grid>
  )
}
