import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import {
  EtsyProductIntegration,
  EtsyPropertyProductIntegration,
  EtsyPropertyTemplateIntegration,
  EtsyShopReturnPolicy,
  EtsyShopShippingProfile,
  EtsyTaxonomyNode,
  EtsyTaxonomyProperty,
  EtsyTemplateIntegration,
  EtsyWhenMade,
  EtsyWhoMade,
} from '../../../../types/Etsy.types'
import { getEtsyTaxonomyProperties } from '../../../../api/integrations/etsy'
import {
  arrayToRecord,
  copy,
  err,
  getAttributeTag,
  isDefined,
  isLike,
} from '../../../../utils/functions'
import { TreeView } from '@mui/x-tree-view/TreeView'
import { TreeItem } from '@mui/x-tree-view/TreeItem'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Box from '@mui/material/Box'
import Button from '@mui/joy/Button'
import {
  GetProduct,
  GetProductAttribute,
  GetProductTemplate,
  GetProductTemplateAttribute,
  ProductTemplateAttributeInput,
  updateProductTemplateAttributes,
} from '../../../../api/product'
import AspectExamples from '../ebay/AspectExamples'
import {
  GetIntegrationResult,
  IntegrationName,
} from '../../../../api/integration'
import { extractAttributeName } from '../../../../classes/Parser'
import { DataFields } from '../../../../api/types'
import { DEFAULT_ATTRIBUTE_TYPE } from '../../../../utils/constants'
import SingleSelect from '../../../common/SingleSelect'
import Alert, { AlertInput } from '../../../common/Alert'
import { ProductTitleDescription } from '../../ProductTitleDescription'
import ListSyncComponent from '../common/ListSyncComponent'
import MissingIntegration from '../MissingIntegration'
import FloatLabelInput from '../../../common/FloatLabelInput'
import AttributeSelect from '../../../common/AttributeSelect'
import NamedSingleSelect from '../../../common/NamedSingleSelect'
import NamedInput from '../../../common/NamedInput'
import Visibility from '@mui/icons-material/Visibility'
import AlternateEmail from '@mui/icons-material/AlternateEmail'
import VerticalIconMenu from '../../../common/VerticalIconMenu'
import Modal from '@mui/joy/Modal'
import ModalDialog from '@mui/joy/ModalDialog'
import ModalClose from '@mui/joy/ModalClose'
import MultipleSelect from '../../../common/MultipleSelect'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import SvgIcon from '@mui/joy/SvgIcon'

interface EtsyIntegrationComponentProps {
  integration: GetIntegrationResult<IntegrationName.ETSY>
  templateIntegration: EtsyTemplateIntegration
  productIntegration?: EtsyProductIntegration
  taxonomy: EtsyTaxonomyNode[]
  shippingProfiles: EtsyShopShippingProfile[]
  returnPolicies: EtsyShopReturnPolicy[]
  template: GetProductTemplate
  product?: GetProduct
  onTemplateChange?: (
    name: string,
    templateIntegration: DataFields<EtsyTemplateIntegration> | undefined,
  ) => void
  onProductChange?: (
    name: string,
    productIntegration: DataFields<EtsyProductIntegration> | undefined,
  ) => void
  onUpdate?: () => void
}

const createReturnPolicyTag = (p: EtsyShopReturnPolicy | undefined): string => {
  if (!p) return ''
  return `${p.accepts_returns ? 'Returns, ' : 'No Returns, '}${
    p.accepts_exchanges ? 'Exchanges, ' : 'No Exchanges, '
  }${p.return_deadline} Days`
}

export default function EtsyIntegrationComponent({
  integration,
  templateIntegration,
  productIntegration,
  taxonomy,
  shippingProfiles,
  returnPolicies,
  template: defaultTemplate,
  product,
  onTemplateChange,
  onProductChange,
  onUpdate,
}: EtsyIntegrationComponentProps): JSX.Element {
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const [template, setTemplate] = useState<GetProductTemplate>(defaultTemplate)

  const [title, setTitle] = useState<string | undefined>(
    templateIntegration.title,
  )
  const [description, setDescription] = useState<string | undefined>(
    templateIntegration.description,
  )
  const [materials, setMaterials] = useState<string>(
    templateIntegration.materials,
  )

  const [list, setList] = useState<boolean>(templateIntegration.list ?? false)
  const [sync, setSync] = useState<boolean>(templateIntegration.sync ?? false)
  const [syncQuantity, setSyncQuantity] = useState<boolean>(
    templateIntegration.syncQuantity ?? false,
  )

  const [detailUpdate] = useState<number>(0)

  const [selectedShippingProfileName, setSelectedShippingProfileName] =
    useState<string>(
      shippingProfiles.find(
        (s) =>
          `${s.shipping_profile_id}` === templateIntegration.shippingProfileId,
      )?.title || '',
    )

  const [selectedReturnPolicyName, setSelectedReturnPolicyName] =
    useState<string>(
      createReturnPolicyTag(
        returnPolicies.find(
          (r) => `${r.return_policy_id}` === templateIntegration.returnPolicyId,
        ),
      ),
    )
  const [whoMade, setWhoMade] = useState<string>(
    templateIntegration.whoMade ?? '',
  )
  const [whenMade, setWhenMade] = useState<string>(
    templateIntegration.whenMade ?? '',
  )

  const [taxonomyProperties, setTaxonomyProperties] = useState<
    EtsyTaxonomyProperty[]
  >([])
  const [filteredTaxonomyNodes, setFilteredTaxonomyNodes] = useState<
    EtsyTaxonomyNode[]
  >([])
  const [taxonomySearch, setTaxonomySearch] = useState<string>('')
  const [selectedTaxonomy, setSelectedTaxonomy] = useState<
    { id: number; name: string } | undefined
  >(
    templateIntegration.taxonomyId
      ? {
          id: parseInt(templateIntegration.taxonomyId),
          name: templateIntegration.taxonomyName,
        }
      : undefined,
  )

  const [
    propertyTemplateIntegrationRecord,
    setPropertyTemplateIntegrationRecord,
  ] = useState<Record<string, DataFields<EtsyPropertyTemplateIntegration>>>(
    arrayToRecord(templateIntegration.properties || [], (p) => p.propertyName),
  )

  const [
    propertyProductIntegrationRecord,
    setPropertyProductIntegrationRecord,
  ] = useState<Record<string, DataFields<EtsyPropertyProductIntegration>>>(
    arrayToRecord(productIntegration?.properties || [], (p) => p.propertyName),
  )

  const [parseDetails, setParseDetails] = useState<boolean>(true)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [selectedAttribute, setSelectedAttribute] = useState<
    GetProductAttribute | undefined
  >()
  const [selectedProperty, setSelectedProperty] = useState<
    EtsyTaxonomyProperty | undefined
  >()

  useEffect(() => {
    if (!integration.integration?.id) return

    const shippingProfileIdNum = shippingProfiles.find(
      (p) => p.title === selectedShippingProfileName,
    )?.shipping_profile_id
    const shippingProfileId = shippingProfileIdNum
      ? `${shippingProfileIdNum}`
      : ''

    const returnPolicyIdNum = returnPolicies.find(
      (p) => createReturnPolicyTag(p) === selectedReturnPolicyName,
    )?.return_policy_id
    const returnPolicyId = returnPolicyIdNum ? `${returnPolicyIdNum}` : ''

    if (product) {
      const newProductIntegration: Omit<
        DataFields<EtsyProductIntegration>,
        'clientId'
      > = {
        id: templateIntegration.id,
        productId: product.product.id,
        name: templateIntegration.name,
        index: templateIntegration.index,
        selected: productIntegration?.selected || false,
        templateIntegrationId: templateIntegration.id,
        title: title || '',
        description: description,
        materials: materials,
        whenMade: whenMade,
        whoMade: whoMade,
        shippingProfileId: shippingProfileId,
        returnPolicyId: returnPolicyId,
        list,
        sync,
        syncQuantity,
        properties: Object.values(propertyProductIntegrationRecord),
      }
      onProductChange?.(templateIntegration.name, newProductIntegration)
    }

    const newTemplateIntegration: Omit<
      DataFields<EtsyTemplateIntegration>,
      'clientId'
    > = {
      id: templateIntegration.id,
      name: templateIntegration.name,
      index: templateIntegration.index,
      integrationId:
        templateIntegration.integrationId || integration.integration?.id,
      templateId: template?.template.id,
      taxonomyId: selectedTaxonomy?.id ? `${selectedTaxonomy.id}` : '0',
      taxonomyName: selectedTaxonomy?.name || '',
      title: title || '',
      description: description,
      materials: materials,
      whenMade: whenMade,
      whoMade: whoMade,
      shippingProfileId: shippingProfileId,
      returnPolicyId: returnPolicyId,
      list,
      sync,
      syncQuantity,
      properties: Object.values(propertyTemplateIntegrationRecord),
    }
    onTemplateChange?.(templateIntegration.name, newTemplateIntegration)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    title,
    description,
    materials,
    propertyTemplateIntegrationRecord,
    propertyProductIntegrationRecord,
    selectedReturnPolicyName,
    selectedShippingProfileName,
    selectedTaxonomy,
    list,
    sync,
    syncQuantity,
    whenMade,
    whoMade,
  ])

  useEffect(() => {
    if (!taxonomySearch) {
      setFilteredTaxonomyNodes(taxonomy)
      return
    }

    const filterTaxonomy = (nodes: EtsyTaxonomyNode[]): EtsyTaxonomyNode[] =>
      nodes
        .map((node): EtsyTaxonomyNode | undefined => {
          if (node.name.toLowerCase().includes(taxonomySearch.toLowerCase())) {
            return node
          } else {
            const childrenIncluded = filterTaxonomy(node.children)
            if (childrenIncluded?.length) {
              return { ...node, children: childrenIncluded }
            }
            return undefined
          }
        })
        .filter(isDefined)

    const filteredTaxonomy = filterTaxonomy(taxonomy)
    setFilteredTaxonomyNodes(filteredTaxonomy)
  }, [taxonomy, taxonomySearch])

  useEffect(() => {
    if (!selectedTaxonomy?.id || !taxonomy.length) return
    getEtsyTaxonomyProperties(selectedTaxonomy.id)
      .then((res) => {
        if (res.success && res.data) {
          setTaxonomyProperties(res.data)
        }
      })
      .catch((e) => err(e))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTaxonomy?.id])

  const handleTaxonomyChange = (id: number, name: string) => {
    setSelectedTaxonomy({ id, name })
  }

  const handleToggleParseDetails = () => {
    setParseDetails(!parseDetails)
  }

  const handlePropertyProductAttributeChange = (
    property: EtsyTaxonomyProperty,
    value: string | undefined,
    scaleName?: string,
  ) => {
    const newPropertyProductIntegrationRecord = copy(
      propertyProductIntegrationRecord,
    )
    const propertyProductIntegration =
      newPropertyProductIntegrationRecord[property.name]

    const scaleIdNum = property.scales.find(
      (scale) => scale.display_name === scaleName,
    )?.scale_id
    const scaleId = scaleIdNum ? `${scaleIdNum}` : undefined

    if (!propertyProductIntegration) {
      newPropertyProductIntegrationRecord[property.name] = {
        productIntegrationId: productIntegration?.id || 0,
        propertyId: `${property.property_id}`,
        propertyName: property.name,
        values: value,
        scaleId: scaleId,
        required: property.is_required,
      }

      setPropertyProductIntegrationRecord(newPropertyProductIntegrationRecord)
      return
    }

    if (value !== undefined) {
      propertyProductIntegration.values = value
    }
    if (scaleId) {
      propertyProductIntegration.scaleId = scaleId
    }

    newPropertyProductIntegrationRecord[property.name] =
      propertyProductIntegration

    setPropertyProductIntegrationRecord(newPropertyProductIntegrationRecord)
  }

  const handlePropertyTemplateAttributeChange = (
    property: EtsyTaxonomyProperty,
    value: string | undefined,
    scaleName?: string,
  ) => {
    const newPropertyTemplateIntegrationRecord = copy(
      propertyTemplateIntegrationRecord,
    )
    const propertyTemplateIntegration =
      newPropertyTemplateIntegrationRecord[property.name]

    const scaleIdNum = property.scales.find(
      (scale) => scale.display_name === scaleName,
    )?.scale_id
    const scaleId = scaleIdNum ? `${scaleIdNum}` : undefined

    if (!propertyTemplateIntegration) {
      newPropertyTemplateIntegrationRecord[property.name] = {
        templateIntegrationId: templateIntegration?.id || 0,
        propertyId: `${property.property_id}`,
        propertyName: property.name,
        values: value,
        scaleId: scaleId,
        required: property.is_required,
      }

      setPropertyTemplateIntegrationRecord(newPropertyTemplateIntegrationRecord)
      return
    }

    if (value !== undefined) {
      propertyTemplateIntegration.values = value
    }
    if (scaleId) {
      propertyTemplateIntegration.scaleId = scaleId
    }

    newPropertyTemplateIntegrationRecord[property.name] =
      propertyTemplateIntegration

    setPropertyTemplateIntegrationRecord(newPropertyTemplateIntegrationRecord)
  }

  const handleAddTemplateAttributeOption = async (
    selectedTemplateAttribute: GetProductTemplateAttribute | undefined,
    options: string[],
  ) => {
    if (!selectedTemplateAttribute) return
    // Get template id from template attribute
    const templateId = selectedTemplateAttribute.attribute.templateId
    const templateAttributeName = selectedTemplateAttribute.attribute.name

    const newIndex = template?.attributes.length // length+1
    const newOptionIndex = selectedTemplateAttribute.attributeOptions.length // length+1
    if (!templateId || !templateAttributeName) return

    // add attribute with options
    const addTemplateAttr: ProductTemplateAttributeInput = {
      attribute: {
        name: templateAttributeName,
        type: DEFAULT_ATTRIBUTE_TYPE,
        index: newIndex,
      },
      attributeOptions: options.map((o) => ({
        value: o,
        type: DEFAULT_ATTRIBUTE_TYPE,
        index: newOptionIndex,
      })),
    }

    const res = await updateProductTemplateAttributes(templateId, [
      addTemplateAttr,
    ])
    if (res.success && res.data) {
      const newTemplate = copy(template)
      const updateIndex = newTemplate.attributes.findIndex(
        (a) => a.attribute.name === templateAttributeName,
      )
      if (
        updateIndex < 0 ||
        !res.data?.[0] ||
        !newTemplate.attributes[updateIndex]
      )
        return
      const newTemplateAttribute =
        newTemplate.attributes[updateIndex]?.attribute

      const newTemplateAttributeOptions =
        newTemplate.attributes[updateIndex]?.attributeOptions

      if (!newTemplateAttribute) return
      newTemplate.attributes[updateIndex] = {
        attribute: newTemplateAttribute,
        attributeOptions:
          newTemplateAttributeOptions?.concat(res.data?.[0].attributeOptions) ||
          [],
      }
      setTemplate(newTemplate)
      // onUpdate?.()
    }
  }

  const handleAddTemplateAttribute = async (
    property: EtsyTaxonomyProperty,
    name: string,
  ) => {
    // Get template id from first template attribute
    const templateId = template?.attributes?.[0]?.attribute.templateId
    const newIndex = template?.attributes?.length // length+1
    if (!templateId) return
    // add attribute
    const addTemplateAttr: ProductTemplateAttributeInput = {
      attribute: {
        name: name,
        type: DEFAULT_ATTRIBUTE_TYPE,
        index: newIndex,
      },
      attributeOptions: [],
    }
    const res = await updateProductTemplateAttributes(templateId, [
      addTemplateAttr,
    ])
    if (res.success && res.data) {
      const newTemplate = copy(template)
      const newAttributes = newTemplate.attributes.slice()
      newTemplate.attributes = newAttributes.concat(res.data)
      setTemplate(newTemplate)
      // onUpdate?.()
    }

    handlePropertyTemplateAttributeChange(property, getAttributeTag(name))
  }

  const handlePropertyOptionClick = (
    attribute: GetProductAttribute | undefined,
    property: EtsyTaxonomyProperty,
    option: string,
  ) => {
    setSelectedAttribute(attribute)
    setSelectedProperty(property)
    setModalOpen(true)
  }

  if (!integration) {
    return <MissingIntegration />
  }

  const attributeNames = template?.attributes.map((a) => a.attribute.name)

  return (
    <Grid container spacing={6} pt={2} px={1}>
      {/* List Sync */}
      <Grid item xs={12}>
        <ListSyncComponent
          title={true}
          list={list}
          sync={sync}
          syncQuantity={syncQuantity}
          onListChange={setList}
          onSyncChange={setSync}
          onSyncQuantityChange={setSyncQuantity}
        />
      </Grid>

      {/* Category */}
      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <Typography level="title-lg">Category</Typography>
          </Grid>
          <Grid item xs={12}>
            {selectedTaxonomy?.id ? (
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <NamedInput
                    name="Category"
                    value={selectedTaxonomy.name}
                    disabled={true}
                    autoComplete="off"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    disabled={false}
                    size="sm"
                    variant="plain"
                    onClick={() => setSelectedTaxonomy(undefined)}
                    sx={{ float: 'right', mt: '3px' }}
                  >
                    Edit
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid container justifyContent="center" spacing={1}>
                  <Grid item xs={12}>
                    <FloatLabelInput
                      fullWidth
                      label="Search Categories"
                      value={taxonomySearch}
                      onChange={(v) => setTaxonomySearch(v)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        px: 12,
                        pt: 4,
                        backgroundColor: '#FBFCFE',
                        borderStyle: 'solid',
                        borderRadius: '6px',
                        borderColor:
                          'var(--variant-outlinedBorder, var(--joy-palette-neutral-outlinedBorder, var(--joy-palette-neutral-300, #CDD7E1)))',
                        boxShadow:
                          '0 0 #000,  0px 1px 2px 0px rgba(21,21,21, 0.08)',
                        padding: '1em',
                      }}
                    >
                      <TreeView
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                        sx={{
                          height: '300px',
                          flexGrow: 1,
                          overflowY: 'auto',
                        }}
                      >
                        {filteredTaxonomyNodes.map((node) => {
                          return (
                            <DisplayTaxonomyNode
                              key={node.id}
                              node={node}
                              onChange={(id, name) =>
                                handleTaxonomyChange(id, name)
                              }
                            />
                          )
                        })}
                      </TreeView>
                    </Box>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>

      {/* Title Desc */}
      <Grid item xs={12}>
        <ProductTitleDescription
          key={`product-title-description-${detailUpdate}`}
          template={template}
          title={title}
          description={description}
          onTitleChange={setTitle}
          onDescriptionChange={setDescription}
          disableUnparsedEdit={true}
        />
      </Grid>

      {/* Misc */}
      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <AttributeSelect
              name="Materials"
              attributeNames={attributeNames}
              freeSolo={true}
              multiple={true}
              values={materials ? materials.split(', ') : []}
              onSelect={(values) => {
                Array.isArray(values) && setMaterials(values.join(', '))
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <AttributeSelect
              attributeNames={attributeNames}
              name="Who Made"
              options={Object.values(EtsyWhoMade)}
              freeSolo={true}
              value={whoMade}
              onChange={(value) => {
                setWhoMade(value || '')
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <AttributeSelect
              attributeNames={attributeNames}
              name="When Made"
              options={Object.values(EtsyWhenMade)}
              freeSolo={true}
              value={whenMade}
              onChange={(value) => {
                setWhenMade(value || '')
              }}
            />
          </Grid>

          <Grid item xs={12}>
            {shippingProfiles ? (
              <NamedSingleSelect
                key={selectedShippingProfileName}
                name="Shipping Profile"
                placeholder="Shipping Profile"
                nameEditable={false}
                required={true}
                options={shippingProfiles.map((p) => `${p.title}`)}
                value={selectedShippingProfileName}
                onChange={(value) =>
                  setSelectedShippingProfileName(value || '')
                }
              ></NamedSingleSelect>
            ) : (
              <Typography level="body-xs">
                No shipping profiles found, create Etsy Shipping Profiles to
                use. See{' '}
                <a
                  target="__blank"
                  href="https://www.etsy.com/your/shops/me/tools/shipping-profiles"
                >
                  Shipping Profiles
                </a>
              </Typography>
            )}
          </Grid>

          <Grid item xs={12}>
            {returnPolicies?.length ? (
              <NamedSingleSelect
                key={selectedReturnPolicyName}
                name="Return Policy"
                placeholder="Return Policy"
                required={true}
                nameEditable={false}
                options={returnPolicies.map((p) => createReturnPolicyTag(p))}
                value={selectedReturnPolicyName}
                onChange={(value) => setSelectedReturnPolicyName(value || '')}
              ></NamedSingleSelect>
            ) : (
              <Typography level="body-xs">
                No policies found, create Etsy Return Policy to use. See{' '}
                <a
                  target="__blank"
                  href="https://help.etsy.com/hc/en-us/articles/360000572888-Refunds-Returns-and-Exchanges-for-Sellers?segment=selling"
                >
                  Return Policies
                </a>
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>

      {/* Properties */}
      {taxonomyProperties?.length ? (
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Grid item xs={1}></Grid>

                <Grid item xs={10}>
                  <Typography level="h4">Attributes</Typography>
                </Grid>

                <Grid item xs={1}>
                  {product && (
                    <Button
                      sx={{ float: 'right' }}
                      size="sm"
                      variant="plain"
                      color="neutral"
                      onClick={handleToggleParseDetails}
                      endDecorator={
                        !parseDetails ? (
                          <Visibility fontSize="small" />
                        ) : (
                          <AlternateEmail fontSize="small" />
                        )
                      }
                    >
                      <Typography level="body-sm">
                        {!parseDetails ? 'Parse' : 'View'}
                      </Typography>
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="center" spacing={1}>
                {!product &&
                  taxonomyProperties.map((property) => {
                    const propertyIntegration =
                      propertyTemplateIntegrationRecord[property.name]

                    const attributeMatch = template?.attributes.find((a) =>
                      isLike(a.attribute.name, property.display_name),
                    )

                    let templateAttribute:
                      | GetProductTemplateAttribute
                      | undefined

                    const attributeValuesName = extractAttributeName(
                      propertyIntegration?.values,
                    )

                    // If undefined
                    if (propertyIntegration?.values === undefined) {
                      templateAttribute = attributeMatch
                    }
                    if (attributeValuesName) {
                      templateAttribute = template?.attributes.find(
                        (attribute) =>
                          attribute.attribute.name === attributeValuesName,
                      )
                    }

                    return (
                      <Grid item xs={12} key={property.property_id}>
                        <Grid container justifyContent="center" spacing={1}>
                          <Grid item xs={12} md={3}>
                            <Typography sx={{ mt: '8px' }}>
                              <strong>
                                {property.display_name}
                                {property.is_required ? '*' : ''}:{' '}
                              </strong>
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={
                              12 -
                              (property.possible_values.length ? 3 : 0) -
                              (property.scales.length ? 3 : 0)
                            }
                            md={
                              9 -
                              (property.possible_values.length ? 3 : 0) -
                              (property.scales.length ? 3 : 0)
                            }
                          >
                            <AttributeSelect
                              value={
                                propertyIntegration?.values ??
                                getAttributeTag(attributeMatch?.attribute.name)
                              }
                              attributeNames={attributeNames}
                              onChange={(newValue) => {
                                handlePropertyTemplateAttributeChange(
                                  property,
                                  newValue || '',
                                )
                              }}
                              onUpdate={onUpdate}
                              addOptions={
                                !attributeMatch ? [property.display_name] : []
                              }
                              onAddOptionClick={(name) =>
                                handleAddTemplateAttribute(property, name)
                              }
                            />
                          </Grid>
                          {property.scales.length ? (
                            <Grid item xs={3} md={3}>
                              <SingleSelect
                                placeholder="Scales"
                                value={
                                  property.scales.find(
                                    (scale) =>
                                      `${scale.scale_id}` ===
                                      propertyIntegration?.scaleId,
                                  )?.display_name || ''
                                }
                                options={property.scales.map(
                                  (v) => v.display_name,
                                )}
                                onChange={(scaleName) =>
                                  handlePropertyTemplateAttributeChange(
                                    property,
                                    undefined,
                                    scaleName,
                                  )
                                }
                              />
                            </Grid>
                          ) : null}
                          {property.possible_values.length ? (
                            <Grid item xs={3} md={3}>
                              <AspectExamples
                                label="Allowed Values"
                                templateAttribute={templateAttribute}
                                options={property.possible_values.map(
                                  (v) => v.name,
                                )}
                                onChange={(options) =>
                                  handleAddTemplateAttributeOption(
                                    templateAttribute,
                                    options,
                                  )
                                }
                              />
                            </Grid>
                          ) : null}
                        </Grid>
                      </Grid>
                    )
                  })}

                {product &&
                  taxonomyProperties.map((property) => {
                    const propertyIntegration =
                      propertyProductIntegrationRecord[property.name]

                    let productAttribute: GetProductAttribute | undefined
                    const attributeValuesName = extractAttributeName(
                      propertyIntegration?.values,
                    )

                    // If match
                    if (attributeValuesName) {
                      productAttribute = product?.attributes.find(
                        (attribute) =>
                          attribute.templateAttribute.name ===
                          attributeValuesName,
                      )
                    }
                    // If undefined
                    if (propertyIntegration?.values === undefined) {
                      productAttribute = product?.attributes.find((a) =>
                        isLike(a.templateAttribute.name, property.display_name),
                      )
                    }

                    let value =
                      productAttribute?.attribute?.value ??
                      propertyIntegration?.values
                    if (!parseDetails && productAttribute) {
                      value = getAttributeTag(
                        productAttribute?.templateAttribute.name,
                      )
                    }

                    const valueNotPossible =
                      property.possible_values?.length &&
                      productAttribute?.attribute?.value &&
                      !property.possible_values.some(
                        (v) => v.name === productAttribute?.attribute?.value,
                      )

                    const warningMessage =
                      valueNotPossible && !property.is_required
                        ? `${
                            productAttribute?.attribute?.value || 'Value'
                          } is not in Etsy values`
                        : ''
                    let errorMessage = ''
                    if (valueNotPossible && property.is_required) {
                      errorMessage = `${
                        productAttribute?.attribute?.value || 'Value'
                      } is not in Etsy values`
                    } else if (
                      !productAttribute?.attribute?.value &&
                      property.is_required
                    ) {
                      errorMessage = 'Value is required'
                    }

                    return (
                      <Grid item xs={12} key={property.property_id}>
                        <Grid container justifyContent="center" spacing={1}>
                          <Grid item xs={12} md={3}>
                            <Typography sx={{ mt: '8px' }}>
                              <strong>
                                {property.display_name}
                                {property.is_required ? '*' : ''}:{' '}
                              </strong>
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12 - (property.scales.length ? 3 : 0)}
                            md={9 - (property.scales.length ? 3 : 0)}
                          >
                            <AttributeSelect
                              value={value}
                              attributeNames={
                                parseDetails ? [] : attributeNames
                              }
                              onChange={(newValue) =>
                                handlePropertyProductAttributeChange(
                                  property,
                                  newValue || '',
                                )
                              }
                              options={
                                parseDetails
                                  ? property.possible_values.map(
                                      (o) => o.name,
                                    ) || []
                                  : []
                              }
                              onUpdate={onUpdate}
                              warningText={warningMessage}
                              errorText={errorMessage}
                              endDecorator={
                                property.possible_values?.length ? (
                                  <VerticalIconMenu
                                    size="small"
                                    options={['Value Mapping']}
                                    onClick={(option) =>
                                      handlePropertyOptionClick(
                                        productAttribute,
                                        property,
                                        option,
                                      )
                                    }
                                    p={0}
                                  />
                                ) : undefined
                              }
                            />
                          </Grid>
                          {property.scales.length ? (
                            <Grid item xs={3} md={3}>
                              <SingleSelect
                                placeholder="Scales"
                                value={
                                  property.scales.find(
                                    (scale) =>
                                      `${scale.scale_id}` ===
                                      propertyIntegration?.scaleId,
                                  )?.display_name || ''
                                }
                                options={property.scales.map(
                                  (v) => v.display_name,
                                )}
                                onChange={(scaleName) =>
                                  handlePropertyProductAttributeChange(
                                    property,
                                    undefined,
                                    scaleName,
                                  )
                                }
                              />
                            </Grid>
                          ) : null}
                        </Grid>
                      </Grid>
                    )
                  })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : null}

      {!product && (
        <Grid item xs={12}>
          <Button
            variant="soft"
            color="danger"
            sx={{ float: 'left' }}
            onClick={() => {
              if (product && productIntegration) {
                onProductChange?.(templateIntegration.name, undefined)
              } else if (!product) {
                onTemplateChange?.(templateIntegration.name, undefined)
              }
            }}
          >
            Delete
          </Button>
        </Grid>
      )}

      {/* <Grid item xs={12}>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Button variant="solid" onClick={handleSave}>
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid> */}

      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <ModalDialog layout="center" size="lg" sx={{ overflow: 'auto', p: 2 }}>
          <ModalClose />

          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12}>
              <Typography level="title-lg">Value Mapping</Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid container justifyContent="center" spacing={1}>
                <Grid item xs={6}>
                  <Typography level="title-md">
                    <strong>Value</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography level="title-md">
                    <strong>Etsy Value</strong>
                  </Typography>
                </Grid>
                {selectedProperty?.possible_values.map((o) => {
                  return (
                    <Grid item xs={12} key={o.name}>
                      <Grid
                        container
                        spacing={1}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={6}>
                          <MultipleSelect
                            placeholder={o.name}
                            value={[]}
                            freeSolo={true}
                            options={selectedAttribute?.templateAttributeOptions.map(
                              (o) => o.value,
                            )}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <SvgIcon
                            size="lg"
                            color="neutral"
                            sx={{
                              position: 'relative',
                              top: '4px',
                              right: '0px',
                              opacity: 0.5,
                            }}
                          >
                            <ArrowRightAltIcon />
                          </SvgIcon>
                        </Grid>
                        <Grid item xs={5}>
                          <FloatLabelInput value={o.name} disabled={true} />
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Button sx={{ float: 'right' }}>Save</Button>
            </Grid>
          </Grid>
        </ModalDialog>
      </Modal>

      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      />
    </Grid>
  )
}

function DisplayTaxonomyNode({
  node,
  onChange,
}: {
  node: EtsyTaxonomyNode
  onChange: (taxonomyId: number, taxonomyName: string) => void
}): JSX.Element {
  return <TreeNode node={node} key={node.id} onChange={onChange} />
}

function TreeNode({
  node,
  onChange,
}: {
  node: EtsyTaxonomyNode
  onChange: (taxonomyId: number, taxonomyName: string) => void
}): JSX.Element {
  return (
    <TreeItem
      key={node.id}
      nodeId={`${node.id}`}
      label={node.name}
      onClick={() => !node.children.length && onChange(node.id, node.name)}
    >
      {node.children?.map((node) => (
        <TreeNode node={node} key={node.id} onChange={onChange} />
      ))}
    </TreeItem>
  )
}

/**

 Value Mapping

// Global Value Mapping
// Agnostic of a lot of things. Maybe not good
type EtsyChannelValueMapping = {
  clientId: number
  integrationId: number
  value: string
  channelValue: string
  strict: boolean
}

// ** This may be the best solution ** This avoids needing to re-map for template integrations and such
// Taxonomy Property Specific Value Mapping
type EtsyTaxonomyPropertyValueMapping = {
  clientId: number
  taxonomyId: number
  taxonomyName: string

  propertyId: number
  propertyName: string

  // Sellmotto value
  value: string

  // Etsy value
  valueName: string
  valueId: string

  strict: boolean
}
// On TemplateIntegration fetch, find all value mapping from taxonomy.

EtsyTemplateIntegration = {
  ...
  properties: [...]
  valueMapping: EtsyTaxonomyPropertyValueMapping[]
}



// ** Specific to exact template integration but may require user to re-do value mapping too much
// Template Property Specific Value Mapping
type EtsyTemplatePropertyValueMapping = {
  clientId: number
  integrationId: number
  templateIntegrationId: number
  propertyTemplateIntegrationId: number

  // Sellmotto value
  value: string

  // Etsy Value
  valueName: string
  valueId: string
  strict: boolean
}

// On Property Fetch, include value mapping

  PropertyTemplateIntegration {
    ...
    valueMapping: EtsyPropertyValueMapping[]
  }
*/
