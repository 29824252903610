import React, { useEffect, useState } from 'react'
import { GetIntegrationResult, IntegrationName } from '../../api/integration'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import Button from '@mui/joy/Button'
import Alert, { AlertInput } from '../../components/common/Alert'
import SingleSelect from '../../components/common/SingleSelect'
import { BigCommerceLocation } from '../../types/BigCommerce.types'
import { getBigCommerceLocations } from '../../api/integrations/bigcommerce'
import { err } from '../../utils/functions'

export default function BigCommerceIntegration({
  integration,
}: {
  integration: GetIntegrationResult<IntegrationName.BIG_COMMERCE>
}): JSX.Element {
  const [alert, setAlert] = useState<AlertInput>({ open: false })
  const [locations, setLocations] = useState<BigCommerceLocation[]>([])

  const handleAppStoreClick = () => {
    const storeHash = integration.integration?.shop?.replace('stores/', '')

    let appStoreUrl =
      'https://www.bigcommerce.com/apps/categories/?query=Sellmotto'
    if (integration.oAuthUrl) {
      appStoreUrl = integration.oAuthUrl
    } else if (storeHash) {
      appStoreUrl = `https://store-${storeHash}.mybigcommerce.com/manage/marketplace/apps/my-apps`
    }
    window.open(integration.oAuthUrl || appStoreUrl, '_blank')
  }

  useEffect(() => {
    getBigCommerceLocations()
      .then((res) => {
        if (res.success && res.data) {
          setLocations(res.data)
        }
      })
      .catch((e) => err(e))
  }, [])

  return (
    <Grid container justifyContent="center" spacing={6}>
      {integration.integration?.shop ? (
        <>
          <Grid item xs={12}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12}>
                <Typography level="title-lg">Store Details</Typography>
                <Typography level="body-sm">
                  Store ID:{' '}
                  {integration.integration.shop?.replace('stores/', '')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button variant="solid" onClick={handleAppStoreClick}>
                  Open Store
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12}>
                <Typography level="title-lg">Locations</Typography>
                <Typography level="body-xs">
                  Add locations in your BigCommerce{' '}
                  <a
                    href={`https://store-${integration.integration.shop.replace(
                      'stores/',
                      '',
                    )}.mybigcommerce.com/manage/settings/locations/`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    location settings
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <SingleSelect
                  placeholder="View Locations"
                  onChange={() => {
                    // no-op
                  }}
                  value=""
                  options={locations.map((l) => l.label)}
                  defaultOption={false}
                ></SingleSelect>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : null}

      {integration.authenticated ? (
        <>
          <Grid item xs={12}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12}>
                <Typography level="title-lg">Authentication</Typography>
                <Typography level="body-sm">
                  To re-authenticate, uninstall and re-install the Sellmotto
                  BigCommerce app to your store.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button variant="solid" onClick={handleAppStoreClick}>
                  Re-Authorize
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography level="body-xs">
                  Authentication Last Updated:{' '}
                  {integration.integration?.updatedAt
                    ? new Date(
                        integration.integration?.updatedAt,
                      ).toLocaleString()
                    : 'Unknown'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <Typography level="title-lg">Authentication</Typography>
              <Typography level="body-sm">
                Authenticate app access through the Sellmotto BigCommerce app
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button variant="solid" onClick={handleAppStoreClick}>
                Open Marketplace
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}

      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Grid>
  )
}
