import { Optional } from '.'
import { DataFields, Integration } from '../api/types'

export interface BulkInventoryItemRequest {
  requests: InventoryItem[]
}

export interface InventoryItem {
  availability?: Availability
  condition?: ConditionEnum
  conditionDescription: string
  locale: LocaleEnum
  packageWeightAndSize: PackageWeightAndSize
  product: Product
  sku: string
}

export interface GetInventoryItems extends PaginatedResponse {
  inventoryItems: InventoryItem[]
}

export interface Availability {
  pickupAtLocationAvailability: PickupAtLocationAvailability[]
  shipToLocationAvailability: ShipToLocationAvailability
}

export interface PickupAtLocationAvailability {
  availabilityType: AvailabilityTypeEnum
  fulfillmentTime: TimeDuration
  merchantLocationKey: string
  quantity: number // integer
}

export interface TimeDuration {
  unit: TimeDurationUnitEnum
  value: number // integer
}

export interface ShipToLocationAvailability {
  availabilityDistributions: AvailabilityDistribution[]
  quantity?: number // integer
}

export interface AvailabilityDistribution {
  fulfillmentTime: TimeDuration
  merchantLocationKey: string
  quantity: number // integer
}

export interface PackageWeightAndSize {
  dimensions: Dimensions
  packageType: PackageTypeEnum
  weight: Weight
}

export interface Dimensions {
  height: number // float
  length: number // float
  unit?: LengthUnitOfMeasureEnum
  width: number // float
}

export interface Weight {
  unit?: WeightUnitOfMeasureEnum
  value: number // float
}

export interface Product {
  aspects: { [key: string]: string[] }
  brand: string
  description: string
  ean: string[]
  epid: string
  imageUrls: string[]
  isbn: string[]
  mpn: string
  subtitle?: string
  title: string
  upc: string[]
  videoIds: string[]
}

export interface Aspects {
  [name: string]: string[]
}

export enum AvailabilityTypeEnum {
  IN_STOCK = 'IN_STOCK',
  OUT_OF_STOCK = 'OUT_OF_STOCK',
  SHIP_TO_STORE = 'SHIP_TO_STORE',
}

export enum TimeDurationUnitEnum {
  YEAR = 'YEAR',
  MONTH = 'MONTH',
  DAY = 'DAY',
  HOUR = 'HOUR',
  CALENDAR_DAY = 'CALENDAR_DAY',
  BUSINESS_DAY = 'BUSINESS_DAY',
  MINUTE = 'MINUTE',
  SECOND = 'SECOND',
  MILLISECOND = 'MILLISECOND',
}

export enum ConditionEnum {
  NEW = 'NEW',
  LIKE_NEW = 'LIKE_NEW',
  NEW_OTHER = 'NEW_OTHER',
  NEW_WITH_DEFECTS = 'NEW_WITH_DEFECTS',
  MANUFACTURER_REFURBISHED = 'MANUFACTURER_REFURBISHED',
  CERTIFIED_REFURBISHED = 'CERTIFIED_REFURBISHED',
  EXCELLENT_REFURBISHED = 'EXCELLENT_REFURBISHED',
  VERY_GOOD_REFURBISHED = 'VERY_GOOD_REFURBISHED',
  GOOD_REFURBISHED = 'GOOD_REFURBISHED',
  SELLER_REFURBISHED = 'SELLER_REFURBISHED',
  USED_EXCELLENT = 'USED_EXCELLENT',
  USED_VERY_GOOD = 'USED_VERY_GOOD',
  USED_GOOD = 'USED_GOOD',
  USED_ACCEPTABLE = 'USED_ACCEPTABLE',
  FOR_PARTS_OR_NOT_WORKING = 'FOR_PARTS_OR_NOT_WORKING',
}

export enum LocaleEnum {
  en_US = 'en_US',
  en_CA = 'en_CA',
  fr_CA = 'fr_CA',
  en_GB = 'en_GB',
  en_AU = 'en_AU',
  en_IN = 'en_IN',
  de_AT = 'de_AT',
  fr_BE = 'fr_BE',
  fr_FR = 'fr_FR',
  de_DE = 'de_DE',
  it_IT = 'it_IT',
  nl_BE = 'nl_BE',
  nl_NL = 'nl_NL',
  es_ES = 'es_ES',
  de_CH = 'de_CH',
  fi_FI = 'fi_FI',
  zh_HK = 'zh_HK',
  hu_HU = 'hu_HU',
  en_PH = 'en_PH',
  pl_PL = 'pl_PL',
  pt_PT = 'pt_PT',
  ru_RU = 'ru_RU',
  en_SG = 'en_SG',
  en_IE = 'en_IE',
  en_MY = 'en_MY',
}

export enum LengthUnitOfMeasureEnum {
  INCH = 'INCH',
  FEET = 'FEET',
  CENTIMETER = 'CENTIMETER',
  METER = 'METER',
}

export enum PackageTypeEnum {
  LETTER = 'LETTER',
  BULKY_GOODS = 'BULKY_GOODS',
  CARAVAN = 'CARAVAN',
  CARS = 'CARS',
  EUROPALLET = 'EUROPALLET',
  EXPANDABLE_TOUGH_BAGS = 'EXPANDABLE_TOUGH_BAGS',
  EXTRA_LARGE_PACK = 'EXTRA_LARGE_PACK',
  FURNITURE = 'FURNITURE',
  INDUSTRY_VEHICLES = 'INDUSTRY_VEHICLES',
  LARGE_CANADA_POSTBOX = 'LARGE_CANADA_POSTBOX',
  LARGE_CANADA_POST_BUBBLE_MAILER = 'LARGE_CANADA_POST_BUBBLE_MAILER',
  LARGE_ENVELOPE = 'LARGE_ENVELOPE',
  MAILING_BOX = 'MAILING_BOX',
  MEDIUM_CANADA_POST_BOX = 'MEDIUM_CANADA_POST_BOX',
  MEDIUM_CANADA_POST_BUBBLE_MAILER = 'MEDIUM_CANADA_POST_BUBBLE_MAILER',
  MOTORBIKES = 'MOTORBIKES',
  ONE_WAY_PALLET = 'ONE_WAY_PALLET',
  PACKAGE_THICK_ENVELOPE = 'PACKAGE_THICK_ENVELOPE',
  PADDED_BAGS = 'PADDED_BAGS',
  PARCEL_OR_PADDED_ENVELOPE = 'PARCEL_OR_PADDED_ENVELOPE',
  ROLL = 'ROLL',
  SMALL_CANADA_POST_BOX = 'SMALL_CANADA_POST_BOX',
  SMALL_CANADA_POST_BUBBLE_MAILER = 'SMALL_CANADA_POST_BUBBLE_MAILER',
  TOUGH_BAGS = 'TOUGH_BAGS',
  UPS_LETTER = 'UPS_LETTER',
  USPS_FLAT_RATE_ENVELOPE = 'USPS_FLAT_RATE_ENVELOPE',
  USPS_LARGE_PACK = 'USPS_LARGE_PACK',
  VERY_LARGE_PACK = 'VERY_LARGE_PACK',
  WINE_PAK = 'WINE_PAK',
}

export enum WeightUnitOfMeasureEnum {
  POUND = 'POUND',
  KILOGRAM = 'KILOGRAM',
  OUNCE = 'OUNCE',
  GRAM = 'GRAM',
}

export interface BulkInventoryItemResponse {
  responses: InventoryItemResponse[]
}

export interface InventoryItemResponse extends BaseResponse {
  statusCode: number
  sku: string
  locale: LocaleEnum
}

export interface AspectMetadata {
  aspects: Aspect[]
}

export interface Aspect {
  aspectConstraint: AspectConstraint
  aspectValues?: AspectValue[]
  localizedAspectName: string
  relevanceIndicator: RelevanceIndicator
}

export interface AspectConstraint {
  aspectApplicableTo: AspectApplicableToEnum[]
  aspectDataType: AspectDataTypeEnum
  aspectEnabledForVariations: boolean
  aspectFormat: string
  aspectMaxLength: number // float
  aspectMode: AspectModeEnum
  aspectRequired: boolean
  aspectUsage: AspectUsageEnum
  expectedRequiredByDate: string
  itemToAspectCardinality: ItemToAspectCardinalityEnum
}

export interface AspectValue {
  localizedValue: string
  valueConstraints: ValueConstraint[]
}

export interface ValueConstraint {
  applicableForLocalizedAspectName: string
  applicableForLocalizedAspectValues: string[]
}

export interface RelevanceIndicator {
  searchCount: number // integer
}

export enum AspectApplicableToEnum {
  ITEM = 'ITEM',
  PRODUCT = 'PRODUCT',
}

export enum AspectDataTypeEnum {
  DATE = 'DATE',
  NUMBER = 'NUMBER',
  STRING = 'STRING',
  STRING_ARRAY = 'STRING_ARRAY',
}

export enum AspectModeEnum {
  FREE_TEXT = 'FREE_TEXT',
  SELECTION_ONLY = 'SELECTION_ONLY',
}

export enum AspectUsageEnum {
  RECOMMENDED = 'RECOMMENDED',
  OPTIONAL = 'OPTIONAL',
}

export enum ItemToAspectCardinalityEnum {
  MULTI = 'MULTI',
  SINGLE = 'SINGLE',
}

export interface CategoryTree {
  applicableMarketplaceIds: MarketplaceIdEnum[]
  categoryTreeId: string
  categoryTreeVersion: string
  rootCategoryNode: CategoryTreeNode
}

export interface CategorySuggestions {
  categorySuggestions: CategorySuggestion[]
}

export interface CategorySuggestion {
  category: Category
  categoryTreeNodeLevel: number // integer
  categoryTreeNodeAncestors: CategoryTreeNodeAncestor[]
}

export interface CategoryTreeNodeAncestor {
  categoryId: number // integer
  categoryName: string
  categoryTreeNodeLevel: number // integer
  categorySubtreeNodeHref: string
}

export interface CategoryTreeNode {
  category: Category
  categoryTreeNodeLevel: number // integer
  childCategoryTreeNodes?: CategoryTreeNode[]
  leafCategoryTreeNode: boolean
  parentCategoryTreeNodeHref: string
}

export interface Category {
  categoryId: string
  categoryName: string
}

export enum MarketplaceIdEnum {
  EBAY_AT = 'EBAY_AT',
  EBAY_AU = 'EBAY_AU',
  EBAY_BE = 'EBAY_BE',
  EBAY_CA = 'EBAY_CA',
  EBAY_CH = 'EBAY_CH',
  EBAY_DE = 'EBAY_DE',
  EBAY_ES = 'EBAY_ES',
  EBAY_FR = 'EBAY_FR',
  EBAY_GB = 'EBAY_GB',
  EBAY_HK = 'EBAY_HK',
  EBAY_IE = 'EBAY_IE',
  EBAY_IN = 'EBAY_IN',
  EBAY_IT = 'EBAY_IT',
  EBAY_MOTORS_US = 'EBAY_MOTORS_US',
  EBAY_MY = 'EBAY_MY',
  EBAY_NL = 'EBAY_NL',
  EBAY_PH = 'EBAY_PH',
  EBAY_PL = 'EBAY_PL',
  EBAY_RU = 'EBAY_RU',
  EBAY_SG = 'EBAY_SG',
  EBAY_US = 'EBAY_US',
}

export interface EbayOffer {
  offerId: string
  availableQuantity: number // integer
  categoryId: string
  charity?: Charity
  extendedProducerResponsibility?: ExtendedProducerResponsibility
  format: FormatTypeEnum
  hideBuyerDetails?: boolean
  includeCatalogProductDetails?: boolean
  listingDescription?: string
  listingDuration: ListingDurationEnum
  listingPolicies?: ListingPoliciesIds
  listingStartDate?: string
  lotSize?: string
  marketplaceId: MarketplaceIdEnum
  merchantLocationKey?: string
  pricingSummary?: OfferPricingSummary
  quantityLimitPerBuyer?: number // integer
  secondaryCategoryId?: string
  sku: string
  storeCategoryNames?: string[]
  tax?: Tax
  listing?: ListingDetails
  status?: OfferStatusEnum
}

export type CreateEbayOffer = Optional<EbayOffer, 'offerId'>

export interface ListingDetails {
  listingId: string
  listingStatus: ListingStatusEnum
  soldQuantity: number // integer
}
export enum ListingStatusEnum {
  ACTIVE = 'ACTIVE',
  OUT_OF_STOCK = 'OUT_OF_STOCK',
  INACTIVE = 'INACTIVE',
  ENDED = 'ENDED',
  EBAY_ENDED = 'EBAY_ENDED',
  NOT_LISTED = 'NOT_LISTED',
}
export enum OfferStatusEnum {
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
}

export interface Charity {
  charityId: string
  donationPercentage: string
}

export interface ExtendedProducerResponsibility {
  producerProductId: string
  productPackageId: string
  shipmentPackageId: string
  productDocumentationId: string
  ecoParticipationFee: Amount
}

export interface ListingPolicies {
  fulfillmentPolicies?: FulfillmentPolicy[]
  paymentPolicies?: PaymentPolicy[]
  returnPolicies?: ReturnPolicy[]
}

export interface ListingPoliciesIds {
  bestOfferTerms?: BestOfferTerms
  eBayPlusIfEligible?: string
  fulfillmentPolicyId?: string
  paymentPolicyId?: string
  productCompliancePolicyIds?: string[]
  returnPolicyId?: string
  shippingCostOverrides?: ShippingCostOverride[]
  takeBackPolicyId?: string
}

export interface BestOfferTerms {
  autoAcceptPrice: Amount
  autoDeclinePrice: Amount
  bestOfferEnabled: boolean
}

export interface ShippingCostOverride {
  additionalShippingCost: Amount
  priority: string
  shippingCost: Amount
  shippingServiceType: ShippingServiceTypeEnum
  surcharge: Amount
}

export interface OfferPricingSummary {
  auctionReservePrice?: Amount
  auctionStartPrice?: Amount
  minimumAdvertisedPrice?: Amount
  originallySoldForRetailPriceOn?: SoldOnEnum
  originalRetailPrice?: Amount
  price: Amount
  pricingVisibility?: string
}
export interface OrderPricingSummary {
  adjustment: Amount
  deliveryCost: DeliveryCost
  deliveryDiscount: Amount
  fee: Amount
  priceDiscountSubtotal: Amount
  priceSubtotal: Amount
  tax: Tax
  total: Amount
}

export interface Tax {
  applyTax: boolean
  thirdPartyTaxCategory: string
  vatPercentage: string
}

export enum SoldOnEnum {
  ON_EBAY = 'ON_EBAY',
  OFF_EBAY = 'OFF_EBAY',
  ON_AND_OFF_EBAY = 'ON_AND_OFF_EBAY',
}

export enum MinimumAdvertisedPriceHandlingEnum {
  NONE = 'NONE',
  PRE_CHECKOUT = 'PRE_CHECKOUT',
  DURING_CHECKOUT = 'DURING_CHECKOUT',
}

export enum FormatTypeEnum {
  AUCTION = 'AUCTION',
  FIXED_PRICE = 'FIXED_PRICE',
}

export enum ShippingServiceTypeEnum {
  DOMESTIC = 'DOMESTIC',
  INTERNATIONAL = 'INTERNATIONAL',
}

export enum ListingDurationEnum {
  DAYS_1 = 'DAYS_1',
  DAYS_3 = 'DAYS_3',
  DAYS_5 = 'DAYS_5',
  DAYS_7 = 'DAYS_7',
  DAYS_10 = 'DAYS_10',
  DAYS_21 = 'DAYS_21',
  DAYS_30 = 'DAYS_30',
  GTC = 'GTC',
}

export interface PaymentPolicy {
  categoryTypes: CategoryType[]
  deposit?: Deposit
  description: string
  fullPaymentDueIn?: FullPaymentDueIn
  immediatePay: boolean
  marketplaceId: MarketplaceIdEnum
  name: string
  paymentInstructions?: string // depreciated
  paymentMethods?: PaymentMethod[]
  paymentPolicyId?: string
}

export interface CategoryType {
  default?: boolean // depreciated
  name: string
}

export interface Deposit {
  amount: Amount
  dueIn: DueIn
  paymentMethods: PaymentMethod[]
}

export interface Amount {
  currency: CurrencyCodeEnum
  value: string // float
  convertedFromCurrency?: CurrencyCodeEnum
  convertedFromValue?: string // float
}

export interface DueIn {
  unit: TimeDurationUnitEnum
  value: number // integer
}

export interface PaymentMethod {
  brands: PaymentInstrumentBrandEnum[]
  paymentMethodType: PaymentMethodTypeEnum
  recipientAccountReference: RecipientAccountReference
}

export interface RecipientAccountReference {
  referenceId: string
  referenceType: RecipientAccountReferenceTypeEnum
}

export interface FullPaymentDueIn {
  unit: string
  value: string
}

export enum CategoryTypeEnum {
  MOTORS_VEHICLES = 'MOTORS_VEHICLES',
  ALL_EXCLUDING_MOTORS_VEHICLES = 'ALL_EXCLUDING_MOTORS_VEHICLES',
}

export enum CurrencyCodeEnum {
  AED = 'AED',
  AFN = 'AFN',
  ALL = 'ALL',
  AMD = 'AMD',
  ANG = 'ANG',
  AOA = 'AOA',
  ARS = 'ARS',
  AUD = 'AUD',
  AWG = 'AWG',
  AZN = 'AZN',
  BAM = 'BAM',
  BBD = 'BBD',
  BDT = 'BDT',
  BGN = 'BGN',
  BHD = 'BHD',
  BIF = 'BIF',
  BMD = 'BMD',
  BND = 'BND',
  BOB = 'BOB',
  BRL = 'BRL',
  BSD = 'BSD',
  BTN = 'BTN',
  BWP = 'BWP',
  BYR = 'BYR',
  BZD = 'BZD',
  CAD = 'CAD',
  CDF = 'CDF',
  CHF = 'CHF',
  CLP = 'CLP',
  CNY = 'CNY',
  COP = 'COP',
  CRC = 'CRC',
  CUP = 'CUP',
  CVE = 'CVE',
  CZK = 'CZK',
  DJF = 'DJF',
  DKK = 'DKK',
  DOP = 'DOP',
  DZD = 'DZD',
  EGP = 'EGP',
  ERN = 'ERN',
  ETB = 'ETB',
  EUR = 'EUR',
  FJD = 'FJD',
  FKP = 'FKP',
  GBP = 'GBP',
  GEL = 'GEL',
  GHS = 'GHS',
  GIP = 'GIP',
  GMD = 'GMD',
  GNF = 'GNF',
  GTQ = 'GTQ',
  GYD = 'GYD',
  HKD = 'HKD',
  HNL = 'HNL',
  HRK = 'HRK',
  HTG = 'HTG',
  HUF = 'HUF',
  IDR = 'IDR',
  ILS = 'ILS',
  INR = 'INR',
  IQD = 'IQD',
  IRR = 'IRR',
  ISK = 'ISK',
  JMD = 'JMD',
  JOD = 'JOD',
  JPY = 'JPY',
  KES = 'KES',
  KGS = 'KGS',
  KHR = 'KHR',
  KMF = 'KMF',
  KPW = 'KPW',
  KRW = 'KRW',
  KWD = 'KWD',
  KYD = 'KYD',
  KZT = 'KZT',
  LAK = 'LAK',
  LBP = 'LBP',
  LKR = 'LKR',
  LRD = 'LRD',
  LSL = 'LSL',
  LTL = 'LTL',
  LYD = 'LYD',
  MAD = 'MAD',
  MDL = 'MDL',
  MGA = 'MGA',
  MKD = 'MKD',
  MMK = 'MMK',
  MNT = 'MNT',
  MOP = 'MOP',
  MRO = 'MRO',
  MUR = 'MUR',
  MVR = 'MVR',
  MWK = 'MWK',
  MXN = 'MXN',
  MYR = 'MYR',
  MZN = 'MZN',
  NAD = 'NAD',
  NGN = 'NGN',
  NIO = 'NIO',
  NOK = 'NOK',
  NPR = 'NPR',
  NZD = 'NZD',
  OMR = 'OMR',
  PAB = 'PAB',
  PEN = 'PEN',
  PGK = 'PGK',
  PHP = 'PHP',
  PKR = 'PKR',
  PLN = 'PLN',
  PYG = 'PYG',
  QAR = 'QAR',
  RON = 'RON',
  RSD = 'RSD',
  RUB = 'RUB',
  RWF = 'RWF',
  SAR = 'SAR',
  SBD = 'SBD',
  SCR = 'SCR',
  SDG = 'SDG',
  SEK = 'SEK',
  SGD = 'SGD',
  SHP = 'SHP',
  SLL = 'SLL',
  SOS = 'SOS',
  SRD = 'SRD',
  STD = 'STD',
  SYP = 'SYP',
  SZL = 'SZL',
  THB = 'THB',
  TJS = 'TJS',
  TMT = 'TMT',
  TND = 'TND',
  TOP = 'TOP',
  TRY = 'TRY',
  TTD = 'TTD',
  TWD = 'TWD',
  TZS = 'TZS',
  UAH = 'UAH',
  UGX = 'UGX',
  USD = 'USD',
  UYU = 'UYU',
  UZS = 'UZS',
  VEF = 'VEF',
  VND = 'VND',
  VUV = 'VUV',
  WST = 'WST',
  XAF = 'XAF',
  XCD = 'XCD',
  XOF = 'XOF',
  XPF = 'XPF',
  YER = 'YER',
  ZAR = 'ZAR',
  ZMW = 'ZMW',
  ZWL = 'ZWL',
}

export enum PaymentInstrumentBrandEnum {
  AMERICAN_EXPRESS = 'AMERICAN_EXPRESS',
  DISCOVER = 'DISCOVER',
  MASTERCARD = 'MASTERCARD',
  VISA = 'VISA',
}

export enum PaymentMethodTypeEnum {
  CASH_IN_PERSON = 'CASH_IN_PERSON',
  CASH_ON_DELIVERY = 'CASH_ON_DELIVERY',
  CASH_ON_PICKUP = 'CASH_ON_PICKUP',
  CASHIER_CHECK = 'CASHIER_CHECK',
  CREDIT_CARD = 'CREDIT_CARD',
  ESCROW = 'ESCROW',
  INTEGRATED_MERCHANT_CREDIT_CARD = 'INTEGRATED_MERCHANT_CREDIT_CARD',
  LOAN_CHECK = 'LOAN_CHECK',
  MONEY_ORDER = 'MONEY_ORDER',
  PAISA_PAY = 'PAISA_PAY',
  PAISA_PAY_ESCROW = 'PAISA_PAY_ESCROW',
  PAISA_PAY_ESCROW_EMI = 'PAISA_PAY_ESCROW_EMI',
  PAYPAL = 'PAYPAL',
  PERSONAL_CHECK = 'PERSONAL_CHECK',
  OTHER = 'OTHER',
}

export enum RecipientAccountReferenceTypeEnum {
  PAYPAL_EMAIL = 'PAYPAL_EMAIL',
}

export interface PaginatedResponse {
  href: string
  limit: number // integer
  next: string
  offset: number // integer
  prev: string
  total: number // integer
  warnings?: ErrorDetail[]
}
export interface GetPaymentPoliciesResponse extends PaginatedResponse {
  paymentPolicies: PaymentPolicy[]
}

export interface GetReturnPoliciesResponse extends PaginatedResponse {
  returnPolicies: ReturnPolicy[]
}

export interface GetFulfillmentPoliciesResponse extends PaginatedResponse {
  fulfillmentPolicies: FulfillmentPolicy[]
}

export interface GetOffersResponse extends PaginatedResponse {
  offers: EbayOffer[]
}

export interface GetOrdersResponse extends PaginatedResponse {
  orders: Order[]
}

export interface ReturnPolicy {
  categoryTypes: CategoryType[]
  description: string
  extendedHolidayReturnsOffered?: boolean // depreciated
  internationalOverride?: InternationalOverride
  marketplaceId: MarketplaceIdEnum
  name: string
  refundMethod?: RefundMethodEnum
  restockingFeePercentage?: string // depreciated
  returnInstructions?: string
  returnMethod?: ReturnMethodEnum
  returnPeriod?: ReturnPeriod
  returnsAccepted?: boolean
  returnShippingCostPayer?: ReturnShippingCostPayerEnum
  returnPolicyId?: string
}

export interface InternationalOverride {
  returnMethod: ReturnMethodEnum
  returnPeriod: ReturnPeriod
  returnsAccepted: boolean
  returnShippingCostPayer: ReturnShippingCostPayerEnum
}

export interface ReturnPeriod {
  unit: TimeDurationUnitEnum
  value: number // integer
}

export enum ReturnMethodEnum {
  EXCHANGE = 'EXCHANGE',
  REPLACEMENT = 'REPLACEMENT',
}

export enum ReturnShippingCostPayerEnum {
  BUYER = 'BUYER',
  SELLER = 'SELLER',
}

export enum RefundMethodEnum {
  MERCHANDISE_CREDIT = 'MERCHANDISE_CREDIT',
  MONEY_BACK = 'MONEY_BACK',
}

export interface FulfillmentPolicy {
  categoryTypes: CategoryType[]
  description: string
  freightShipping?: boolean
  globalShipping?: boolean
  handlingTime?: HandlingTime
  localPickup?: boolean
  marketplaceId: MarketplaceIdEnum
  name: string
  pickupDropOff?: boolean
  shippingOptions: ShippingOption[]
  shipToLocations?: ShipToLocations
  fulfillmentPolicyId?: string
}

export interface HandlingTime {
  unit: string
  value: string
}

export interface ShippingOption {
  costType: ShippingCostTypeEnum
  insuranceFee?: Amount
  insuranceOffered?: boolean
  optionType?: ShippingOptionTypeEnum
  packageHandlingCost?: Amount
  rateTableId?: string
  shippingServices?: ShippingService[]
}

export interface ShippingService {
  additionalShippingCost: Amount
  buyerResponsibleForPickup: boolean
  buyerResponsibleForShipping: boolean
  cashOnDeliveryFee: Amount
  freeShipping: boolean
  shippingCarrierCode: string
  shippingCost: Amount
  shippingServiceCode: string
  shipToLocations: ShipToLocations
  sortOrder: number // integer
  surcharge: Amount
}

export interface ShipToLocations {
  regionExcluded: RegionExcluded[]
  regionIncluded: RegionIncluded[]
}

export interface RegionExcluded {
  regionName: string
  regionType: RegionTypeEnum
}

export interface RegionIncluded {
  regionName: string
  regionType: RegionTypeEnum
}

export interface ShipToLocations {
  regionExcluded: RegionExcluded[]
  regionIncluded: RegionIncluded[]
}

export enum ShippingCostTypeEnum {
  CALCULATED = 'CALCULATED',
  FLAT_RATE = 'FLAT_RATE',
  NOT_SPECIFIED = 'NOT_SPECIFIED',
}

export enum RegionTypeEnum {
  COUNTRY = 'COUNTRY',
  COUNTRY_REGION = 'COUNTRY_REGION',
  STATE_OR_PROVINCE = 'STATE_OR_PROVINCE',
  WORLD_REGION = 'WORLD_REGION',
  WORLDWIDE = 'WORLDWIDE',
}

export enum ShippingOptionTypeEnum {
  DOMESTIC = 'DOMESTIC',
  INTERNATIONAL = 'INTERNATIONAL',
}

export interface OfferResponse extends BaseResponse {
  offerId: string
}

export interface BaseResponse {
  warnings?: ErrorDetail[]
  errors?: ErrorDetail[]
}

export interface ErrorDetail {
  category: string
  domain: string
  errorId: number // integer
  inputRefIds: [string]
  longMessage: string
  message: string
  outputRefIds: [string]
  parameters?: ErrorParameter[]
  subdomain: string
}

export interface ErrorParameter {
  name: string
  value: string
}

export interface FilterField {
  field: 'creationdate' | 'lastmodifieddate' | 'orderfulfillmentstatus'
  range: RangeValue
  set: string[]
  value: string
}

export interface RangeValue {
  end: string
  start: string
  exclusiveEnd: boolean
  exclusiveStart: boolean
  range: boolean
}

export interface Order {
  buyer: Buyer
  buyerCheckoutNotes: string
  cancelStatus: CancelStatus
  creationDate: string
  ebayCollectAndRemitTax: boolean
  fulfillmentHrefs: string[]
  fulfillmentStartInstructions: FulfillmentStartInstruction[]
  lastModifiedDate: string
  legacyOrderId: string
  lineItems: LineItem[]
  orderFulfillmentStatus: string
  orderId: string
  orderPaymentStatus: string
  paymentSummary: PaymentSummary
  pricingSummary: OrderPricingSummary
  program: Program
  salesRecordReference: string
  sellerId: string
  totalFeeBasisAmount: Amount
  totalMarketplaceFee: Amount
}

export interface Buyer {
  taxAddress: TaxAddress
  taxIdentifier: TaxIdentifier
  username: string
}

export interface TaxAddress {
  city: string
  countryCode: CountryCodeEnum
  postalCode: string
  stateOrProvince: string
}

export interface TaxIdentifier {
  taxpayerId: string
  taxIdentifierType: TaxIdentifierTypeEnum
  issuingCountry: CountryCodeEnum
}

export interface CancelStatus {
  cancelledDate: string
  cancelRequests: CancelRequest[]
  cancelState: CancelStateEnum
}

export interface CancelRequest {
  cancelCompletedDate: string
  cancelInitiator: string
  cancelReason: string
  cancelRequestedDate: string
  cancelRequestId: string
  cancelRequestState: CancelRequestStateEnum
}

export interface FulfillmentStartInstruction {
  destinationTimeZone: string
  ebaySupportedFulfillment: boolean
  finalDestinationAddress: Address
  fulfillmentInstructionsType: FulfillmentInstructionsTypeEnum
  maxEstimatedDeliveryDate: string
  minEstimatedDeliveryDate: string
  pickupStep: PickupStep
  shippingStep: ShippingStep
}

export interface Address {
  addressLine1?: string
  addressLine2?: string
  city?: string
  countryCode: CountryCodeEnum
  county?: string
  postalCode?: string
  stateOrProvince?: string
}

export interface PickupStep {
  merchantLocationKey: string
}

export interface ShippingStep {
  shippingCarrierCode: string
  shippingServiceCode: string
  shipTo: ShipTo
  shipToReferenceId: string
}

export interface ShipTo {
  companyName: string
  contactAddress: Address
  email: string
  fullName: string
  primaryPhone: PrimaryPhone
}

export interface PrimaryPhone {
  phoneNumber: string
}

export interface LineItem {
  appliedPromotions: AppliedPromotion[]
  deliveryCost: DeliveryCost
  discountedLineItemCost: Amount
  ebayCollectAndRemitTaxes: EbayCollectAndRemitTax[]
  ebayCollectedCharges: EbayCollectedCharges
  giftDetails: GiftDetails
  itemLocation: ItemLocation
  legacyItemId: string
  legacyVariationId: string
  lineItemCost: Amount
  lineItemFulfillmentInstructions: LineItemFulfillmentInstructions
  lineItemFulfillmentStatus: LineItemFulfillmentStatusEnum
  lineItemId: string
  listingMarketplaceId: MarketplaceIdEnum
  properties: LineItemProperties
  purchaseMarketplaceId: MarketplaceIdEnum
  quantity: number // integer
  refunds: LineItemRefund[]
  sku: string
  soldFormat: SoldFormatEnum
  taxes: LineItemTax[]
  title: string
  total: Amount
}

export interface AppliedPromotion {
  description: string
  discountAmount: Amount
  promotionId: string
}

export interface DeliveryCost {
  importCharges: Amount
  shippingCost: Amount
  shippingIntermediationFee: Amount
}

export interface EbayCollectAndRemitTax {
  amount: Amount
  ebayReference: EbayTaxReference
  taxType: TaxTypeEnum
  collectionMethod: CollectionMethodEnum
}

export interface EbayTaxReference {
  name: string
  value: string
}

export interface EbayCollectedCharges {
  ebayShipping: Amount
}

export interface GiftDetails {
  message: string
  recipientEmail: string
  senderName: string
}

export interface ItemLocation {
  countryCode: CountryCodeEnum
  location: string
  postalCode: string
}

export interface LineItemFulfillmentInstructions {
  destinationTimeZone: string
  guaranteedDelivery: boolean
  maxEstimatedDeliveryDate: string
  minEstimatedDeliveryDate: string
  shipByDate: string
  sourceTimeZone: string
}

export interface LineItemProperties {
  buyerProtection: boolean
  fromBestOffer: boolean
  soldViaAdCampaign: boolean
}

export interface LineItemRefund {
  amount: Amount
  refundDate: string
  refundId: string
  refundReferenceId: string
}

export interface LineItemTax {
  amount: Amount
  taxType: TaxTypeEnum
}

export interface PaymentSummary {
  payments: Payment[]
  refunds: LineItemRefund[]
  totalDueSeller: Amount
}

export interface Payment {
  amount: Amount
  paymentDate: string
  paymentHolds: PaymentHold[]
  paymentMethod: string
  paymentReferenceId: string
  paymentStatus: string
}

export interface PaymentHold {
  expectedReleaseDate: string
  holdAmount: Amount
  holdReason: string
  holdState: string
  releaseDate: string
  sellerActionsToRelease: SellerActionsToRelease[]
}

export interface SellerActionsToRelease {
  sellerActionToRelease: string
}

export interface Program {
  authenticityVerification: AuthenticityVerification
  ebayShipping: Amount
  ebayVault: EbayVault
  ebayInternationalShipping: EbayInternationalShipping
  fulfillmentProgram: FulfillmentProgram
}

export interface AuthenticityVerification {
  outcomeReason: string
  status: string
}

export interface EbayVault {
  fulfillmentType: string
}

export interface EbayInternationalShipping {
  returnsManagedBy: string
}

export interface FulfillmentProgram {
  fulfilledBy: string
}

export interface Parameter {
  name: string
  value: string
}

export enum CountryCodeEnum {
  AD = 'AD',
  AE = 'AE',
  AF = 'AF',
  AG = 'AG',
  AI = 'AI',
  AL = 'AL',
  AM = 'AM',
  AN = 'AN',
  AO = 'AO',
  AQ = 'AQ',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AU = 'AU',
  AW = 'AW',
  AX = 'AX',
  AZ = 'AZ',
  BA = 'BA',
  BB = 'BB',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BG = 'BG',
  BH = 'BH',
  BI = 'BI',
  BJ = 'BJ',
  BL = 'BL',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BQ = 'BQ',
  BR = 'BR',
  BS = 'BS',
  BT = 'BT',
  BV = 'BV',
  BW = 'BW',
  BY = 'BY',
  BZ = 'BZ',
  CA = 'CA',
  CC = 'CC',
  CD = 'CD',
  CF = 'CF',
  CG = 'CG',
  CH = 'CH',
  CI = 'CI',
  CK = 'CK',
  CL = 'CL',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  CR = 'CR',
  CU = 'CU',
  CV = 'CV',
  CW = 'CW',
  CX = 'CX',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DJ = 'DJ',
  DK = 'DK',
  DM = 'DM',
  DO = 'DO',
  DZ = 'DZ',
  EC = 'EC',
  EE = 'EE',
  EG = 'EG',
  EH = 'EH',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  FI = 'FI',
  FJ = 'FJ',
  FK = 'FK',
  FM = 'FM',
  FO = 'FO',
  FR = 'FR',
  GA = 'GA',
  GB = 'GB',
  GD = 'GD',
  GE = 'GE',
  GF = 'GF',
  GG = 'GG',
  GH = 'GH',
  GI = 'GI',
  GL = 'GL',
  GM = 'GM',
  GN = 'GN',
  GP = 'GP',
  GQ = 'GQ',
  GR = 'GR',
  GS = 'GS',
  GT = 'GT',
  GU = 'GU',
  GW = 'GW',
  GY = 'GY',
  HK = 'HK',
  HM = 'HM',
  HN = 'HN',
  HR = 'HR',
  HT = 'HT',
  HU = 'HU',
  ID = 'ID',
  IE = 'IE',
  IL = 'IL',
  IM = 'IM',
  IN = 'IN',
  IO = 'IO',
  IQ = 'IQ',
  IR = 'IR',
  IS = 'IS',
  IT = 'IT',
  JE = 'JE',
  JM = 'JM',
  JO = 'JO',
  JP = 'JP',
  KE = 'KE',
  KG = 'KG',
  KH = 'KH',
  KI = 'KI',
  KM = 'KM',
  KN = 'KN',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KY = 'KY',
  KZ = 'KZ',
  LA = 'LA',
  LB = 'LB',
  LC = 'LC',
  LI = 'LI',
  LK = 'LK',
  LR = 'LR',
  LS = 'LS',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  LY = 'LY',
  MA = 'MA',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MF = 'MF',
  MG = 'MG',
  MH = 'MH',
  MK = 'MK',
  ML = 'ML',
  MM = 'MM',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MQ = 'MQ',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MU = 'MU',
  MV = 'MV',
  MW = 'MW',
  MX = 'MX',
  MY = 'MY',
  MZ = 'MZ',
  NA = 'NA',
  NC = 'NC',
  NE = 'NE',
  NF = 'NF',
  NG = 'NG',
  NI = 'NI',
  NL = 'NL',
  NO = 'NO',
  NP = 'NP',
  NR = 'NR',
  NU = 'NU',
  NZ = 'NZ',
  OM = 'OM',
  PA = 'PA',
  PE = 'PE',
  PF = 'PF',
  PG = 'PG',
  PH = 'PH',
  PK = 'PK',
  PL = 'PL',
  PM = 'PM',
  PN = 'PN',
  PR = 'PR',
  PS = 'PS',
  PT = 'PT',
  PW = 'PW',
  PY = 'PY',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  RW = 'RW',
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SG = 'SG',
  SH = 'SH',
  SI = 'SI',
  SJ = 'SJ',
  SK = 'SK',
  SL = 'SL',
  SM = 'SM',
  SN = 'SN',
  SO = 'SO',
  SR = 'SR',
  ST = 'ST',
  SV = 'SV',
  SX = 'SX',
  SY = 'SY',
  SZ = 'SZ',
  TC = 'TC',
  TD = 'TD',
  TF = 'TF',
  TG = 'TG',
  TH = 'TH',
  TJ = 'TJ',
  TK = 'TK',
  TL = 'TL',
  TM = 'TM',
  TN = 'TN',
  TO = 'TO',
  TR = 'TR',
  TT = 'TT',
  TV = 'TV',
  TW = 'TW',
  TZ = 'TZ',
  UA = 'UA',
  UG = 'UG',
  UM = 'UM',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VA = 'VA',
  VC = 'VC',
  VE = 'VE',
  VG = 'VG',
  VI = 'VI',
  VN = 'VN',
  VU = 'VU',
  WF = 'WF',
  WS = 'WS',
  YE = 'YE',
  YT = 'YT',
  ZA = 'ZA',
  ZM = 'ZM',
  ZW = 'ZW',
}

export enum TaxIdentifierTypeEnum {
  CODICE_FISCALE = 'CODICE_FISCALE',
  DNI = 'DNI',
  NIE = 'NIE',
  NIF = 'NIF',
  NIT = 'NIT',
  VATIN = 'VATIN',
}

export enum CancelStateEnum {
  CANCELED = 'CANCELED',
  IN_PROGRESS = 'IN_PROGRESS',
  NONE_REQUESTED = 'NONE_REQUESTED',
}

export enum CancelRequestStateEnum {
  COMPLETED = 'COMPLETED',
  REJECTED = 'REJECTED',
  REQUESTED = 'REQUESTED',
}

export enum FulfillmentInstructionsTypeEnum {
  DIGITAL = 'DIGITAL',
  PREPARE_FOR_PICKUP = 'PREPARE_FOR_PICKUP',
  SELLER_DEFINED = 'SELLER_DEFINED',
  SHIP_TO = 'SHIP_TO',
  FULFILLED_BY_EBAY = 'FULFILLED_BY_EBAY',
}

export enum TaxTypeEnum {
  GST = 'GST',
  PROVINCE_SALES_TAX = 'PROVINCE_SALES_TAX',
  REGION = 'REGION',
  STATE_SALES_TAX = 'STATE_SALES_TAX',
  VAT = 'VAT',
  WHITE_GOODS_DISPOSABLE_TAX = 'WHITE_GOODS_DISPOSABLE_TAX',
  ELECTRONIC_RECYCLING_FEE = 'ELECTRONIC_RECYCLING_FEE',
  MATTRESS_RECYCLING_FEE = 'MATTRESS_RECYCLING_FEE',
  ADDITIONAL_FEE = 'ADDITIONAL_FEE',
  BATTERY_RECYCLING_FEE = 'BATTERY_RECYCLING_FEE',
  TIRE_RECYCLING_FEE = 'TIRE_RECYCLING_FEE',
}

export enum CollectionMethodEnum {
  INVOICE = 'INVOICE',
  NET = 'NET',
}

export enum LineItemFulfillmentStatusEnum {
  FULFILLED = 'FULFILLED',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_STARTED = 'NOT_STARTED',
}

export enum SoldFormatEnum {
  AUCTION = 'AUCTION',
  FIXED_PRICE = 'FIXED_PRICE',
  OTHER = 'OTHER',
  SECOND_CHANCE_OFFER = 'SECOND_CHANCE_OFFER',
}

export interface InventoryLocation {
  location: LocationDetails
  locationAdditionalInformation?: string
  locationInstructions?: string
  locationTypes?: StoreTypeEnum
  locationWebUrl?: string
  merchantLocationStatus?: StatusEnum
  name?: string
  operatingHours?: OperatingHours[]
  phone?: string
  specialHours?: SpecialHours[]
}

export interface LocationDetails {
  address: LocationAddress
  geoCoordinates?: GeoCoordinates
}

export interface LocationAddress {
  addressLine1?: string
  addressLine2?: string
  city?: string
  country: CountryCodeEnum
  county?: string
  postalCode: string
  stateOrProvince?: string
}

export interface GeoCoordinates {
  latitude: string
  longitude: string
}

export interface OperatingHours {
  dayOfWeekEnum: DayOfWeekEnum
  intervals: Interval[]
}

export interface Interval {
  close: string
  open: string
}

export interface SpecialHours {
  date: string
  intervals: Interval[]
}

export enum StoreTypeEnum {
  STORE = 'STORE',
  WAREHOUSE = 'WAREHOUSE',
}

export enum StatusEnum {
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED',
}

export enum DayOfWeekEnum {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export type EbayTemplateIntegration = {
  id?: number
  integrationId?: number
  templateId?: number
  ebayCategoryId?: number
  ebayCategoryName?: string
  ebayCategoryVersion?: number
  name: string
  index: number
  title: string
  subtitle: string
  description: string
  condition: string
  conditionDescription: string
  brand: string
  pricingTitle?: string
  pricingAspects?: string
  list?: boolean
  sync?: boolean
  syncQuantity?: boolean
  active?: boolean
  createdAt?: string
  updatedAt?: string
  aspects?: EbayAspectTemplateIntegration[]
}

export type EbayAspectTemplateIntegration = {
  id?: number
  ebayTemplateIntegrationId?: number
  aspectName: string
  value: string
  dataType: string
  required: boolean
  active?: boolean
  createdAt?: string
  updatedAt?: string
}

export type EbayCategoryTemplateIntegration = {
  id: number
  templateIntegrationId: number
  ebayCategoryId?: number
  ebayCategoryName?: string
  ebayCategoryVersion?: number
  name: string
  title?: string
  subtitle?: string
  description?: string
  condition?: string
  conditionDescription?: string
  brand?: string
  active: boolean
  createdAt: string
  updatedAt: string
  aspects?: EbayCategoryAspectTemplateIntegration[]
}

export type EbayCategoryAspectTemplateIntegration = {
  id: number
  ebayCategoryTemplateIntegrationId: number
  aspectName: string
  value: string
  dataType: string
  required: boolean
  active: boolean
}

export type GetEbayIntegrationsResult = {
  integration?: Integration
  templateIntegrations?: EbayTemplateIntegration[]
  productIntegrations?: EbayProductIntegration[]
}

export type EbayProductIntegration = {
  id?: number
  templateIntegrationId: number
  productId: number
  name?: string
  index?: number
  selected: boolean
  ebayCategoryId?: number
  ebayCategoryName?: string
  ebayCategoryVersion?: number
  title?: string
  subtitle?: string
  description?: string
  brand?: string
  condition?: string
  conditionDescription?: string
  status?: string
  list?: boolean
  sync?: boolean
  syncQuantity?: boolean
  aspects?: SetEbayAspectProductIntegration[]
}

export type EbayAspectProductIntegration = {
  id: number
  productIntegrationId: number
  aspectName: string
  value: string
  dataType: string
  required: boolean
}
export type SetEbayAspectProductIntegration = Optional<
  DataFields<EbayAspectProductIntegration>,
  'productIntegrationId'
>

export type EbayTemplateListingConfiguration = {
  id?: number
  clientId?: number
  ebayTemplateIntegrationId?: number

  // List and Syncing
  list?: boolean
  sync?: boolean
  syncSales?: boolean

  // List Configurations
  price?: boolean
  basePrice?: number
  priceAdd?: number // fixed amount to add for a channel

  // Relist
  relist?: boolean
  relistEvery?: number

  // Price Reduction
  reducePrice?: boolean
  maxPrice?: number
  reduceBy?: number
  reduceEvery?: number
  lastReduced?: number // time last reduced

  // Auction
  listAuction?: boolean
  startPrice?: number
  buyItNowPrice?: number
  reservePrice?: number // not sure if will use
  auctionLength?: number
  relistAuction?: number // how many times to relist as an auction
  startTime?: number
  startDay?: string

  // General Configuration
  modifyerType?: string // percent, amount
  roundTo?: number // Not sure
}

export type EbayProductListingConfiguration = {
  id?: number
  clientId?: number
  ebayProductIntegrationId?: number

  // List and Syncing
  list?: boolean
  sync?: boolean
  syncSales?: boolean

  // List Configurations
  price?: boolean
  basePrice?: number
  priceAdd?: number // fixed amount to add for a channel

  // Relist
  relist?: boolean
  relistEvery?: number

  // Price Reduction
  reducePrice?: boolean
  maxPrice?: number
  reduceBy?: number
  reduceEvery?: number
  lastReduced?: number | null // time last reduced

  // Auction
  listAuction?: boolean
  startPrice?: number
  buyItNowPrice?: number
  reservePrice?: number // not sure if will use
  auctionLength?: number
  relistAuction?: number // how many times to relist as an auction
  startTime?: number
  startDay?: number
  lastAuctioned?: number // time last listed as auction

  // General Configuration
  modifyerType?: string // percent, amount
  roundTo?: number // Not sure
}

export type EbayRefinementResponse = {
  _type: string
  meta: {
    name: string
    trackingList: {
      eventFamily?: string
      actionKind?: string
      actionKinds?: string[]
      eventAction: string
      operationId: string
      flushImmediately: boolean
      eventProperty: {
        parentrq?: string
        pageci?: string
        moduledtl: string
      }
    }[]
  }
  group: {
    _type: 'Group'
    fieldId: string
    selectionType: string
    expandInline: boolean
    entries: EbayRefinementEntry[]
  }[]
  heading: unknown
  subHeading: unknown
  baseURL: string
  actions: unknown
  paramKeyValues: unknown
  filterSelectedL: unknown
  noFilter: unknown
  removeFilter: unknown
  activeGroupId: string
  accessibilityText: string
}

export type EbayRefinementEntry = {
  _type: string
  fieldId: string
  paramKey: string
  label: {
    _type: string
    textSpans?: unknown
  }
  beginParamKey: string
  beginParamValue: string
  endParamKey: string
  endParamValue: string
  secondaryLabel: string
  uxComponentHint: string
  action: {
    _type: string
    type: string
    name: string
  }
  selectionType: string
  paramValueDelimiter: string
  needsLoad: boolean
  entries?: EbayRefinementEntry[]
  priceDistributionInfo: {
    _type: string
    minPrice: number
    maxPrice: number
    matchCount: number
  }[]
  [k: string]: unknown
}

export type PriceSuggestion = {
  aspects: string[]
  filterAspects: string[]
  selection: PriceSuggestionValue[]
  graph: PriceSuggestionValue[]
  graphAverage: number
  selectionAverage: number
  searchUrl: string
  title?: string
}

export type PriceSuggestionValue = {
  low?: number
  high?: number
  count?: number
}

export type EbayAttributeAspectValues = { [k: string]: string[] }
