import * as React from 'react'
import { styled, VariantProp } from '@mui/joy/styles'
import Input from '@mui/joy/Input'
import FormHelperText from '@mui/joy/FormHelperText'
import FormControl from '@mui/joy/FormControl'
import Box from '@mui/material/Box'

const StyledInput = styled('input')({
  border: 'none', // remove the native input border
  minWidth: 0, // remove the native input width
  outline: 0, // remove the native input outline
  padding: 0, // remove the native input padding
  flex: 1,
  color: 'inherit',
  backgroundColor: 'transparent',
  fontFamily: 'inherit',
  fontSize: 'inherit',
  fontStyle: 'inherit',
  fontWeight: 'inherit',
  lineHeight: 'inherit',
  textOverflow: 'ellipsis',
  '&::placeholder': {
    opacity: 0,
    transition: '0.1s ease-out',
  },
  '&:focus::placeholder': {
    opacity: 1,
  },
  '&:focus ~ label, &:not(:placeholder-shown) ~ label, &:-webkit-autofill ~ label':
    {
      top: '-0.5rem',
      fontSize: '0.75rem',
      background:
        'linear-gradient(#FBFCFE, #FBFCFE) 0 100% / 100% 0.45rem no-repeat',
      borderRadius: '3px',
      padding: 1,
      paddingLeft: 4,
      paddingRight: 4,
      zIndex: 1,
    },
  '&:focus ~ label': {
    color: 'var(--Input-focusedHighlight)',
  },
  '&:-webkit-autofill': {
    alignSelf: 'stretch', // to fill the height of the root slot
  },
  '&:-webkit-autofill:not(* + &)': {
    marginInlineStart: 'calc(-1 * var(--Input-paddingInline))',
    paddingInlineStart: 'var(--Input-paddingInline)',
    borderTopLeftRadius:
      'calc(var(--Input-radius) - var(--variant-borderWidth, 0px))',
    borderBottomLeftRadius:
      'calc(var(--Input-radius) - var(--variant-borderWidth, 0px))',
  },
})

const StyledLabel = styled('label')(({ theme }) => ({
  position: 'absolute',
  lineHeight: 1,
  top: 'calc((var(--Input-minHeight) - 1em) / 2)',
  color: theme.vars.palette.text.tertiary,
  fontWeight: theme.vars.fontWeight.md,
  transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1)',
}))

export const InnerInput = React.forwardRef<
  HTMLInputElement,
  JSX.IntrinsicElements['input'] & { label?: string | JSX.Element }
>(function InnerInput(props, ref) {
  const id = React.useId()
  return (
    <React.Fragment>
      <StyledInput {...props} ref={ref} id={id} />
      <StyledLabel htmlFor={id}>{props.label}</StyledLabel>
    </React.Fragment>
  )
})

export default function FloatLabelInput({
  value,
  defaultValue,
  onChange,
  onClick,
  onBlur,
  onFocus,
  onKeyDown,
  label,
  placeholder,
  type,
  size,
  variant,
  fullWidth,
  required,
  disabled,
  readOnly,
  errorText,
  helperText,
  autoComplete,
  autoCapitalize,
  autoCorrect,
  format,
  endDecorator,
  button,
}: {
  value?: string | number
  defaultValue?: string
  onChange?: (value: string) => void
  onBlur?: (e?: React.FocusEvent<HTMLInputElement, Element>) => void
  onClick?: () => void
  onFocus?: () => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void
  label?: string | JSX.Element
  placeholder?: string
  type?: React.HTMLInputTypeAttribute
  size?: 'sm' | 'md' | 'lg'
  variant?: VariantProp
  fullWidth?: boolean
  required?: boolean
  disabled?: boolean
  readOnly?: boolean
  errorText?: string
  helperText?: string | React.ReactNode
  autoComplete?: string
  autoCapitalize?: boolean
  autoCorrect?: boolean
  format?: 'USD'
  endDecorator?: React.ReactNode
  button?: React.ReactNode
}): JSX.Element {
  const InputElement = (
    <Input
      value={value !== undefined && value !== null ? value : undefined}
      defaultValue={
        (value === undefined || value === null) && defaultValue
          ? defaultValue
          : undefined
      }
      variant={variant}
      onChange={(e) => onChange?.(e.target.value)}
      onBlur={onBlur}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      onClick={onClick}
      autoComplete={autoComplete ? autoComplete : 'off'}
      required={required}
      disabled={disabled}
      size={size}
      fullWidth={fullWidth}
      error={!!errorText}
      readOnly={readOnly}
      endDecorator={endDecorator}
      slots={label ? { input: InnerInput } : undefined}
      slotProps={{
        input: {
          label: label,
          type: type,
          placeholder: placeholder,
          autoCapitalize: autoCapitalize === false ? 'none' : undefined,
          autoCorrect: autoCorrect === false ? 'off' : undefined,
        },
      }}
      sx={{
        '--Input-radius': '6px',
      }}
    />
  )

  return (
    <FormControl>
      {button ? (
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ flexGrow: 1 }}>{InputElement}</Box>
          <Box sx={{ ml: 1 }}>{button}</Box>
        </Box>
      ) : (
        InputElement
      )}

      {errorText || helperText ? (
        <FormHelperText sx={{ ml: 1 }}>
          {errorText || helperText}
        </FormHelperText>
      ) : null}
    </FormControl>
  )
}
