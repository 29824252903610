import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import {
  AspectMetadata,
  CategoryTree,
  EbayAspectTemplateIntegration,
  EbayTemplateIntegration,
} from '../../../../types/Ebay.types'
import { log } from '../../../../utils/functions'
import {
  GetProduct,
  getProductAspectsForCategory,
  GetProductTemplate,
} from '../../../../api/product'
import { DataFields } from '../../../../api/types'

import EbayCategories, { EbayCategory } from './EbayCategories'
import Alert, { AlertInput } from '../../../common/Alert'
import EbayIntegrationConditions from '../../ConditionsInput'
import ItemAspects from './ItemAspects'
import EbayProductAspects from './EbayProductAspects'
import { ProductAttributeInputText } from '../../../../pages/products/product/ProductAttributeInputText'
import { ProductTitleDescription } from '../../ProductTitleDescription'
import NamedInput from '../../../common/NamedInput'
import AttributeSelect from '../../../common/AttributeSelect'
import Button from '@mui/joy/Button'
import Box from '@mui/joy/Box'
import ArrowBack from '@mui/icons-material/ArrowBack'
import IconButton from '@mui/joy/IconButton'
import Tooltip from '@mui/joy/Tooltip'
import ListSyncComponent from '../common/ListSyncComponent'

export type AspectIntegrationInput = Omit<
  DataFields<EbayAspectTemplateIntegration>,
  'ebayTemplateIntegrationId' | 'type'
>

interface EbayTemplateIntegrationConfigurationComponentProps {
  templateIntegration: EbayTemplateIntegration
  template?: GetProductTemplate
  count?: number
  product?: GetProduct
  categories: CategoryTree
  onChange?: (
    name: string,
    templateIntegration: EbayTemplateIntegration | undefined,
  ) => void
  onUpdate?: () => void
}
export default function EbayTemplateIntegrationConfigurationComponent({
  templateIntegration,
  template,
  product,
  categories,
  count,
  onChange,
  onUpdate,
}: EbayTemplateIntegrationConfigurationComponentProps): JSX.Element {
  const [category, setCategory] = useState<EbayCategory | undefined>(
    templateIntegration.ebayCategoryId && templateIntegration.ebayCategoryName
      ? {
          id: templateIntegration.ebayCategoryId,
          name: templateIntegration.ebayCategoryName,
        }
      : undefined,
  )
  const [categoryVersion, setCategoryVersion] = useState<number>(
    templateIntegration.ebayCategoryVersion || 0,
  )
  const [productAspects, setProductAspects] = useState<
    AspectMetadata | undefined
  >()
  const [ebayAspectIntegrations, setEbayAspectIntegrations] = useState<
    EbayAspectTemplateIntegration[]
  >(templateIntegration.aspects || [])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const [title, setTitle] = useState<string | undefined>(
    templateIntegration.title || '',
  )
  const [subtitle] = useState<string>(templateIntegration.subtitle || '')
  const [description, setDescription] = useState<string | undefined>(
    templateIntegration.description || '',
  )
  const [brand, setBrand] = useState<string>(templateIntegration.brand)
  const [sku] = useState<string>(product?.product.sku || '')
  const [conditionPrimary, setConditionPrimary] = useState<string>(
    product?.product.condition || 'New',
  )
  const [conditionSecondary, setConditionSecondary] = useState<
    string | undefined
  >()
  const [conditionDescription, setConditionDescription] = useState<string>(
    product?.attributes?.find(
      (a) => a.templateAttribute.name === 'Condition Description',
    )?.attribute?.value || '',
  )
  const [price] = useState<number>(product?.product.price ?? 0)
  const [quantity] = useState<number>(product?.product.quantity ?? 0)

  const [list, setList] = useState<boolean>(templateIntegration?.list ?? false)
  const [sync, setSync] = useState<boolean>(templateIntegration?.sync ?? false)
  const [syncQuantity, setSyncQuantity] = useState<boolean>(
    templateIntegration?.syncQuantity ?? false,
  )

  const [detailUpdate] = useState<number>(0)
  const templateAttributes = template?.attributes || []

  const handleChange = () => {
    const newTemplateIntegration: EbayTemplateIntegration = {
      name: templateIntegration.name,
      index: templateIntegration.index,
      integrationId: templateIntegration.integrationId,
      templateId: templateIntegration.templateId,
      ebayCategoryId: category?.id,
      ebayCategoryName: category?.name,
      ebayCategoryVersion: categoryVersion,
      title: title || '',
      subtitle,
      description: description || '',
      condition: conditionPrimary,
      conditionDescription,
      brand: brand,
      list: list,
      sync: sync,
      syncQuantity: syncQuantity,
      aspects: ebayAspectIntegrations,
    }

    onChange?.(templateIntegration.name, newTemplateIntegration)
  }

  const handleDelete = () => {
    onChange?.(templateIntegration.name, undefined)
  }

  const handleIndexChange = (newIndex: number) => {
    const newTemplateIntegration = templateIntegration
    newTemplateIntegration.index = newIndex
    onChange?.(templateIntegration.name, newTemplateIntegration)
  }

  useEffect(() => {
    handleChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    category,
    title,
    description,
    conditionPrimary,
    brand,
    ebayAspectIntegrations,
  ])

  const handleSelectCategory = (
    categoryVersion: number,
    category: EbayCategory | undefined,
  ) => {
    setCategoryVersion(categoryVersion)
    setCategory(category)
  }

  const handleAspectChange = (aspect: AspectIntegrationInput) => {
    // find index
    const newAspectIntegrations = ebayAspectIntegrations.slice()
    const indexOf = newAspectIntegrations.findIndex(
      (a) => a.aspectName === aspect.aspectName,
    )
    // if not found, insert at end
    if (indexOf === -1) {
      newAspectIntegrations.push(aspect as EbayAspectTemplateIntegration)
    } else {
      newAspectIntegrations[indexOf] = aspect as EbayAspectTemplateIntegration
    }

    // save
    setEbayAspectIntegrations(newAspectIntegrations)
  }

  useEffect(() => {
    category?.id &&
      getProductAspectsForCategory(category.id)
        .then((res) => setProductAspects(res?.data || ({} as AspectMetadata)))
        .catch((e) => log(e))
  }, [category])

  const attributeNames =
    template?.attributes.map((a) => a.attribute.name) ||
    product?.attributes.map((a) => a.templateAttribute.name)

  return (
    <Grid container justifyContent="center" spacing={6}>
      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={2}>
          {!product && (
            <Grid item xs={12}>
              <Tooltip
                title="Re-order left"
                size="sm"
                variant="soft"
                placement="right"
              >
                <IconButton
                  variant="plain"
                  size="sm"
                  sx={{ float: 'left' }}
                  disabled={!templateIntegration.index}
                  onClick={() =>
                    handleIndexChange(templateIntegration.index - 1)
                  }
                >
                  <ArrowBack fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip
                title="Re-order right"
                size="sm"
                variant="soft"
                placement="left"
              >
                <IconButton
                  variant="plain"
                  size="sm"
                  sx={{ float: 'right' }}
                  disabled={
                    count !== undefined &&
                    templateIntegration.index !== undefined &&
                    templateIntegration.index >= count - 1
                  }
                  onClick={() =>
                    handleIndexChange(templateIntegration.index + 1)
                  }
                >
                  <ArrowBack fontSize="small" sx={{ rotate: '180deg' }} />
                </IconButton>
              </Tooltip>
            </Grid>
          )}

          <Grid item xs={12}>
            <ListSyncComponent
              title={true}
              list={list}
              sync={sync}
              syncQuantity={syncQuantity}
              onListChange={setList}
              onSyncChange={setSync}
              onSyncQuantityChange={setSyncQuantity}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {/* Default Template Integration */}
        <Grid container justifyContent="center" spacing={2}>
          {/* Category */}
          <Grid item xs={12}>
            {template ? (
              <EbayCategories
                categories={categories}
                category={category}
                categoryVersion={categoryVersion}
                onChange={handleSelectCategory}
                label="Category"
              />
            ) : (
              <ProductAttributeInputText
                label="Category"
                value={
                  '' +
                  category?.name +
                  (category?.id ? ` - ${category.id}` : '')
                }
                disabled={true}
              />
            )}
          </Grid>

          {/* Title and Description */}
          <Grid item xs={12}>
            <ProductTitleDescription
              key={`product-title-description-${detailUpdate}`}
              template={template}
              product={product}
              title={title}
              description={description}
              required={!!product}
              onTitleChange={setTitle}
              onDescriptionChange={setDescription}
              disableUnparsedEdit={true}
            />
          </Grid>

          {/* Product Values */}
          {product && (
            <>
              <Grid item xs={12}>
                <ProductAttributeInputText
                  label="SKU"
                  value={sku}
                  disabled={true}
                />
              </Grid>

              <Grid item xs={12}>
                <NamedInput
                  name="Price"
                  nameEditable={false}
                  value={price}
                  disabled={true}
                  helperText={
                    <Typography level="body-xs">
                      Price may vary according to pricing configuration.
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <NamedInput
                  name="Quantity"
                  nameEditable={false}
                  value={quantity}
                  disabled={true}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <EbayIntegrationConditions
              conditionPrimary={conditionPrimary}
              conditionSecondary={conditionSecondary}
              onChange={(primary, secondary) => {
                setConditionPrimary(primary)
                setConditionSecondary(secondary)
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <AttributeSelect
              name="Condition Description"
              nameEditable={false}
              value={conditionDescription}
              attributeNames={attributeNames}
              multiple={false}
              onChange={(v) => setConditionDescription(v || '')}
            />
          </Grid>

          <Grid item xs={12}>
            <AttributeSelect
              name="Brand"
              nameEditable={false}
              value={brand}
              attributeNames={attributeNames}
              multiple={false}
              onChange={(v) => setBrand(v || '')}
            />
          </Grid>

          {template && (
            <Grid item xs={12} mt={8}>
              <ItemAspects
                templateIntegration={templateIntegration}
                aspects={productAspects?.aspects || []}
                aspectIntegrations={ebayAspectIntegrations}
                templateAttributes={templateAttributes}
                onChange={handleAspectChange}
                onUpdate={onUpdate}
              />
            </Grid>
          )}

          {product && (
            <Grid item xs={12} mt={8}>
              <EbayProductAspects
                product={product}
                templateIntegration={templateIntegration}
                aspects={productAspects?.aspects || []}
                aspectIntegrations={ebayAspectIntegrations}
                templateAttributes={templateAttributes}
                disabled={true}
                onChange={handleAspectChange}
                onUpdate={onUpdate}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Box sx={{ display: 'flex' }}>
              <Button
                onClick={handleDelete}
                variant="soft"
                color="danger"
                sx={{ float: 'left' }}
              >
                Delete
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Grid>
  )
}
