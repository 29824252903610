import * as React from 'react'
import Grid from '@mui/material/Grid'
import FloatLabelInput from './FloatLabelInput'
import { SxProps, Theme } from '@mui/material'
import Autocomplete, { AutocompleteSlots } from '@mui/joy/Autocomplete'
import { useState } from 'react'
import Typography from '@mui/joy/Typography'
import { split } from '../../utils/functions'
import FormHelperText from '@mui/joy/FormHelperText'
import Box from '@mui/joy/Box'

export default function NamedMultiSelect({
  value,
  name,
  nameEditable,
  onChange,
  onNameChange,
  onClick,
  onBlur,
  onNameBlur,
  onFocus,
  onKeyDown,
  label,
  nameLabel,
  placeholder,
  namePlaceholder,
  type,
  size,
  fullWidth,
  required,
  disabled,
  readOnly,
  errorText,
  helperText,
  autoComplete,
  autoCapitalize,
  autoCorrect,
  format,
  endDecorator,
  sx,
  xs,
  sm,
  md,
  lg,
  options,
  freeSolo,
  addValues,
  defaultOption,
  noSpacedInputs,
  warningText,
  onAddValue,
  slots,
  limitTags,
  disableInput,
}: {
  value: string[]
  name?: string
  nameEditable?: false
  onNameChange?: (value: string) => void
  onChange?: (value: string[]) => void
  onBlur?: (e?: React.FocusEvent<HTMLInputElement, Element>) => void
  onNameBlur?: (e?: React.FocusEvent<HTMLInputElement, Element>) => void
  onClick?: () => void
  onFocus?: () => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void
  label?: string | JSX.Element
  nameLabel?: string | JSX.Element
  placeholder?: string
  namePlaceholder?: string
  type?: React.HTMLInputTypeAttribute
  size?: 'sm' | 'md' | 'lg'
  fullWidth?: boolean
  required?: boolean
  disabled?: boolean
  readOnly?: boolean
  errorText?: string
  helperText?: string | React.ReactNode
  autoComplete?: string
  autoCapitalize?: boolean
  autoCorrect?: boolean
  format?: 'USD'
  endDecorator?: React.ReactNode
  sx?: SxProps<Theme> | undefined
  xs?: [number, number]
  sm?: [number, number]
  md?: [number, number]
  lg?: [number, number]
  noSpacedInputs?: boolean
  options?: string[]
  freeSolo?: boolean
  addValues?: boolean
  defaultOption?: boolean
  warningText?: string
  onAddValue?: (value: string[]) => void
  slots?: AutocompleteSlots
  limitTags?: number
  disableInput?: boolean
}): JSX.Element {
  const [inputValue, setInputValue] = useState<string>('')

  const handleInputValueChange = (inputValue: string) => {
    if (disableInput) return
    setInputValue(inputValue)

    const commaRegex = new RegExp(/\s?.*?,+/gim)
    const spaceRegex = new RegExp(/\s?.*?\s+/gim)

    let newValues: string[] = []
    if (commaRegex.exec(inputValue)) {
      newValues = split(inputValue, ',').filter((v) => v)
      // remove unmatched
      if (!freeSolo) {
        newValues = newValues.filter((v) => options?.includes(v))
      }
      onChange?.(value.concat(newValues))
      setInputValue('')
    } else if (noSpacedInputs && spaceRegex.exec(inputValue)) {
      newValues = split(inputValue, ' ').filter((v) => v)
      // remove unmatched
      if (!freeSolo) {
        newValues = newValues.filter((v) => options?.includes(v))
      }
      onChange?.(value.concat(newValues))
      setInputValue('')
    }
  }
  return (
    <Grid container spacing={1} sx={sx}>
      {name ? (
        <Grid
          item
          xs={xs?.[0] || 12}
          sm={sm?.[0] || 12}
          md={md?.[0] || 3}
          lg={lg?.[0] || 2}
        >
          {nameEditable ? (
            <FloatLabelInput
              value={name}
              onChange={onNameChange}
              onBlur={onNameBlur}
              label={nameLabel}
              placeholder={namePlaceholder}
              type={type}
              size={size}
              fullWidth={fullWidth}
              required={required}
              disabled={disabled}
              readOnly={readOnly}
              errorText={errorText}
              helperText={helperText}
              autoComplete={autoComplete}
              autoCapitalize={autoCapitalize}
              autoCorrect={autoCorrect}
              format={format}
            />
          ) : (
            <Typography level="title-md" sx={{ pt: '0.5em' }}>
              <strong>{name}</strong>
            </Typography>
          )}
        </Grid>
      ) : null}
      <Grid
        item
        xs={name ? xs?.[1] || 12 : 12}
        sm={name ? sm?.[1] || 12 : 12}
        md={name ? md?.[1] || 9 : 12}
        lg={name ? lg?.[1] || 10 : 12}
      >
        <Box>
          <Autocomplete
            value={value}
            options={options || []}
            freeSolo={freeSolo}
            multiple
            size={size}
            inputValue={inputValue}
            onInputChange={(e, value) => {
              handleInputValueChange(value)
            }}
            limitTags={limitTags}
            slots={slots}
            autoHighlight={true}
            isOptionEqualToValue={(option, value) => option === value}
            onChange={(e, values) => {
              onChange?.(values)
            }}
            onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) =>
              onBlur?.(e)
            }
            placeholder={
              placeholder ||
              (!value.length && typeof label === 'string' ? label : '')
            }
            defaultValue={value}
            endDecorator={endDecorator}
          />
          {errorText || helperText ? (
            <FormHelperText sx={{ ml: 1, mt: 0.33 }}>
              {errorText || helperText}
            </FormHelperText>
          ) : null}
        </Box>
      </Grid>
    </Grid>
  )
}
