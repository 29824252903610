import { Optional } from '.'
import { DataFields } from '../api/types'

export type EtsyListing = {
  listing_id: number
  user_id: number
  shop_id: number

  quantity: number
  title: string
  description: string
  price: {
    amount: number
    divisor: number
    currency_code: string
  }
  who_made: EtsyWhoMade
  when_made: EtsyWhenMade
  taxonomy_id: number

  state?: EtsyState
  creation_timestamp?: number
  created_timestamp?: number
  ending_timestamp?: number
  original_creation_timestamp?: number
  last_modified_timestamp?: number
  updated_timestamp?: number
  state_timestamp?: number
  shop_section_id?: number
  featured_rank?: number
  url?: string
  num_favorers?: number
  non_taxable?: boolean
  is_taxable?: boolean
  is_customizable?: boolean
  is_personalizable?: boolean
  personalization_is_required?: boolean
  personalization_char_count_max?: number
  personalization_instructions?: string
  listing_type?: EtsyType
  tags?: string[]
  styles?: string[]
  materials?: string[]
  shipping_profile_id?: number
  return_policy_id?: number
  processing_min?: number
  processing_max?: number
  is_supply?: boolean
  item_weight?: number
  item_weight_unit?: ItemWeightUnit
  item_length?: number
  item_width?: number
  item_height?: number
  item_dimensions_unit?: ItemDimensionUnit
  is_private?: boolean
  style?: string[]
  file_data?: string
  has_variations?: boolean
  should_auto_renew?: boolean
  language?: string
}

export type CreateDraftEtsyListing = Optional<
  EtsyListing,
  'listing_id' | 'user_id' | 'shop_id'
>

export enum EtsyState {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  SOLD_OUT = 'sold_out',
  DRAFT = 'draft',
  EXPIRED = 'expired',
}

export enum EtsyType {
  PHYSICAL = 'physical',
  DOWNLOAD = 'download',
  BOTH = 'both',
}

export enum EtsyWhoMade {
  I_DID = 'i_did',
  SOMEONE_ELSE = 'someone_else',
  COLLECTIVE = 'collective',
}

export enum EtsyWhenMade {
  MADE_TO_ORDER = 'made_to_order',
  Y2020_2024 = '2020_2024',
  Y2010_2019 = '2010_2019',
  Y2005_2009 = '2005_2009',
  BEFORE_2005 = 'before_2005',
  Y2000_2004 = '2000_2004',
  Y1990s = '1990s',
  Y1980s = '1980s',
  Y1970s = '1970s',
  Y1960s = '1960s',
  Y1950s = '1950s',
  Y1940s = '1940s',
  Y1930s = '1930s',
  Y1920s = '1920s',
  Y1910s = '1910s',
  Y1900s = '1900s',
  Y1800s = '1800s',
  Y1700s = '1700s',
  BEFORE_1700 = 'before_1700',
}

enum ItemWeightUnit {
  OUNCE = 'oz',
  POUND = 'lb',
  GRAM = 'g',
  KILOGRAM = 'kg',
}

enum ItemDimensionUnit {
  INCH = 'in',
  FOOT = 'ft',
  MILLIMETER = 'mm',
  CENTIMETER = 'cm',
  METER = 'm',
  YARD = 'yd',
  INCHES = 'inches',
}

export type EtsyResults<T> = {
  count: number
  results: T[]
}

export type EtsyTaxonomyNode = {
  id: number
  level: number
  name: string
  parent_id?: number | null
  children: EtsyTaxonomyNode[]
  full_path_taxonomy_ids: number[]
}

export type EtsyShopShippingProfile = {
  shipping_profile_id: number
  title: string
  user_id: number
  min_processing_days: number
  max_processing_days: number
  processing_days_display_label: string
  origin_country_iso: string
  is_deleted: boolean
  shipping_profile_destinations: [
    {
      shipping_profile_destination_id: number
      shipping_profile_id: number
      origin_country_iso: string
      destination_country_iso: string
      destination_region: string
      primary_cost: {
        amount: number
        divisor: number
        currency_code: string
      }
      secondary_cost: {
        amount: number
        divisor: number
        currency_code: string
      }
      shipping_carrier_id: number
      mail_class: string
      min_delivery_days: number
      max_delivery_days: number
    },
  ]
  shipping_profile_upgrades: [
    {
      shipping_profile_id: number
      upgrade_id: number
      upgrade_name: string
      type: string
      rank: number
      language: string
      price: {
        amount: number
        divisor: number
        currency_code: string
      }
      secondary_price: {
        amount: number
        divisor: number
        currency_code: string
      }
      shipping_carrier_id: number
      mail_class: string
      min_delivery_days: number
      max_delivery_days: number
    },
  ]
  origin_postal_code: string
  profile_type: string
  domestic_handling_fee: number
  international_handling_fee: number
}

export type EtsyShopReturnPolicy = {
  return_policy_id: number
  shop_id: number
  accepts_returns: boolean
  accepts_exchanges: boolean
  return_deadline: number
}

export type EtsyTaxonomyProperty = {
  property_id: number
  name: string
  display_name: string
  scales: TaxonomyPropertyScale[]
  is_required: boolean
  supports_attributes: boolean
  supports_variations: boolean
  is_multivalued: boolean
  max_values_allowed: number
  possible_values: TaxonomyPropertyValue[]
  selected_values: TaxonomyPropertyValue[]
}

export type TaxonomyPropertyScale = {
  scale_id: number
  display_name: string
  description: string
}

export type TaxonomyPropertyValue = {
  value_id: number
  name: string
  scale_id: number
  equal_to: number[]
}

export type EtsyTemplateIntegration = {
  id: number
  clientId: number
  integrationId: number
  templateId: number

  taxonomyId: string
  taxonomyName: string
  title: string
  description?: string

  whoMade?: string
  whenMade?: string
  shippingProfileId?: string
  returnPolicyId?: string
  materials: string
  shopSectionId?: number
  processingMin?: number
  processingMax?: number
  tags?: string
  styles?: string

  list?: boolean
  sync?: boolean
  syncQuantity?: boolean
  name: string
  index: number
  properties?: DataFields<EtsyPropertyTemplateIntegration>[]
  active: boolean
  createdAt: string
  updatedAt: string
}

export type EtsyProductIntegration = {
  id: number
  clientId: number
  templateIntegrationId: number
  productId: number
  name?: string
  index?: number
  selected: boolean
  taxonomyId?: string
  taxonomyName?: string
  title?: string
  description?: string

  whoMade?: string
  whenMade?: string
  shippingProfileId?: string
  returnPolicyId?: string
  materials: string
  shopSectionId?: number
  processingMin?: number
  processingMax?: number
  tags?: string
  styles?: string

  list?: boolean
  sync?: boolean
  syncQuantity?: boolean
  properties?: DataFields<EtsyPropertyProductIntegration>[]
  active: boolean
  createdAt: string
  updatedAt: string
}

export type EtsyPropertyTemplateIntegration = {
  id: number
  templateIntegrationId: number
  propertyId: string
  propertyName: string
  valueIds?: string
  values?: string
  scaleId?: string
  dataType?: string
  required?: boolean
}

export type EtsyPropertyProductIntegration = {
  id: number
  productIntegrationId: number
  propertyId: string
  propertyName: string
  valueIds?: string
  values?: string
  scaleId?: string
  dataType?: string
  required?: boolean
  active: boolean
  createdAt: string
  updatedAt: string
}
