import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/joy/Typography'
import Button from '@mui/joy/Button'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import { ProductAttribute } from '../../api/types'
import TipTapEditor from '../../components/tiptap/TipTapEditor'
import Textarea from '@mui/joy/Textarea'
import FormControl from '@mui/joy/FormControl'
import FormHelperText from '@mui/joy/FormHelperText'
import Box from '@mui/joy/Box'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import Dropdown from '@mui/joy/Dropdown'
import IconButton from '@mui/joy/IconButton'
import ListItemContent from '@mui/joy/ListItemContent'
import MenuButton from '@mui/joy/MenuButton'
import Menu from '@mui/joy/Menu'
import MenuItem from '@mui/joy/MenuItem'
import { SetProductTemplateAttribute } from '../../api/product'
import { getAttributeTag, unique } from '../../utils/functions'

interface ProductDescriptionEditorProps {
  value: string | undefined
  title?: string
  oneLine?: boolean
  required?: boolean
  onChange: (value: string) => void
  onUpdate: (value: string) => void
  onBlur?: () => void
  allowHtml?: boolean
  placeholder?: string
  disabled?: boolean
  attributes?: ProductAttribute[]
  attributeNames?: string[]
  templateAttributes?: SetProductTemplateAttribute[]
  richText?: boolean
  helperText?: string
}

export function ProductDescriptionEditor({
  value,
  title,
  oneLine,
  onChange,
  onUpdate,
  onBlur,
  allowHtml,
  required,
  placeholder,
  disabled,
  attributes,
  attributeNames: attributeNamesInput,
  templateAttributes,
  richText,
  helperText,
}: ProductDescriptionEditorProps): JSX.Element {
  const attributeNames = attributeNamesInput
    ? unique(attributeNamesInput)
    : undefined

  const convertEditorToHtml = (editorState: EditorState): string => {
    const htmlValue = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    return htmlValue.trim()
  }
  const convertHtmlToEditor = (
    html: string | undefined,
  ): EditorState | undefined => {
    if (!html) return undefined
    const blocksFromHtml = htmlToDraft(html)
    if (!blocksFromHtml) return
    const { contentBlocks, entityMap } = blocksFromHtml
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap,
    )
    return EditorState.createWithContent(contentState)
  }

  const addAttributeToValue = (attributeName: string) => {
    if (oneLine) {
      onUpdate(value + getAttributeTag(attributeName))
    } else {
      onUpdate(
        value + '\n' + attributeName + ': ' + getAttributeTag(attributeName),
      )
    }
    setEditorUpdates(editorUpdates + 1)
  }

  const handleAddAllAttributes = () => {
    // Add Attributes Named List to Description
    let listString = ''
    attributeNames?.forEach((a) => {
      if (oneLine) {
        listString += getAttributeTag(a)
      } else {
        listString += '\n' + a + ': ' + getAttributeTag(a)
      }
    })
    onUpdate(value + '\n' + listString)
    setEditorUpdates(editorUpdates + 1)
  }

  const [editorUpdates, setEditorUpdates] = useState<number>(0)
  const [editHtml, setEditHtml] = useState<boolean>(false)
  const [errorText, setErrorText] = useState('')

  const renderTitle = !!title
  return (
    <Grid container spacing={1} justifyContent="center">
      {renderTitle && (
        <Grid item xs={12} md={3} lg={2}>
          <Stack
            direction="row"
            justifyContent="center"
            sx={{
              position: 'relative',
              height: '100%',
              top: 10,
            }}
          >
            <Typography
              sx={{ mr: '0.5em', mb: '0.5em' }}
              textColor={disabled ? 'rgba(0, 0, 0, 0.38);' : undefined}
            >
              <strong>{title}</strong>
            </Typography>
          </Stack>
        </Grid>
      )}
      <Grid item xs={12} md={renderTitle ? 9 : 12} lg={renderTitle ? 10 : 12}>
        {allowHtml === true ? (
          <Grid container justifyContent="flex-end">
            {editHtml ? (
              <Button onClick={() => setEditHtml(!editHtml)}>View Raw</Button>
            ) : (
              <Button onClick={() => setEditHtml(!editHtml)}>
                HTML Editor
              </Button>
            )}
          </Grid>
        ) : null}

        {editHtml && !richText ? (
          <Editor
            editorState={convertHtmlToEditor(value)}
            onEditorStateChange={(editorState) => {
              onChange(convertEditorToHtml(editorState))
            }}
            onBlur={onBlur}
            readOnly={disabled}
            editorStyle={{
              border: '1px solid #767676',
              borderRadius: '4px',
              minHeight: '16em',
              paddingLeft: '16.5px',
              paddingRight: '16.5px',
              overflow: 'scroll',
            }}
          />
        ) : null}

        {!editHtml && richText ? (
          <>
            <Box sx={{ position: 'relative' }}>
              <TipTapEditor
                key={editorUpdates}
                placeholder={placeholder}
                required={required}
                disabled={disabled}
                oneLine={oneLine}
                value={value}
                mentions={attributeNames}
                onChange={(v) => {
                  onChange(v)
                }}
                onBlur={onBlur}
              />
              {attributeNames?.length ? (
                <Dropdown>
                  <MenuButton
                    sx={{
                      position: 'absolute',
                      right: '4px',
                      bottom: '4px',
                      padding: '0px',
                      opacity: 0.7,
                      height: '28px',
                      width: '28px',
                      maxWidth: '28px',
                      minWidth: '28px',
                      minHeight: '28px',
                      maxHeight: '28px',
                    }}
                    variant="plain"
                    size="sm"
                    slots={{ root: IconButton }}
                    slotProps={{
                      root: {
                        variant: 'plain',
                      },
                    }}
                  >
                    <AlternateEmailIcon
                      fontSize="small"
                      color="primary"
                      sx={{ fontSize: '18px' }}
                    />
                  </MenuButton>
                  <Menu
                    sx={{
                      maxHeight: '400px',
                      overflow: 'scroll',
                    }}
                  >
                    {!oneLine ? (
                      <MenuItem onClick={() => handleAddAllAttributes()}>
                        <ListItemContent>
                          <Typography level="title-sm">
                            <strong>Add All Attributes</strong>
                          </Typography>
                        </ListItemContent>
                      </MenuItem>
                    ) : null}
                    {attributeNames?.map((a) => (
                      <MenuItem
                        key={a}
                        data-option={a}
                        onClick={(e) => addAttributeToValue(a)}
                      >
                        <ListItemContent>
                          <Typography level="body-sm">
                            <strong>@{a}</strong>
                          </Typography>
                        </ListItemContent>
                      </MenuItem>
                    ))}
                  </Menu>
                </Dropdown>
              ) : null}
            </Box>
            {required && !value ? (
              <Grid container>
                <Typography
                  sx={{ mt: 0.5, mx: 1.5 }}
                  level="body-xs"
                  color="danger"
                  justifySelf="start"
                >
                  {helperText || 'Value required.'}
                </Typography>
              </Grid>
            ) : null}
          </>
        ) : null}

        {!editHtml && !richText ? (
          <FormControl>
            <Textarea
              onBlur={() =>
                setErrorText(
                  !value && required ? 'Description is required.' : '',
                )
              }
              error={!!errorText}
              minRows={5}
              disabled={disabled}
              onChange={(e) => {
                onChange(e.target.value)
                setErrorText(
                  !e.target.value && required ? 'Description is required.' : '',
                )
              }}
              placeholder={placeholder}
              value={value}
            />
            {errorText ? (
              <FormHelperText>
                {
                  <Typography color={disabled ? 'neutral' : undefined}>
                    {errorText}
                  </Typography>
                }
              </FormHelperText>
            ) : null}
          </FormControl>
        ) : null}
      </Grid>
    </Grid>
  )
}
