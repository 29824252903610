import React, { useEffect, useState } from 'react'
import { GetProduct, GetProductTemplate } from '../../../../api/product'
import Grid from '@mui/material/Grid'
import {
  GetIntegrationResult,
  IntegrationName,
  listProduct,
  unlistProduct,
} from '../../../../api/integration'
import { isDefined, unique } from '../../../../utils/functions'
import {
  CSVTemplateIntegration,
  CSVProductIntegration,
  CSVProductHeaderIntegration,
  CSVSheetHeaderIntegration,
  CSVTemplateHeaderIntegration,
  GetCSVSpreadsheetIntegration,
} from '../../../../types/CSV.types'
import Alert, { AlertInput, handleAlert } from '../../../common/Alert'
import { DataFields } from '../../../../api/types'
import ListSyncComponent from '../common/ListSyncComponent'
import ListUnlistButtons from '../common/ListUnlistButtons'
import MissingIntegration from '../MissingIntegration'
import Typography from '@mui/joy/Typography'
import AccordionGroup from '@mui/joy/AccordionGroup'
import Accordion from '@mui/joy/Accordion'
import AccordionSummary from '@mui/joy/AccordionSummary'
import AccordionDetails from '@mui/joy/AccordionDetails'
import ListItemContent from '@mui/joy/ListItemContent'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Link } from 'react-router-dom'
import SingleSelect from '../../../common/SingleSelect'
import { PRODUCT_FIELD_NAMES } from '../../../../types'
import { extractAttributeName } from '../../../../classes/Parser'
import Tabs from '@mui/joy/Tabs'
import TabList from '@mui/joy/TabList'
import TabPanel from '@mui/joy/TabPanel'
import Tab from '@mui/joy/Tab'
import AttributeSelect from '../../../common/AttributeSelect'
import MissingTemplateIntegration from '../MissingTemplateIntegration'
import Box from '@mui/joy/Box'

interface CSVIntegrationComponentProps {
  integration: GetIntegrationResult<IntegrationName.CSV>
  spreadsheetIntegrations: GetCSVSpreadsheetIntegration[]
  templateIntegration: CSVTemplateIntegration
  productIntegration?: CSVProductIntegration
  template: GetProductTemplate
  product?: GetProduct
  onTemplateChange?: (
    name: string,
    templateIntegration: DataFields<CSVTemplateIntegration> | undefined,
  ) => void
  onProductChange?: (
    name: string,
    productIntegration: DataFields<CSVProductIntegration> | undefined,
  ) => void
  onUpdate?: () => void
}

export default function CSVIntegrationComponent({
  integration,
  spreadsheetIntegrations,
  templateIntegration,
  productIntegration,
  template,
  product,
  onTemplateChange,
  onProductChange,
}: CSVIntegrationComponentProps): JSX.Element {
  const [templateHeaderIntegrations, setTemplateHeaderIntegrations] = useState<
    Partial<CSVTemplateHeaderIntegration>[]
  >(templateIntegration?.headers || [])

  const [productHeaderIntegrations, setProductHeaderIntegrations] = useState<
    Partial<CSVProductHeaderIntegration>[]
  >(productIntegration?.headers || [])

  const [list, setList] = useState<boolean>(
    productIntegration?.list ?? templateIntegration?.list ?? false,
  )
  const [sync, setSync] = useState<boolean>(
    productIntegration?.sync ?? templateIntegration?.sync ?? false,
  )
  const [syncQuantity, setSyncQuantity] = useState<boolean>(
    productIntegration?.syncQuantity ??
      templateIntegration?.syncQuantity ??
      false,
  )

  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const handleListProduct = () => {
    const productId = product?.product.id
    if (!productId) {
      return
    }
    listProduct(IntegrationName.CSV, productId)
      .then((res) => {
        handleAlert(setAlert, res, 'Successfully listed product.')
      })
      .catch((e) => {
        setAlert({
          open: true,
          severity: 'error',
          message: `${e.response.data.message}`,
        })
      })
  }

  const handleUnlistProduct = () => {
    const productId = product?.product.id
    if (!integration.integration?.id || !productId) return
    unlistProduct(IntegrationName.CSV, productId, integration.integration.id)
      .then((res) => {
        handleAlert(setAlert, res, 'Successfully Unlisted.')
      })
      .catch((e) =>
        setAlert({
          open: true,
          severity: 'error',
          message: e.response.data.message,
        }),
      )
  }

  // useEffect(() => {
  //   if (!integration) {
  //     return
  //   }

  //   const integrationId = integration.id
  //   const templateId = template?.template.id || product?.template.id
  //   // CSV Template Integration
  //   if (integrationId && templateId) {
  //     getCSVIntegrations(integrationId, templateId, product?.product.id)
  //       .then((res) => {
  //         if (res.success && res.data) {
  //           const data = res.data
  //           setSpreadsheetIntegrations(data.spreadsheetIntegrations || [])
  //         }
  //       })
  //       .catch((e) => err(e))
  //   }
  // }, [integration, product, template])

  useEffect(() => {
    if (!integration.integration?.id) return

    if (product) {
      const newCSVProductIntegration: DataFields<CSVProductIntegration> = {
        templateIntegrationId: templateIntegration?.id,
        productId: product.product.id,
        list,
        sync,
        syncQuantity,
        selected: true,
        headers: productHeaderIntegrations,
      }
      onProductChange?.(templateIntegration.name, newCSVProductIntegration)
      return
    }

    const newCSVTemplateIntegration: DataFields<CSVTemplateIntegration> = {
      integrationId: integration.integration.id,
      templateId: template?.template.id,
      list,
      sync,
      syncQuantity,
      name: templateIntegration.name,
      index: templateIntegration.index,
      headers: templateHeaderIntegrations,
    }
    onTemplateChange?.(templateIntegration.name, newCSVTemplateIntegration)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    list,
    sync,
    syncQuantity,
    templateHeaderIntegrations,
    productHeaderIntegrations,
  ])

  const handleTemplateHeaderChange = (
    header: CSVSheetHeaderIntegration,
    value: string | undefined,
  ) => {
    // update integration value
    // const headerIntegration

    const newTemplateHeaderIntegrations = templateHeaderIntegrations.slice()
    const index = newTemplateHeaderIntegrations.findIndex(
      (templateHeader) => templateHeader.sheetHeaderIntegrationId === header.id,
    )

    const updatedTemplateHeader = {
      sheetHeaderIntegrationId: header.id,
      value: value || '',
    }
    if (index === -1) {
      newTemplateHeaderIntegrations.push(updatedTemplateHeader)
    } else {
      newTemplateHeaderIntegrations[index] = updatedTemplateHeader
    }
    setTemplateHeaderIntegrations(newTemplateHeaderIntegrations)
  }

  const handleProductHeaderChange = (
    header: CSVSheetHeaderIntegration,
    value: string | undefined,
  ) => {
    // update integration value
    // const headerIntegration

    const newProductHeaderIntegrations = productHeaderIntegrations.slice()
    const index = newProductHeaderIntegrations.findIndex(
      (productHeader) => productHeader.sheetHeaderIntegrationId === header.id,
    )

    const updatedProductHeader = {
      sheetHeaderIntegrationId: header.id,
      value: value || '',
    }
    if (index === -1) {
      newProductHeaderIntegrations.push(updatedProductHeader)
    } else {
      newProductHeaderIntegrations[index] = updatedProductHeader
    }
    setProductHeaderIntegrations(newProductHeaderIntegrations)
  }

  const attributeNames = unique(
    Object.keys(PRODUCT_FIELD_NAMES).concat(
      template?.attributes?.map((a) => a.attribute.name) ||
        product?.attributes?.map((a) => a.templateAttribute.name) ||
        [],
    ),
  )

  if (!integration) {
    return <MissingIntegration />
  }

  if (product && !templateIntegration) {
    return (
      <MissingTemplateIntegration
        templateId={product.product.templateId}
        channelName={IntegrationName.CSV}
      />
    )
  }

  return (
    <Grid container justifyContent="center" spacing={6} pt={2}>
      <Grid item xs={12}>
        <ListSyncComponent
          title={true}
          list={list}
          sync={sync}
          syncQuantity={syncQuantity}
          onListChange={setList}
          onSyncChange={setSync}
          onSyncQuantityChange={setSyncQuantity}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <Typography level="h4"> Spreadsheets</Typography>
          </Grid>

          {spreadsheetIntegrations.map((csvSpreadsheetIntegration) => {
            const spreadsheetId = csvSpreadsheetIntegration.spreadsheetId
            const sheetIntegrations =
              csvSpreadsheetIntegration.csvSheetIntegrations

            return (
              <Grid item xs={12} key={csvSpreadsheetIntegration.id}>
                <AccordionGroup
                  size="lg"
                  variant="outlined"
                  sx={{ backgroundColor: '#fff' }}
                >
                  <Accordion defaultExpanded={true}>
                    <AccordionSummary variant="plain">
                      <ListItemContent sx={{ p: 1 }}>
                        <Typography level="title-lg" component="div">
                          {csvSpreadsheetIntegration.name}
                          {spreadsheetId ? (
                            <OpenInNewIcon
                              fontSize="small"
                              sx={{ opacity: 0.5, ml: 0.5, pt: 0.3 }}
                              onClick={(e) => {
                                e.stopPropagation()
                                window
                                  ?.open(
                                    `https://docs.google.com/spreadsheets/d/${spreadsheetId}/edit`,
                                    '_blank',
                                  )
                                  ?.focus()
                              }}
                            />
                          ) : (
                            ''
                          )}
                        </Typography>
                        <Typography level="body-xs">{spreadsheetId}</Typography>
                      </ListItemContent>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{ padding: 0 }}
                      slotProps={{
                        content: {
                          sx: {
                            p: 0,
                          },
                        },
                      }}
                    >
                      <Grid
                        container
                        justifyContent="center"
                        sx={{ py: 2, px: 0 }}
                      >
                        <Grid item xs={12}>
                          <Tabs
                            defaultValue={
                              csvSpreadsheetIntegration
                                ?.csvSheetIntegrations?.[0]?.sheetTitle
                            }
                            size="lg"
                            variant="plain"
                            sx={{ backgroundColor: '#fff' }}
                          >
                            <TabList
                              sx={{
                                overflow: 'auto',
                                scrollSnapType: 'x mandatory',
                                '&::-webkit-scrollbar': {
                                  display: 'none',
                                },
                              }}
                            >
                              {sheetIntegrations?.map((csvSheetIntegration) => {
                                const sheetTitle =
                                  csvSheetIntegration.sheetTitle

                                return (
                                  <Tab
                                    variant="plain"
                                    disableIndicator
                                    key={sheetTitle}
                                    value={sheetTitle}
                                  >
                                    <Typography level="title-md">
                                      {sheetTitle}
                                    </Typography>
                                  </Tab>
                                )
                              })}
                            </TabList>
                            {sheetIntegrations?.map((sheetIntegration) => {
                              const sheetTitle = sheetIntegration.sheetTitle
                              const headers = sheetIntegration.headers

                              return (
                                <TabPanel key={sheetTitle} value={sheetTitle}>
                                  <Grid
                                    container
                                    justifyContent="center"
                                    spacing={2}
                                  >
                                    <Grid item xs={12}>
                                      <Grid
                                        container
                                        justifyContent="center"
                                        spacing={2}
                                      >
                                        <Grid item xs={12}>
                                          <Typography level="title-lg">
                                            Headers
                                          </Typography>
                                        </Grid>
                                        {headers?.length ? (
                                          headers?.map((header) => {
                                            const templateHeaderIntegration =
                                              templateHeaderIntegrations.find(
                                                (t) =>
                                                  t.sheetHeaderIntegrationId ===
                                                  header.id,
                                              )

                                            const productHeaderIntegration =
                                              productHeaderIntegrations.find(
                                                (t) =>
                                                  t.sheetHeaderIntegrationId ===
                                                  header.id,
                                              )

                                            const value =
                                              productHeaderIntegration?.value ||
                                              templateHeaderIntegration?.value

                                            const attributeName =
                                              extractAttributeName(value)

                                            let options: string[] = []
                                            let warningText = ''
                                            let errorText = ''
                                            if (header.options?.length) {
                                              const attribute =
                                                template?.attributes.find(
                                                  (a) =>
                                                    a.attribute.name ===
                                                    attributeName,
                                                )
                                              const attrOpts =
                                                attribute?.attributeOptions.map(
                                                  (o) => o.value,
                                                )

                                              const productValue =
                                                product?.attributes.find(
                                                  (a) =>
                                                    a.templateAttribute.name ===
                                                    attributeName,
                                                )?.attribute?.value || ''

                                              options =
                                                header?.options
                                                  ?.map((o) => o.option)
                                                  .filter(isDefined) || []

                                              const optionsNotInOptions =
                                                !!options?.length &&
                                                !!attrOpts?.length &&
                                                !options.some((o) =>
                                                  attrOpts.includes(o),
                                                )

                                              if (optionsNotInOptions) {
                                                warningText =
                                                  'No matching options'
                                              }

                                              const valueInOptions =
                                                options.some(
                                                  (o) => productValue === o,
                                                )

                                              const valueNotValid =
                                                header.strict &&
                                                !!options?.length &&
                                                !valueInOptions &&
                                                !!productValue

                                              const valueNeeded =
                                                header.strict &&
                                                attributeName &&
                                                !productValue

                                              const valueNotInOptions =
                                                !header.strict &&
                                                !!options?.length &&
                                                !valueInOptions &&
                                                !!productValue

                                              if (valueNeeded) {
                                                errorText = `${attributeName} missing value`
                                              }
                                              if (valueNotValid) {
                                                errorText = `'${productValue}' not allowed in options`
                                              }
                                              if (valueNotInOptions) {
                                                warningText = `'${productValue}' not in options`
                                              }

                                              if (value) {
                                                console.log(attrOpts)
                                                console.log(value)
                                                console.log(productValue)
                                                console.log(
                                                  extractAttributeName(value),
                                                )
                                              }

                                              return (
                                                <Grid
                                                  item
                                                  xs={12}
                                                  key={header.id}
                                                >
                                                  <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                      <AttributeSelect
                                                        value={value || ''}
                                                        label="Value"
                                                        name={header.name}
                                                        nameLabel={
                                                          'column ' +
                                                          header.cellId
                                                        }
                                                        nameEditable={true}
                                                        nameEditDisabled={true}
                                                        onChange={(v) =>
                                                          product
                                                            ? handleProductHeaderChange(
                                                                header,
                                                                v,
                                                              )
                                                            : handleTemplateHeaderChange(
                                                                header,
                                                                v,
                                                              )
                                                        }
                                                        attributeNames={
                                                          attributeNames
                                                        }
                                                        fullWidth={true}
                                                        endElement={
                                                          <Box width={100}>
                                                            <SingleSelect
                                                              placeholder={
                                                                header.strict
                                                                  ? 'Possible Options'
                                                                  : 'Example Options'
                                                              }
                                                              value={''}
                                                              onChange={() => {
                                                                // no-op
                                                              }}
                                                              options={options}
                                                              warningText={
                                                                warningText
                                                              }
                                                              errorText={
                                                                errorText
                                                              }
                                                            />
                                                          </Box>
                                                        }
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                              )
                                            }

                                            return (
                                              <Grid
                                                item
                                                xs={12}
                                                key={header.id}
                                              >
                                                <AttributeSelect
                                                  value={value || ''}
                                                  label="Value"
                                                  name={header.name}
                                                  nameLabel={
                                                    'column ' + header.cellId
                                                  }
                                                  nameEditable={true}
                                                  nameEditDisabled={true}
                                                  onChange={(v) =>
                                                    product
                                                      ? handleProductHeaderChange(
                                                          header,
                                                          v,
                                                        )
                                                      : handleTemplateHeaderChange(
                                                          header,
                                                          v,
                                                        )
                                                  }
                                                  attributeNames={
                                                    attributeNames
                                                  }
                                                />
                                              </Grid>
                                            )
                                          })
                                        ) : (
                                          <>
                                            <Typography level="body-sm">
                                              No headers. Update this{' '}
                                              <Link to="/integrations">
                                                Integration
                                              </Link>
                                            </Typography>
                                          </>
                                        )}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </TabPanel>
                              )
                            })}
                          </Tabs>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </AccordionGroup>
              </Grid>
            )
          })}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <ListUnlistButtons
          title={true}
          list={list}
          hasProductIntegration={!!productIntegration}
          product={product}
          onList={handleListProduct}
          onUnlist={handleUnlistProduct}
        />
      </Grid>

      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Grid>
  )
}
