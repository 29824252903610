import React, { useEffect, useMemo, useState } from 'react'
import {
  ProductTemplateAttributeInput,
  SetProductAttribute,
  updateProductTemplateAttributes,
} from '../../../api/product'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import Autocomplete from '@mui/joy/Autocomplete'
import { RequiredIntegrationTemplateAttributes } from '../../../api/integrations/ebay'
import { darken, lighten, styled } from '@mui/material/styles'
import Button from '@mui/joy/Button'
import { DEFAULT_ATTRIBUTE_TYPE } from '../../../utils/constants'
import { ProductTemplateAttribute } from '../../../api/types'
import { copy } from '../../../utils/functions'
import FormControl from '@mui/joy/FormControl'
import AutocompleteOption from '@mui/joy/AutocompleteOption'
import ListItemContent from '@mui/joy/ListItemContent'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import Tooltip from '@mui/joy/Tooltip'

interface OptionValue {
  value: string
  source: OptionGroupsEnum
}

enum OptionGroupsEnum {
  OPTION = 'Template Options',
  EBAY = 'Ebay Examples',
  ETSY = 'Etsy Values',
}
interface ProductAttributeInputFieldProps {
  id?: string
  name: string
  value?: string
  displayPlaceholder?: boolean
  attributes: SetProductAttribute[]
  options?: string[]
  onChange: (name: string, value: string) => void
  onSelection?: (next?: boolean) => void
  onUpdate?: () => void
  disabled?: boolean
  requiredAttributes: RequiredIntegrationTemplateAttributes[]
}

export default function ProductAttributeInputField({
  id,
  name,
  value: defaultValue,
  displayPlaceholder,
  attributes,
  options,
  onChange,
  onSelection,
  onUpdate,
  disabled = false,
  requiredAttributes,
}: ProductAttributeInputFieldProps): JSX.Element | null {
  const getAttribute = (
    attributes: SetProductAttribute[],
    name: string,
  ): SetProductAttribute | undefined => {
    const attribute = attributes.find(
      (attribute) => attribute.templateAttribute.name === name,
    )
    if (!attribute) return undefined
    return {
      attribute: attribute.attribute,
      templateAttribute: attribute.templateAttribute,
      templateAttributeOptions: attribute?.templateAttributeOptions,
    }
  }

  const getRequiredAttribute = (
    requiredAttributes: RequiredIntegrationTemplateAttributes[],
    name: string,
  ): RequiredIntegrationTemplateAttributes | undefined => {
    return requiredAttributes.find((reqAttribute) =>
      reqAttribute.templateAttributeNames.includes(name),
    )
  }

  const [requiredAttribute, setRequiredAttribute] = useState<
    RequiredIntegrationTemplateAttributes | undefined
  >()
  const [attribute, setAttribute] = useState<SetProductAttribute | undefined>()
  const [value, setValue] = useState<string>(defaultValue || '')
  const [displayValue, setDisplayValue] = useState<string>(defaultValue || '')
  const [requiredText, setRequiredText] = useState<string | undefined>(
    undefined,
  )
  const [warningText, setWarningText] = useState<string | undefined>(undefined)

  const [optionValues, setOptionValues] = useState<OptionValue[]>([])

  useMemo(() => {
    const attributeOptionValues = attribute?.templateAttributeOptions
      ? attribute?.templateAttributeOptions
          .map((o) => ({ value: o.value, source: OptionGroupsEnum.OPTION }))
          .sort((a, b) => a.value.localeCompare(b.value))
      : []

    const lowercaseValue = value.toLowerCase()
    const ebayOptions = options
      ?.filter((o) => o.toLowerCase().startsWith(lowercaseValue))
      ?.slice(0, 50)
      ?.filter(
        (o) =>
          !attributeOptionValues.find(
            (attributeOption) => attributeOption.value === o,
          ),
      )
      .map((ebayOption) => ({
        value: ebayOption,
        source: OptionGroupsEnum.EBAY,
      }))

    setOptionValues(attributeOptionValues.concat(ebayOptions || []))
  }, [attribute?.templateAttributeOptions, options, value])

  useEffect(() => {
    const newAttr = getAttribute(attributes, name)
    setAttribute(newAttr)
    if (!defaultValue && !displayPlaceholder) {
      setValue(newAttr?.attribute?.value || '')
    }
    if (displayPlaceholder) {
      setDisplayValue(newAttr?.attribute?.value || '')
    }
  }, [name, defaultValue, attributes, displayPlaceholder])
  useEffect(() => {
    setRequiredAttribute(getRequiredAttribute(requiredAttributes, name))
  }, [requiredAttributes, name])

  useEffect(() => {
    if (
      !value &&
      requiredAttribute?.aspect &&
      requiredAttribute.aspect?.required
    ) {
      const requiredTextValue = `Attribute is required. Used in Ebay Aspect "${
        requiredAttribute.aspect.aspectName || 'Unknown Aspect'
      }" as ${requiredAttribute.aspect.value}`
      setRequiredText(requiredTextValue)
    } else if (value) {
      setRequiredText('')
    }
  }, [requiredAttribute, value])

  useEffect(() => {
    if (
      !value &&
      requiredAttribute?.aspect &&
      !requiredAttribute.aspect.required
    ) {
      const warningTextValue = `Attribute is recommended. Used in Ebay Aspect "${
        requiredAttribute.aspect.aspectName || 'Unknown Aspect'
      }" as ${requiredAttribute.aspect.value}`
      setWarningText(warningTextValue)
    } else if (!value && requiredAttribute) {
      const warningTextValue =
        'Attribute is reccommended. Used in Title or Description.'
      setWarningText(warningTextValue)
    } else if (value) {
      setWarningText('')
    }
  }, [requiredAttribute, value])

  const handleChange = (value: string) => {
    setValue(value)
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (!e.shiftKey && e.key === 'Enter') {
      onSelection?.(true)
    } else if (e.shiftKey && e.key === 'Enter') {
      onSelection?.(false)
    }
  }

  const handleAddTemplateAttributeOption = async (option: string) => {
    if (!attribute) return
    // Get template id from template attribute
    const templateId = attribute.templateAttribute.templateId
    const templateAttributeName = attribute.templateAttribute.name

    const templateAttribute = attribute.templateAttribute
    const newOptionIndex = attribute.templateAttributeOptions.length // length+1
    if (!templateId || !templateAttributeName) return

    // add attribute with option
    const addTemplateAttr: ProductTemplateAttributeInput = {
      attribute: templateAttribute,
      attributeOptions: attribute.templateAttributeOptions.concat({
        value: option,
        type: DEFAULT_ATTRIBUTE_TYPE,
        index: newOptionIndex,
      }),
    }

    const newAttribute = copy(attribute)
    newAttribute.templateAttribute =
      addTemplateAttr.attribute as ProductTemplateAttribute
    newAttribute.templateAttributeOptions = addTemplateAttr.attributeOptions
    setAttribute(newAttribute)
    const res = await updateProductTemplateAttributes(templateId, [
      addTemplateAttr,
    ])
    if (res.success) {
      console.log('success')
    } else {
      console.log('Error')
    }
    onUpdate?.()
  }

  let priorityText = ''
  if (attribute?.templateAttribute.constraint === 'required') {
    priorityText = ' (required)'
  } else if (attribute?.templateAttribute.constraint === 'recommended') {
    priorityText = ' (recommended)'
  }

  let helperTextColor: 'danger' | 'warning' | undefined = undefined
  if (requiredText) {
    helperTextColor = 'danger'
  } else if (warningText) {
    helperTextColor = 'warning'
  }

  return (
    <Grid container spacing={1} justifyContent="center">
      <Grid item xs={12} sm={12} md={3} lg={2} sx={{ position: 'relative' }}>
        <Typography sx={{ mt: 1 }}>
          <strong>{name}</strong>
        </Typography>
        {/* <Box sx={{ position: 'absolute', top: '1em', left: '1em', zIndex: 2 }}>
   
        </Box> */}
      </Grid>
      <Grid item xs={12} sm={12} md={9} lg={10}>
        <FormControl>
          <Autocomplete
            openOnFocus={true}
            selectOnFocus={true}
            autoSelect={false}
            clearOnEscape={true}
            id={id}
            value={value}
            options={optionValues}
            groupBy={(option) => option.source}
            freeSolo
            limitTags={5}
            disabled={disabled}
            endDecorator={
              requiredText || warningText ? (
                <Tooltip
                  title={requiredText || warningText}
                  variant="solid"
                  placement="bottom-start"
                >
                  <HelpOutlineIcon
                    sx={{ fontSize: '19px' }}
                    fontSize="small"
                    color="disabled"
                  />
                  {/* <Typography>
                  {attribute?.templateAttribute.constraint === 'required' ? (
                    <InfoIcon
                      sx={{ fontSize: '16px', ml: 1, top: '4px' }}
                      fontSize="small"
                      color="disabled"
                    />
                  ) : (
                    ''
                  )}
                  {attribute?.templateAttribute.constraint === 'recommended' ? (
                    <InfoIcon
                      sx={{ fontSize: '16px', ml: 1, top: '4px' }}
                      fontSize="small"
                      color="disabled"
                    />
                  ) : (
                    ''
                  )}
                </Typography> */}
                </Tooltip>
              ) : null
            }
            onChange={(e, v: OptionValue | string | null) => {
              if (v && typeof v !== 'string') {
                const optionValue = v as OptionValue
                setValue(value)
                onChange(name, optionValue.value)
                onSelection?.()
                setRequiredText('')
                setWarningText('')
              }
            }}
            onInput={(e) => {
              handleChange((e.target as HTMLInputElement).value)
            }}
            onBlur={() => {
              setValue(value)
              // onChange(name, value)
            }}
            isOptionEqualToValue={(
              option: OptionValue,
              value: OptionValue | string,
            ) => {
              if (typeof value === 'string') {
                return value === option.value
              }
              if (value.value === option.value) {
                return true
              }
              return false
            }}
            renderGroup={(params) => (
              <li key={params.key}>
                <GroupHeader>{params.group}</GroupHeader>
                <GroupItems>{params.children}</GroupItems>
              </li>
            )}
            getOptionLabel={(option: OptionValue | string) => {
              if (typeof option !== 'string') {
                return (option as OptionValue).value
              } else {
                return option
              }
            }}
            renderOption={(props, option: OptionValue) => (
              <AutocompleteOption {...props}>
                {/* <ListItemDecorator>
                </ListItemDecorator> */}
                <ListItemContent sx={{ fontSize: 'sm' }}>
                  {option.value}
                  {option.source !== OptionGroupsEnum.OPTION ? (
                    <Button
                      sx={{
                        position: 'absolute',
                        right: '0.5em',
                        top: '0em',
                      }}
                      size="sm"
                      variant="plain"
                      onClick={(e) => {
                        e.stopPropagation()
                        void handleAddTemplateAttributeOption(option.value)
                      }}
                    >
                      Add
                    </Button>
                  ) : null}
                </ListItemContent>
              </AutocompleteOption>
            )}
            placeholder={displayValue || name + priorityText}
            inputValue={value}
            color={helperTextColor}
            onKeyDown={(e) => handleKeyPress(e)}
            onInputChange={(e) => {
              const value = (e.target as HTMLInputElement).value
              setValue(value)
              onChange(name, value)
            }}
            // renderInput={(a) => (
            //   <LabelInput
            //     {...a}
            //     fullWidth
            //     value={value}
            //     // color={warningText ? 'warning' : undefined}
            //     onKeyDown={(e) => handleKeyPress(e)}
            //     onChange={(v) => {
            //       setValue(v)
            //       onChange(name, v)
            //     }}
            //   />
            // )}
          ></Autocomplete>
          {/* <FormHelperText>
            <Typography level="body-xs" color={helperTextColor}>
              {requiredText || warningText}
            </Typography>
          </FormHelperText> */}
        </FormControl>
      </Grid>
    </Grid>
  )
}

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}))

const GroupItems = styled('ul')({
  padding: 0,
  backgroundColor: '#fff',
  li: '1px solid red',
})
