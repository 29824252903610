import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import {
  Aspect,
  AspectUsageEnum,
  EbayAspectTemplateIntegration,
  EbayTemplateIntegration,
} from '../../../../types/Ebay.types'
import {
  GetProductTemplateAttribute,
  ProductTemplateAttributeInput,
  updateProductTemplateAttributes,
} from '../../../../api/product'
import { useMemo } from 'react'
import AspectSection from './AspectSection'
import { AspectIntegrationInput } from './EbayIntegrationsComponent'
import Button from '@mui/joy/Button'
import { DEFAULT_ATTRIBUTE_TYPE } from '../../../../utils/constants'
import { getAttributeTag, isDefined, isLike } from '../../../../utils/functions'
import Modal from '../../../common/Modal'
import CodeInline from '../../../common/CodeInline'
import Alert, { AlertInput, handleAlert } from '../../../common/Alert'
import { setEbayTemplateIntegration } from '../../../../api/integrations/ebay'
import { AttributeConstraintEnum } from '../../../../api/types'
import AccordionGroup from '@mui/joy/AccordionGroup'
import Accordion from '@mui/joy/Accordion'
import AccordionDetails from '@mui/joy/AccordionDetails'
import AccordionSummary from '@mui/joy/AccordionSummary'
import Box from '@mui/joy/Box'

const SUBMIT_MODAL_CLOSE_COOLDOWN = 2500

export default function ItemAspects({
  templateIntegration,
  aspects,
  aspectIntegrations,
  templateAttributes,
  onChange,
  onUpdate,
}: {
  templateIntegration?: EbayTemplateIntegration
  aspects: Aspect[]
  aspectIntegrations: EbayAspectTemplateIntegration[]
  templateAttributes: GetProductTemplateAttribute[]
  onChange: (aspect: EbayAspectTemplateIntegration) => void
  onUpdate?: () => void
}): JSX.Element {
  const [open, setOpen] = useState<boolean>(false)
  const [addAspectAttributesType, setAddAspectAttributesType] =
    useState<string>('')
  const [addAspectAttributes, setAddAspectAttributes] = useState<
    ProductTemplateAttributeInput[]
  >([])

  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const requiredAspects = useMemo(
    () => aspects.filter((aspect) => aspect.aspectConstraint.aspectRequired),
    [aspects],
  )
  const reccommendedAspects = useMemo(
    () =>
      aspects.filter(
        (aspect) =>
          !aspect.aspectConstraint.aspectRequired &&
          aspect.aspectConstraint.aspectUsage === AspectUsageEnum.RECOMMENDED,
      ),
    [aspects],
  )
  const optionalAspects = useMemo(
    () =>
      aspects.filter(
        (aspect) =>
          !aspect.aspectConstraint.aspectRequired &&
          aspect.aspectConstraint.aspectUsage === AspectUsageEnum.OPTIONAL,
      ),
    [aspects],
  )

  const handleChange = (aspect: AspectIntegrationInput) => {
    onChange(aspect)
    // onUpdate?.()
  }

  const createTemplateAttributeInput = (
    aspect: Aspect,
    index?: number,
  ): ProductTemplateAttributeInput => {
    let constraint: AttributeConstraintEnum | undefined = undefined
    if (aspect.aspectConstraint.aspectRequired) {
      constraint = AttributeConstraintEnum.REQUIRED
    } else if (
      aspect.aspectConstraint.aspectUsage === AspectUsageEnum.RECOMMENDED
    ) {
      constraint = AttributeConstraintEnum.RECOMMENDED
    } else if (
      aspect.aspectConstraint.aspectUsage === AspectUsageEnum.OPTIONAL
    ) {
      constraint = AttributeConstraintEnum.OPTIONAL
    }
    return {
      attribute: {
        name: aspect.localizedAspectName,
        type: DEFAULT_ATTRIBUTE_TYPE,
        constraint,
        index,
      },
      attributeOptions: [],
    }
  }

  const getAspectsAsAttributes = (aspects: Aspect[]) => {
    const newIndex = templateAttributes.length // length
    const addTemplateAttributes: ProductTemplateAttributeInput[] = aspects.map(
      (aspect, i) => createTemplateAttributeInput(aspect, newIndex + i),
    )

    // filter away the attributes that already exist in templateAttributes
    const newAddTemplateAttributes = addTemplateAttributes.filter(
      (addTemplateAttribute) => {
        return !templateAttributes.find((templateAttribute) =>
          isLike(
            templateAttribute.attribute.name,
            addTemplateAttribute.attribute.name,
          ),
        )
      },
    )

    return newAddTemplateAttributes
  }

  const addAspectsAsAttributes = async (
    addTemplateAttributes: ProductTemplateAttributeInput[],
  ) => {
    const templateId = templateAttributes?.[0]?.attribute.templateId
    if (!templateId) {
      console.log('Invalid.')
      return
    }
    const res = await updateProductTemplateAttributes(
      templateId,
      addTemplateAttributes,
    )

    if (!res.success || !res.data) return
    if (!templateIntegration) return

    const allAttributes = templateAttributes.concat(res.data)
    // For each adding, add aspect integration
    const newAspectIntegrations = aspects
      .map((aspect) => {
        const aspectName = aspect.localizedAspectName

        // aspect integration already exists
        const existingAspect = aspectIntegrations.find(
          (aspectIntegration) => aspectIntegration.aspectName === aspectName,
        )
        const attributeExists = allAttributes.find(
          (attribute) => attribute.attribute.name === aspectName,
        )

        // No attribute for this aspect, no-op
        if (!attributeExists) return undefined

        return {
          aspectName: existingAspect?.aspectName || aspectName,
          value: existingAspect?.value || getAttributeTag(aspectName),
          dataType: existingAspect?.dataType || 'text',
          required:
            existingAspect?.required || aspect.aspectConstraint.aspectRequired,
        }
      })
      .filter(isDefined)
    await setEbayTemplateIntegration({
      ...templateIntegration,
      aspects: newAspectIntegrations,
    })

    let successMessage = 'Successfully added Template Attributes.'
    if (addTemplateAttributes.length === 1) {
      successMessage = `Successfully added '${
        addTemplateAttributes[0]?.attribute.name || 'Attribute'
      }' Template Attribute.`
    }

    handleAlert(setAlert, res, successMessage)
    onUpdate?.()
  }

  const aspectAttributesAllExist = (aspects: Aspect[]): boolean => {
    return (
      aspects.filter((aspect) =>
        templateAttributes.find((a) =>
          isLike(a.attribute.name, aspect.localizedAspectName),
        ),
      ).length === aspects.length
    )
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <AccordionGroup size="lg" variant="outlined">
          <Accordion defaultExpanded={true}>
            <AccordionSummary>
              <Box p={2}>
                <Typography level="title-lg">Item Specifics</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box p={1}>
                {aspects.length ? (
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12}>
                      <Typography level="body-sm">
                        Add Item Specifics as Template Attributes
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="soft"
                        onClick={() => {
                          setAddAspectAttributesType('All')
                          setAddAspectAttributes(
                            getAspectsAsAttributes(aspects),
                          )
                          setOpen(true)
                        }}
                        disabled={aspectAttributesAllExist(aspects)}
                      >
                        Add All
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="soft"
                        onClick={() => {
                          setAddAspectAttributesType('Required')
                          setAddAspectAttributes(
                            getAspectsAsAttributes(requiredAspects),
                          )
                          setOpen(true)
                        }}
                        disabled={aspectAttributesAllExist(requiredAspects)}
                      >
                        Add Required
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="soft"
                        onClick={async () => {
                          setAddAspectAttributesType('Reccommended')
                          setAddAspectAttributes(
                            getAspectsAsAttributes(reccommendedAspects),
                          )
                          setOpen(true)
                        }}
                        disabled={aspectAttributesAllExist(reccommendedAspects)}
                      >
                        Add Recommended
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="soft"
                        onClick={async () => {
                          setAddAspectAttributesType('Optional')
                          setAddAspectAttributes(
                            getAspectsAsAttributes(optionalAspects),
                          )
                          setOpen(true)
                        }}
                        disabled={aspectAttributesAllExist(optionalAspects)}
                      >
                        Add Optional
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12}>
                      <Typography level="body-sm">
                        Must set a category before viewing item specifics
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </AccordionDetails>
          </Accordion>

          {requiredAspects.length ? (
            <AspectSection
              label="Required"
              aspects={requiredAspects}
              aspectIntegrations={aspectIntegrations}
              onChange={handleChange}
              onUpdate={onUpdate}
              templateAttributes={templateAttributes}
              open={true}
            />
          ) : null}
          {reccommendedAspects.length ? (
            <AspectSection
              label="Recommended"
              aspects={reccommendedAspects}
              aspectIntegrations={aspectIntegrations}
              onChange={handleChange}
              onUpdate={onUpdate}
              templateAttributes={templateAttributes}
              open={true}
            />
          ) : null}
          {optionalAspects.length ? (
            <AspectSection
              label="Optional"
              aspects={optionalAspects}
              aspectIntegrations={aspectIntegrations}
              onChange={handleChange}
              onUpdate={onUpdate}
              templateAttributes={templateAttributes}
              open={true}
            />
          ) : null}
        </AccordionGroup>
      </Grid>

      <Modal
        open={open}
        onClose={() => {
          setOpen(false)
        }}
        onSubmit={async () => {
          await addAspectsAsAttributes(addAspectAttributes)
          setTimeout(() => {
            setOpen(false)
          }, SUBMIT_MODAL_CLOSE_COOLDOWN)
        }}
      >
        <Grid container justifyContent="center" spacing={3}>
          <Grid item>
            <Typography level="title-lg">
              Add {addAspectAttributesType} Item Specifics as Template
              Attributes
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              This action will add the following Template Attributes
            </Typography>
          </Grid>

          {addAspectAttributes.map((attribute) => (
            <Grid key={attribute.attribute.name} item xs={12}>
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={8}>
                  <CodeInline>{attribute.attribute.name}</CodeInline>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    size="sm"
                    variant="outlined"
                    onClick={async () => {
                      await addAspectsAsAttributes([attribute])

                      // remove this attribute from list
                      setAddAspectAttributes(
                        addAspectAttributes.filter(
                          (addAspectAttribute) =>
                            addAspectAttribute.attribute.name !==
                            attribute.attribute.name,
                        ),
                      )
                    }}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Modal>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Grid>
  )
}
