import React, { useEffect, useState } from 'react'
import { GetIntegrationResult, IntegrationName } from '../../api/integration'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import Button from '@mui/joy/Button'
import Alert, { AlertInput, handleAlert } from '../../components/common/Alert'
import {
  getCSVSpreadsheetIntegrations,
  getSheetHeaders,
  setCSVSpreadsheetIntegration,
  setCSVSpreadsheetIntegrationDetails,
} from '../../api/integrations/csv'
import { cloudinaryUploadImage, err } from '../../utils/functions'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import Accordion from '@mui/joy/Accordion'
import AccordionGroup from '@mui/joy/AccordionGroup'
import AccordionDetails from '@mui/joy/AccordionDetails'
import AccordionSummary from '@mui/joy/AccordionSummary'
import ListItemContent from '@mui/joy/ListItemContent'
import SingleSelect from '../../components/common/SingleSelect'
import {
  CSVSheetHeaderOption,
  GetCSVSpreadsheetIntegration,
} from '../../types/CSV.types'
import Tabs from '@mui/joy/Tabs'
import TabList from '@mui/joy/TabList'
import TabPanel from '@mui/joy/TabPanel'
import Tab from '@mui/joy/Tab'
import NamedInput from '../../components/common/NamedInput'
import { toExcelHeaderArray } from '../../classes/CSV'
import Avatar from '@mui/joy/Avatar'
import CircularProgress from '@mui/joy/CircularProgress'
import VerticalIconMenu from '../../components/common/VerticalIconMenu'
import Add from '@mui/icons-material/Add'
import Box from '@mui/material/Box'
import FloatLabelInput from '../../components/common/FloatLabelInput'
import { CloudinarySignature, getCloudinarySignature } from '../../api/product'

export default function CSVIntegration({
  integration,
}: {
  integration: GetIntegrationResult<IntegrationName.CSV>
}): JSX.Element {
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const [headersLoading, setHeadersLoading] = useState<boolean>(false)
  const [saveLoading, setSaveLoading] = useState<boolean>(false)
  const [spreadsheetIntegrations, setSpreadsheetIntegrations] = useState<
    GetCSVSpreadsheetIntegration[]
  >([])

  const [addSpreadsheetName, setAddSpreadsheetName] = useState<string>('')
  const [addSheetName, setAddSheetName] = useState<string>('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedSpreadsheet, setSelectedSpreadsheet] = useState<string>('')
  const [selectedSheet, setSelectedSheet] = useState<string>('')
  const [cloudinarySignature, setCloudinarySignature] = useState<
    CloudinarySignature | undefined
  >()

  useEffect(() => {
    getCloudinarySignature()
      .then((res) => {
        if (res.success && res.data) {
          setCloudinarySignature(res.data)
        } else {
          console.log(res.message)
        }
      })
      .catch((e) => err(e))
  }, [])

  const getSpreadsheet = (
    spreadsheetId: string,
  ): [GetCSVSpreadsheetIntegration | undefined, number | undefined] => {
    const newSpreadsheets = spreadsheetIntegrations.slice()
    const index = newSpreadsheets.findIndex(
      (s) => s.spreadsheetId === spreadsheetId,
    )
    if (index < 0) return [undefined, undefined]
    const newSpreadsheet = newSpreadsheets[index]
    if (!newSpreadsheet) return [undefined, undefined]

    return [newSpreadsheet, index]
  }

  const getSpreadsheetSheet = (spreadsheetId: string, sheetTitle: string) => {
    const [newSpreadsheet] = getSpreadsheet(spreadsheetId)
    if (!newSpreadsheet) return
    const newSpreadsheetSheets = newSpreadsheet.csvSheetIntegrations
    if (!newSpreadsheetSheets) return
    const newSpreadsheetSheet = newSpreadsheetSheets.find(
      (newSpreadsheetSheet) => newSpreadsheetSheet.sheetTitle === sheetTitle,
    )

    return newSpreadsheetSheet
  }

  const getSpreadsheetSheetHeader = (
    spreadsheetId: string,
    sheetTitle: string,
    cellId: string,
  ) => {
    const newSpreadsheetSheet = getSpreadsheetSheet(spreadsheetId, sheetTitle)
    if (!newSpreadsheetSheet?.headers) return
    const headerIndex = newSpreadsheetSheet.headers.findIndex(
      (h) => h.cellId === cellId,
    )
    if (headerIndex < 0) return
    const newSpreadsheetHeader = newSpreadsheetSheet.headers[headerIndex]
    if (!newSpreadsheetHeader) return

    return newSpreadsheetHeader
  }

  const onMenuClick = (
    option: string,
    spreadsheetId: string,
    sheetName: string,
    cellId: string,
  ) => {
    if (!option) return

    const newSpreadsheets = spreadsheetIntegrations.slice()
    const [newSpreadsheet, index] = getSpreadsheet(spreadsheetId)
    const newSpreadsheetSheet = getSpreadsheetSheet(spreadsheetId, sheetName)
    const newSpreadsheetSheetHeader = getSpreadsheetSheetHeader(
      spreadsheetId,
      sheetName,
      cellId,
    )

    if (!newSpreadsheet || index === undefined) return
    if (!newSpreadsheetSheetHeader) return
    if (!newSpreadsheetSheetHeader.options) {
      newSpreadsheetSheetHeader.options = [] as CSVSheetHeaderOption[]
    }
    if (option === 'Add Options') {
      // update header name
      newSpreadsheetSheetHeader.options = []
    } else if (option === 'Remove Options') {
      // update header name
      newSpreadsheetSheetHeader.options = undefined
    } else if (option === 'Make Strict') {
      // update header name
      newSpreadsheetSheetHeader.strict = true
    } else if (option === 'Make Unstrict') {
      // update header name
      newSpreadsheetSheetHeader.strict = false
    } else if (option === 'Remove') {
      // update header name
      const i = newSpreadsheetSheet?.headers?.findIndex(
        (h) => h.cellId === cellId,
      )
      if (i !== undefined && i >= 0) {
        newSpreadsheetSheet?.headers?.splice(i, 1)
      }
      // cannot remove last header
    }

    // set
    newSpreadsheets[index] = newSpreadsheet
    setSpreadsheetIntegrations(newSpreadsheets)
  }

  const handleSheetHeaderChange = (
    spreadsheetId: string,
    sheetName: string,
    cellId: string,
    newValue: string | undefined,
    newHeaderName: string,
  ) => {
    const newSpreadsheets = spreadsheetIntegrations.slice()
    const [newSpreadsheet, index] = getSpreadsheet(spreadsheetId)
    const newSpreadsheetSheetHeader = getSpreadsheetSheetHeader(
      spreadsheetId,
      sheetName,
      cellId,
    )

    if (!newSpreadsheet || index === undefined) return
    if (!newSpreadsheetSheetHeader) return

    // update header name
    newSpreadsheetSheetHeader.name = newHeaderName
    // update value
    newSpreadsheetSheetHeader.value = newValue

    newSpreadsheets[index] = newSpreadsheet
    setSpreadsheetIntegrations(newSpreadsheets)
  }

  const handleAddSheetHeaderOption = (
    spreadsheetId: string,
    sheetName: string,
    cellId: string,
    newOption: string,
  ) => {
    const newSpreadsheets = spreadsheetIntegrations.slice()
    const [newSpreadsheet, index] = getSpreadsheet(spreadsheetId)
    const newSpreadsheetSheetHeader = getSpreadsheetSheetHeader(
      spreadsheetId,
      sheetName,
      cellId,
    )

    if (!newSpreadsheet || index === undefined) return
    if (!newSpreadsheetSheetHeader) return

    if (!newSpreadsheetSheetHeader.options) {
      newSpreadsheetSheetHeader.options = [] as CSVSheetHeaderOption[]
    }

    const newOptions = newSpreadsheetSheetHeader.options?.concat([
      { option: newOption },
    ])
    newSpreadsheetSheetHeader.options = newOptions

    newSpreadsheets[index] = newSpreadsheet
    setSpreadsheetIntegrations(newSpreadsheets)
  }

  const handleAddSpreadsheetIntegration = () => {
    if (!integration.integration) return

    if (!addSpreadsheetName) {
      setAlert({
        severity: 'error',
        message: 'Must include a sheet name.',
        open: true,
      })
      return
    }

    setCSVSpreadsheetIntegration({
      integrationId: integration.integration.id,
      name: addSpreadsheetName,
    })
      .then((res) => {
        handleAlert(setAlert, res, 'Success')
        if (res.success && res.data) {
          setAddSpreadsheetName('')
          const newIntegrations = spreadsheetIntegrations.concat([res.data])
          setSpreadsheetIntegrations(newIntegrations)
        }
      })
      .catch((e) => err(e))
  }

  useEffect(() => {
    if (!integration?.integration?.id) return
    getCSVSpreadsheetIntegrations(integration.integration.id)
      .then((res) => {
        if (res.success && res.data) {
          setSpreadsheetIntegrations(res.data)
        }
      })
      .catch((e) => err(e))
  }, [integration])

  const handleGetSheetHeaders = (spreadsheetId: string) => {
    setHeadersLoading(true)
    getSheetHeaders(spreadsheetId)
      .then((res) => {
        setHeadersLoading(false)
        if (res.success && res.data) {
          const newSpreadsheetIntegrations = spreadsheetIntegrations.slice()
          const spreadsheetIndex = newSpreadsheetIntegrations.findIndex(
            (s) => s.spreadsheetId === res.data?.spreadsheetId,
          )
          if (spreadsheetIndex < 0) return
          newSpreadsheetIntegrations[spreadsheetIndex] = res.data

          setSpreadsheetIntegrations(newSpreadsheetIntegrations)
        }
      })
      .catch((e) => err(e))
  }

  const saveToSheet = (spreadsheetId: string) => {
    const saveSpreadSheet = spreadsheetIntegrations.find(
      (s) => s.spreadsheetId === spreadsheetId,
    )

    if (!saveSpreadSheet) return
    setSaveLoading(true)
    setCSVSpreadsheetIntegration(saveSpreadSheet)
      .then((res) => {
        setSaveLoading(false)

        if (res.success) {
          handleAlert(setAlert, res, 'Saved Sheet Integration')
          if (res.data) {
            const updatedSpreadsheetIndex = spreadsheetIntegrations.findIndex(
              (s) =>
                res.data?.spreadsheetId &&
                s.spreadsheetId === res.data?.spreadsheetId,
            )
            if (updatedSpreadsheetIndex >= 0) {
              const newSpreadsheetIntegrations = spreadsheetIntegrations.slice()
              newSpreadsheetIntegrations[updatedSpreadsheetIndex] = res.data
              setSpreadsheetIntegrations(newSpreadsheetIntegrations)
            }
          }
        }
      })
      .catch((e) => err(e))
  }

  const handleAddSheet = (spreadsheetId: string) => {
    if (!addSheetName) return
    const newSpreadsheets = spreadsheetIntegrations.slice()
    const index = newSpreadsheets.findIndex(
      (s) => s.spreadsheetId === spreadsheetId,
    )
    if (index < 0) return
    const newSpreadsheet = newSpreadsheets[index]
    if (!newSpreadsheet) return
    const newSpreadsheetSheets = newSpreadsheet.csvSheetIntegrations
    if (!newSpreadsheetSheets) return

    const sheetExists = newSpreadsheetSheets.find(
      (newSpreadsheetSheet) => newSpreadsheetSheet.sheetTitle === addSheetName,
    )
    if (sheetExists) return

    newSpreadsheetSheets.push({
      sheetTitle: addSheetName,
      sheetId: '',
      index: newSpreadsheetSheets.length,
    })

    newSpreadsheets[index] = newSpreadsheet
    setSpreadsheetIntegrations(newSpreadsheets)
    setAddSheetName('')
    setSelectedSheet(addSheetName)
  }

  const handleUpdateSpreadsheetName = (
    spreadsheetId: string,
    spreadsheetName: string,
  ) => {
    if (!spreadsheetId || !spreadsheetName) return
    const newSpreadsheets = spreadsheetIntegrations.slice()
    const index = newSpreadsheets.findIndex(
      (s) => s.spreadsheetId === spreadsheetId,
    )
    if (index < 0) return
    const newSpreadsheet = newSpreadsheets[index]
    if (!newSpreadsheet) return
    newSpreadsheet.name = spreadsheetName

    newSpreadsheets[index] = newSpreadsheet
    setSpreadsheetIntegrations(newSpreadsheets)
    setSelectedSpreadsheet(spreadsheetName)
  }

  const handleUpdateSheetName = (
    spreadsheetId: string,
    sheetName: string,
    newSheetName: string,
  ) => {
    if (!newSheetName) return
    const newSpreadsheets = spreadsheetIntegrations.slice()
    const index = newSpreadsheets.findIndex(
      (s) => s.spreadsheetId === spreadsheetId,
    )
    if (index < 0) return
    const newSpreadsheet = newSpreadsheets[index]
    if (!newSpreadsheet) return
    const newSpreadsheetSheets = newSpreadsheet.csvSheetIntegrations
    if (!newSpreadsheetSheets) return

    const existingSheet = newSpreadsheetSheets?.find(
      (h) => h.sheetTitle === sheetName,
    )

    if (!existingSheet) return
    existingSheet.sheetTitle = newSheetName

    newSpreadsheets[index] = newSpreadsheet
    setSpreadsheetIntegrations(newSpreadsheets)
    setSelectedSheet(newSheetName)
  }

  const handleUpdateSheetHeaderRow = (
    spreadsheetId: string,
    sheetName: string,
    headerRow: number,
  ) => {
    const newSpreadsheets = spreadsheetIntegrations.slice()
    const index = newSpreadsheets.findIndex(
      (s) => s.spreadsheetId === spreadsheetId,
    )
    if (index < 0) return
    const newSpreadsheet = newSpreadsheets[index]
    if (!newSpreadsheet) return
    const newSpreadsheetSheets = newSpreadsheet.csvSheetIntegrations
    if (!newSpreadsheetSheets) return

    const existingSheet = newSpreadsheetSheets?.find(
      (h) => h.sheetTitle === sheetName,
    )

    if (!existingSheet) return
    existingSheet.headerRow = headerRow

    newSpreadsheets[index] = newSpreadsheet
    setSpreadsheetIntegrations(newSpreadsheets)
    setSelectedSheet(sheetName)
  }

  const handleAddSheetHeader = (spreadsheetId: string, sheetName: string) => {
    const newSpreadsheets = spreadsheetIntegrations.slice()
    const index = newSpreadsheets.findIndex(
      (s) => s.spreadsheetId === spreadsheetId,
    )
    if (index < 0) return
    const newSpreadsheet = newSpreadsheets[index]
    if (!newSpreadsheet) return
    const newSpreadsheetSheets = newSpreadsheet.csvSheetIntegrations
    if (!newSpreadsheetSheets) return

    const newSpreadsheetSheet = newSpreadsheetSheets.find(
      (newSpreadsheetSheet) => newSpreadsheetSheet.sheetTitle === sheetName,
    )

    if (!newSpreadsheetSheet) return
    if (!newSpreadsheetSheet.headers) {
      newSpreadsheetSheet.headers = []
    }

    const length = newSpreadsheetSheet.headers.length
    const allCells = toExcelHeaderArray(length + 1)
    const existingCellIds = newSpreadsheetSheet.headers.map((h) => h.cellId)
    const availableCells = allCells?.filter((n) => !existingCellIds.includes(n))

    const newCellId = availableCells[0]
    if (!newCellId) return
    const cellIndex = allCells.findIndex((c) => c === newCellId)
    if (cellIndex < 0) return
    newSpreadsheetSheet.headers.push({
      name: newCellId,
      index: cellIndex,
      cellId: newCellId,
    })
    newSpreadsheetSheet.headers.sort(
      (a, b) => (a?.index || 0) - (b?.index || 0),
    )

    newSpreadsheets[index] = newSpreadsheet
    setSpreadsheetIntegrations(newSpreadsheets)
  }

  const handleRemoveSheetHeader = (
    spreadsheetId: string,
    sheetName: string,
  ) => {
    const newSpreadsheets = spreadsheetIntegrations.slice()
    const index = newSpreadsheets.findIndex(
      (s) => s.spreadsheetId === spreadsheetId,
    )
    if (index < 0) return
    const newSpreadsheet = newSpreadsheets[index]
    if (!newSpreadsheet) return
    const newSpreadsheetSheets = newSpreadsheet.csvSheetIntegrations
    if (!newSpreadsheetSheets) return

    const sheetIndex = newSpreadsheetSheets.findIndex(
      (newSpreadsheetSheet) => newSpreadsheetSheet.sheetTitle === sheetName,
    )

    if (sheetIndex < 0) return
    newSpreadsheetSheets.splice(sheetIndex, 1)

    newSpreadsheets[index] = newSpreadsheet
    setSpreadsheetIntegrations(newSpreadsheets)
  }

  // const handleSave = () => {
  //   console.log('Save')
  //   setCSVSpreadsheetIntegrations(spreadsheetIntegrations)
  //     .then((res) => {
  //       console.log(res.data)
  //     })
  //     .catch((e) => {
  //       err(e)
  //     })
  // }

  const handleAppStoreClick = () => {
    if (!integration.oAuthUrl) return
    window.open(integration.oAuthUrl, '_blank')
  }

  const handleSelectImage = async (spreadsheetId: string, files: File[]) => {
    const imageFile = files[0]
    if (!spreadsheetId) return
    if (!imageFile) return
    if (!cloudinarySignature) return
    const uploadedImageUrl = await cloudinaryUploadImage(
      cloudinarySignature,
      imageFile,
    )

    if (!uploadedImageUrl) {
      setAlert({
        open: true,
        message: 'Failed to upload profile image.',
        severity: 'error',
      })
      return
    }

    const newSpreadsheets = spreadsheetIntegrations.slice()
    const index = newSpreadsheets.findIndex(
      (s) => s.spreadsheetId === spreadsheetId,
    )
    if (index < 0) return
    const newSpreadsheet = newSpreadsheets[index]
    if (!newSpreadsheet) return
    newSpreadsheet.image = uploadedImageUrl

    newSpreadsheets[index] = newSpreadsheet
    setSpreadsheetIntegrations(newSpreadsheets)

    // set spreadsheet details in database
    const res = await setCSVSpreadsheetIntegrationDetails(newSpreadsheet)
    if (res.success && res.data) {
      handleAlert(setAlert, res, 'Successfully updated spreadsheet icon image.')
    } else {
      setAlert({
        open: true,
        message: 'Failed to set spreadsheet icon image.',
        severity: 'error',
      })
    }
  }

  return (
    <Grid container spacing={4} pt={1}>
      <Grid item xs={12}>
        <Typography level="title-lg">CSV Integrations</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={8}>
          {integration.authenticated ? (
            <>
              <Grid item xs={12}>
                <Grid container justifyContent="center" spacing={2}>
                  {spreadsheetIntegrations.length ? (
                    spreadsheetIntegrations.map((csvSpreadsheetIntegration) => {
                      const spreadsheetId =
                        csvSpreadsheetIntegration.spreadsheetId

                      const sheetIntegrations =
                        csvSpreadsheetIntegration.csvSheetIntegrations
                      return (
                        <Grid item xs={12} key={csvSpreadsheetIntegration.id}>
                          <AccordionGroup variant="outlined" size="lg">
                            <Accordion
                              onChange={(_e, expanded) => {
                                if (!expanded) {
                                  setSelectedSpreadsheet('')
                                  return
                                }
                                setSelectedSpreadsheet(
                                  csvSpreadsheetIntegration.name,
                                )
                              }}
                            >
                              <AccordionSummary>
                                <ListItemContent sx={{ p: 1 }}>
                                  <Typography level="title-lg" component="div">
                                    {csvSpreadsheetIntegration.name}
                                    {spreadsheetId ? (
                                      <OpenInNewIcon
                                        fontSize="small"
                                        sx={{
                                          opacity: 0.5,
                                          ml: 0.5,
                                          pt: 0.3,
                                        }}
                                        onClick={(e) => {
                                          e.stopPropagation()
                                          window
                                            ?.open(
                                              `https://docs.google.com/spreadsheets/d/${spreadsheetId}/edit`,
                                              '_blank',
                                            )
                                            ?.focus()
                                        }}
                                      />
                                    ) : (
                                      ''
                                    )}
                                  </Typography>
                                  <Typography level="body-xs">
                                    {spreadsheetId}
                                  </Typography>
                                </ListItemContent>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Grid
                                  container
                                  justifyContent="center"
                                  spacing={4}
                                  sx={{ p: 2 }}
                                >
                                  <Grid item xs={6}>
                                    <Typography mt={1}>
                                      <strong>Spreadsheet Icon</strong>
                                    </Typography>
                                    <Typography level="body-xs">
                                      &quot;Listed On&quot; channel Icon
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Box
                                      sx={{
                                        width: '50px',
                                        height: '50px',
                                        m: 'auto',
                                      }}
                                    >
                                      <>
                                        <input
                                          hidden
                                          id={'spreadsheet-icon-image-upload'}
                                          type="file"
                                          name="myImage"
                                          accept=".png,.jpeg,.jpg"
                                          onChange={(e) => {
                                            void handleSelectImage(
                                              spreadsheetId,
                                              Array.from(e.target.files || []),
                                            )
                                          }}
                                        />
                                        <label
                                          htmlFor={
                                            'spreadsheet-icon-image-upload'
                                          }
                                        >
                                          <Avatar
                                            alt={'Spreadsheet Icon'}
                                            src={
                                              csvSpreadsheetIntegration.image
                                            }
                                            sx={{
                                              width: '50px',
                                              height: '50px',
                                              m: 'auto',
                                              cursor: 'pointer',
                                            }}
                                          />
                                        </label>
                                      </>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <FloatLabelInput
                                      label="Spreadsheet Name"
                                      value={
                                        csvSpreadsheetIntegration?.name || ''
                                      }
                                      onChange={(v) =>
                                        handleUpdateSpreadsheetName(
                                          csvSpreadsheetIntegration.spreadsheetId,
                                          v,
                                        )
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Tabs
                                      value={
                                        selectedSheet ||
                                        csvSpreadsheetIntegration
                                          ?.csvSheetIntegrations?.[0]
                                          ?.sheetTitle ||
                                        ''
                                      }
                                      size="lg"
                                      variant="outlined"
                                      onChange={(_e, v) => {
                                        if (!v) return
                                        setSelectedSheet(`${v}`)
                                        // if (v !== '__ADD_SHEET') {
                                        //   setSelectedSheet(`${v}`)
                                        // } else {
                                        //   // Add sheet
                                        //   handleAddSheet(
                                        //     spreadsheetId,
                                        //     'New Sheet',
                                        //   )
                                        // }
                                      }}
                                    >
                                      <TabList
                                        sx={{
                                          overflow: 'auto',
                                          scrollSnapType: 'x mandatory',
                                          '&::-webkit-scrollbar': {
                                            display: 'none',
                                          },
                                        }}
                                      >
                                        {sheetIntegrations?.map(
                                          (csvSheetIntegration) => {
                                            const sheetTitle =
                                              csvSheetIntegration.sheetTitle

                                            const sheetSelected = selectedSheet
                                              ? sheetTitle === selectedSheet
                                              : sheetTitle ===
                                                sheetIntegrations[0]?.sheetTitle
                                            return (
                                              <Tab
                                                variant="plain"
                                                disableIndicator
                                                key={sheetTitle}
                                                value={sheetTitle}
                                                sx={{
                                                  p: sheetSelected
                                                    ? 0.5
                                                    : undefined,
                                                  flex: 'none',
                                                  scrollSnapAlign: 'start',
                                                }}
                                              >
                                                {sheetSelected ? (
                                                  <Box
                                                    sx={{
                                                      overflow: 'hidden',
                                                    }}
                                                  >
                                                    <FloatLabelInput
                                                      defaultValue={sheetTitle}
                                                      fullWidth={true}
                                                      onBlur={(e) =>
                                                        handleUpdateSheetName(
                                                          spreadsheetId,
                                                          sheetTitle,
                                                          e?.target.value || '',
                                                        )
                                                      }
                                                    ></FloatLabelInput>
                                                  </Box>
                                                ) : (
                                                  <Typography level="title-md">
                                                    {sheetTitle}
                                                  </Typography>
                                                )}
                                                {/* <Typography level="title-md">
                                                {sheetTitle}
                                              </Typography> */}
                                              </Tab>
                                            )
                                          },
                                        )}
                                        <Tab
                                          variant="plain"
                                          disableIndicator
                                          value={'__ADD_SHEET'}
                                        >
                                          <Add
                                            fontSize="small"
                                            color="action"
                                          />
                                        </Tab>
                                      </TabList>
                                      {sheetIntegrations?.map(
                                        (sheetIntegration) => {
                                          const sheetTitle =
                                            sheetIntegration.sheetTitle
                                          const headers =
                                            sheetIntegration.headers

                                          return (
                                            <TabPanel
                                              key={sheetTitle}
                                              value={sheetTitle}
                                            >
                                              <Grid
                                                container
                                                justifyContent="center"
                                                spacing={2}
                                              >
                                                <Grid item xs={12}>
                                                  <Grid
                                                    container
                                                    justifyContent="center"
                                                    spacing={2}
                                                  >
                                                    <Grid item xs={12}>
                                                      <Typography level="title-lg">
                                                        Headers
                                                      </Typography>
                                                    </Grid>
                                                    {headers?.length ? (
                                                      headers.map((header) => {
                                                        return header.options ? (
                                                          <Grid
                                                            item
                                                            xs={12}
                                                            key={
                                                              header.id +
                                                              header.cellId
                                                            }
                                                          >
                                                            <Grid
                                                              container
                                                              justifyContent="center"
                                                              spacing={1}
                                                            >
                                                              <Grid item xs={9}>
                                                                <NamedInput
                                                                  name={
                                                                    header.name
                                                                  }
                                                                  value={
                                                                    header.value
                                                                  }
                                                                  nameLabel={
                                                                    'column ' +
                                                                    header.cellId
                                                                  }
                                                                  nameEditable={
                                                                    true
                                                                  }
                                                                  fullWidth
                                                                  placeholder="Value"
                                                                  onChange={(
                                                                    e,
                                                                  ) =>
                                                                    handleSheetHeaderChange(
                                                                      spreadsheetId,
                                                                      sheetTitle,
                                                                      header.cellId,
                                                                      e,
                                                                      header.name,
                                                                    )
                                                                  }
                                                                  onNameChange={(
                                                                    e,
                                                                  ) =>
                                                                    handleSheetHeaderChange(
                                                                      spreadsheetId,
                                                                      sheetTitle,
                                                                      header.cellId,
                                                                      header.value,
                                                                      e,
                                                                    )
                                                                  }
                                                                  endDecorator={
                                                                    <VerticalIconMenu
                                                                      size="small"
                                                                      options={[
                                                                        'Remove',
                                                                      ]}
                                                                      onClick={(
                                                                        option,
                                                                      ) =>
                                                                        onMenuClick(
                                                                          option,
                                                                          spreadsheetId,
                                                                          sheetTitle,
                                                                          header.cellId,
                                                                        )
                                                                      }
                                                                    />
                                                                  }
                                                                />
                                                              </Grid>
                                                              <Grid item xs={3}>
                                                                <SingleSelect
                                                                  placeholder={
                                                                    header?.strict
                                                                      ? 'Possible Values'
                                                                      : 'Example Values'
                                                                  }
                                                                  value={''}
                                                                  freeSolo
                                                                  addValues={
                                                                    true
                                                                  }
                                                                  onAddValue={(
                                                                    v,
                                                                  ) =>
                                                                    handleAddSheetHeaderOption(
                                                                      spreadsheetId,
                                                                      sheetTitle,
                                                                      header.cellId,
                                                                      v,
                                                                    )
                                                                  }
                                                                  options={
                                                                    header.options.map(
                                                                      (o) =>
                                                                        o.option ||
                                                                        '',
                                                                    ) || []
                                                                  }
                                                                  endDecorator={
                                                                    <VerticalIconMenu
                                                                      size="small"
                                                                      options={[
                                                                        'Remove Options',
                                                                        'Make Strict',
                                                                        'Make Unstrict',
                                                                      ]}
                                                                      onClick={(
                                                                        option,
                                                                      ) =>
                                                                        onMenuClick(
                                                                          option,
                                                                          spreadsheetId,
                                                                          sheetTitle,
                                                                          header.cellId,
                                                                        )
                                                                      }
                                                                    />
                                                                  }
                                                                />
                                                              </Grid>
                                                            </Grid>
                                                          </Grid>
                                                        ) : (
                                                          <Grid
                                                            item
                                                            xs={12}
                                                            key={
                                                              header.id +
                                                              header.cellId
                                                            }
                                                          >
                                                            <NamedInput
                                                              name={header.name}
                                                              value={
                                                                header.value
                                                              }
                                                              nameLabel={
                                                                'column ' +
                                                                header.cellId
                                                              }
                                                              nameEditable={
                                                                true
                                                              }
                                                              fullWidth
                                                              placeholder="Value"
                                                              onChange={(e) =>
                                                                handleSheetHeaderChange(
                                                                  spreadsheetId,
                                                                  sheetTitle,
                                                                  header.cellId,
                                                                  e,
                                                                  header.name,
                                                                )
                                                              }
                                                              onNameChange={(
                                                                e,
                                                              ) =>
                                                                handleSheetHeaderChange(
                                                                  spreadsheetId,
                                                                  sheetTitle,
                                                                  header.cellId,
                                                                  header.value,
                                                                  e,
                                                                )
                                                              }
                                                              endDecorator={
                                                                <VerticalIconMenu
                                                                  size="small"
                                                                  options={[
                                                                    'Add Options',
                                                                    'Remove',
                                                                  ]}
                                                                  onClick={(
                                                                    option,
                                                                  ) =>
                                                                    onMenuClick(
                                                                      option,
                                                                      spreadsheetId,
                                                                      sheetTitle,
                                                                      header.cellId,
                                                                    )
                                                                  }
                                                                />
                                                              }
                                                            />
                                                          </Grid>
                                                        )
                                                      })
                                                    ) : (
                                                      <Grid item xs={12}>
                                                        <Box p={1}>
                                                          <Typography level="body-sm">
                                                            No Sheet Headers
                                                          </Typography>
                                                        </Box>
                                                      </Grid>
                                                    )}
                                                    <Grid item xs={12}>
                                                      <Button
                                                        variant="plain"
                                                        sx={{
                                                          float: 'right',
                                                        }}
                                                        onClick={() =>
                                                          handleAddSheetHeader(
                                                            spreadsheetId,
                                                            sheetTitle,
                                                          )
                                                        }
                                                      >
                                                        Add Header
                                                      </Button>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                  <Grid
                                                    container
                                                    justifyContent="center"
                                                    spacing={2}
                                                  >
                                                    <Grid item xs={12}>
                                                      <Typography level="title-lg">
                                                        Sheet Options
                                                      </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                      <FloatLabelInput
                                                        label="Header Row"
                                                        helperText={
                                                          <Typography level="body-xs">
                                                            Row index to search
                                                            for column headers
                                                          </Typography>
                                                        }
                                                        value={
                                                          sheetIntegration?.headerRow ||
                                                          ''
                                                        }
                                                        onChange={(v) =>
                                                          handleUpdateSheetHeaderRow(
                                                            spreadsheetId,
                                                            sheetTitle,
                                                            parseInt(v),
                                                          )
                                                        }
                                                      />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                      <Button
                                                        variant="plain"
                                                        color="danger"
                                                        sx={{
                                                          float: 'left',
                                                        }}
                                                        onClick={() =>
                                                          handleRemoveSheetHeader(
                                                            spreadsheetId,
                                                            sheetTitle,
                                                          )
                                                        }
                                                      >
                                                        Remove Sheet
                                                      </Button>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                          )
                                        },
                                      )}
                                      <TabPanel value={'__ADD_SHEET'}>
                                        <Grid container spacing={1}>
                                          <Grid item xs={12}>
                                            <FloatLabelInput
                                              label="New Sheet Name"
                                              placeholder="New Sheet Name"
                                              value={addSheetName}
                                              onChange={setAddSheetName}
                                              button={
                                                <Button
                                                  sx={{ float: 'right' }}
                                                  variant="soft"
                                                  onClick={() =>
                                                    handleAddSheet(
                                                      spreadsheetId,
                                                    )
                                                  }
                                                  disabled={!addSheetName}
                                                >
                                                  Add Sheet
                                                </Button>
                                              }
                                            />
                                          </Grid>
                                        </Grid>
                                      </TabPanel>
                                    </Tabs>
                                  </Grid>

                                  <Grid item xs={12}>
                                    <Grid
                                      container
                                      justifyContent="center"
                                      spacing={2}
                                    >
                                      <Grid item xs={12}>
                                        <Button
                                          sx={{ float: 'left' }}
                                          variant="soft"
                                          disabled={headersLoading}
                                          onClick={() =>
                                            handleGetSheetHeaders(
                                              csvSpreadsheetIntegration.spreadsheetId,
                                            )
                                          }
                                          endDecorator={
                                            headersLoading ? (
                                              <CircularProgress />
                                            ) : null
                                          }
                                        >
                                          Fetch Sheets
                                        </Button>
                                        <Button
                                          sx={{ float: 'right' }}
                                          variant="solid"
                                          onClick={() =>
                                            saveToSheet(spreadsheetId)
                                          }
                                          endDecorator={
                                            saveLoading ? (
                                              <CircularProgress />
                                            ) : null
                                          }
                                        >
                                          Update
                                        </Button>
                                      </Grid>
                                    </Grid>
                                    {/* <Button
                                      sx={{ float: 'left' }}
                                      color="danger"
                                      variant="plain"
                                    >
                                      Close
                                    </Button> */}
                                  </Grid>
                                </Grid>
                              </AccordionDetails>
                            </Accordion>
                          </AccordionGroup>
                        </Grid>
                      )
                    })
                  ) : (
                    <Typography>No Spreadsheet Integrations</Typography>
                  )}
                  {/* {spreadsheetIntegrations.length ? (
                    <Grid item xs={12}>
                      <Button sx={{ float: 'right' }} onClick={handleSave}>
                        Save
                      </Button>
                    </Grid>
                  ) : null} */}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container justifyContent="center" spacing={2}>
                  <Grid item xs={12}>
                    <Typography level="title-lg">New Spreadsheet</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FloatLabelInput
                      label="New Spreadsheet"
                      fullWidth={true}
                      value={addSpreadsheetName}
                      onChange={setAddSpreadsheetName}
                      button={
                        <Button
                          sx={{ float: 'right' }}
                          variant="solid"
                          onClick={handleAddSpreadsheetIntegration}
                        >
                          Create
                        </Button>
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : null}
          {integration.authenticated ? (
            <>
              <Grid item xs={12}>
                <Grid
                  container
                  justifyContent="center"
                  spacing={2}
                  direction="column"
                >
                  <Grid item xs={12}>
                    <Typography level="title-lg">Authentication</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Button variant="solid" onClick={handleAppStoreClick}>
                      Re-Authorize
                    </Button>
                  </Grid>
                  <Grid item>
                    <Typography level="body-xs">
                      Authentication Last Updated:{' '}
                      {integration.integration?.updatedAt
                        ? new Date(
                            integration.integration?.updatedAt,
                          ).toLocaleString()
                        : 'Unknown'}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Typography>
                  To authenticate app access, please authorize with Google.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button variant="solid" onClick={handleAppStoreClick}>
                  Open
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Grid>
  )
}
