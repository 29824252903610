import React from 'react'
import Grid from '@mui/material/Grid'
import {
  Aspect,
  EbayAspectTemplateIntegration,
} from '../../../../types/Ebay.types'
import { GetProductTemplateAttribute } from '../../../../api/product'

import AspectGroup from './AspectGroup'
import { AspectIntegrationInput } from './EbayIntegrationsComponent'
import Accordion from '@mui/joy/Accordion'
import AccordionDetails from '@mui/joy/AccordionDetails'
import AccordionSummary from '@mui/joy/AccordionSummary'
import Box from '@mui/joy/Box'
import Typography from '@mui/joy/Typography'

export default function AspectSection({
  label,
  aspects,
  aspectIntegrations,
  templateAttributes,
  open,
  onChange,
  onUpdate,
}: {
  label: string
  aspects: Aspect[]
  aspectIntegrations: EbayAspectTemplateIntegration[]
  templateAttributes: GetProductTemplateAttribute[]
  open?: boolean
  onChange: (aspect: AspectIntegrationInput) => void
  onUpdate?: () => void
}): JSX.Element {
  const getIntegrationAspect = (
    getAspect: Aspect,
  ): AspectIntegrationInput | undefined =>
    aspectIntegrations.find(
      (a) => a.aspectName === getAspect.localizedAspectName,
    )

  const handleChange = onChange

  return (
    <Accordion defaultExpanded={open}>
      <AccordionSummary>
        <Box p={1}>
          <Typography level="title-md">
            <strong>{label}</strong>
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box p={2}>
          <Grid container spacing={1} justifyContent="center">
            {aspects.map((aspect) => (
              <Grid item key={aspect.localizedAspectName} xs={12}>
                <AspectGroup
                  aspect={aspect}
                  templateAttributes={templateAttributes}
                  aspectIntegration={getIntegrationAspect(aspect)}
                  onChange={handleChange}
                  onUpdate={onUpdate}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}
