import React, { useState } from 'react'
import Autocomplete from '@mui/joy/Autocomplete'
import AutocompleteOption from '@mui/joy/AutocompleteOption'
import Grid from '@mui/material/Grid'
import { SxProps, Theme } from '@mui/material'
import FloatLabelInput from './FloatLabelInput'
import Typography from '@mui/joy/Typography'
import AttributeInline from './AttributeInline'
import Chip from '@mui/joy/Chip'
import { getAttributeName, getAttributeTag } from '../../utils/functions'
import Close from '@mui/icons-material/Close'
import FormControl from '@mui/joy/FormControl'
import FormHelperText from '@mui/joy/FormHelperText'
import SvgIcon from '@mui/joy/SvgIcon'
import { TypographySystem } from '@mui/joy/styles/types/typography'
import Add from '@mui/icons-material/Add'
import Box from '@mui/joy/Box'

type AttributeSelectProps = {
  label?: string
  name?: string
  nameLevel?: keyof TypographySystem
  nameEditable?: boolean
  nameEditDisabled?: boolean
  value?: string
  defaultValue?: string
  values?: string[]
  attributeNames?: string[]
  options?: string[]
  multiple?: boolean
  required?: boolean
  freeSolo?: boolean
  endDecorator?: React.ReactNode
  endElement?: React.ReactNode
  sx?: SxProps<Theme> | undefined
  xs?: [number, number]
  sm?: [number, number]
  md?: [number, number]
  lg?: [number, number]
  onBlur?: () => void
  onChange?: (value: string | undefined) => void
  onSelect?: (values: string[] | string | undefined) => void
  onUpdate?: () => void
  onNameChange?: (value: string) => void
  onNameBlur?: (e?: React.FocusEvent<HTMLInputElement, Element>) => void
  onClick?: () => void
  onFocus?: () => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void
  nameLabel?: string | JSX.Element
  placeholder?: string
  namePlaceholder?: string
  type?: React.HTMLInputTypeAttribute
  size?: 'sm' | 'md' | 'lg'
  fullWidth?: boolean
  disabled?: boolean
  readOnly?: boolean
  errorText?: string
  helperText?: string | React.ReactNode
  autoComplete?: string
  autoCapitalize?: boolean
  autoCorrect?: boolean
  format?: 'USD'
  addOptions?: string[]
  defaultOption?: boolean
  warningText?: string
  renderAddOption?: (option: string) => React.ReactNode | string
  onAddOptionClick?: (option: string) => void
}

export default function AttributeSelect({
  label,
  name,
  nameLevel,
  nameEditable,
  nameEditDisabled,
  value: defaultValue,
  values,
  attributeNames = [],
  options = [],
  multiple,
  required,
  freeSolo,
  endDecorator,
  endElement,
  sx,
  xs,
  sm,
  md,
  lg,
  onBlur,
  onChange,
  onSelect,
  onNameChange,
  onClick,
  onNameBlur,
  onFocus,
  onKeyDown,
  nameLabel,
  placeholder,
  namePlaceholder,
  type,
  size,
  fullWidth,
  disabled,
  readOnly,
  errorText,
  helperText,
  autoComplete,
  autoCapitalize,
  autoCorrect,
  format,
  addOptions = [],
  defaultOption,
  warningText,
  renderAddOption,
  onAddOptionClick,
}: AttributeSelectProps): JSX.Element {
  const [inputValue, setInputValue] = useState<string>('')
  const allOptions = addOptions
    .map((v) => `__ADD_OPTION__${v}`)
    .concat(options)
    .concat(attributeNames.map((a) => `@${a}`))

  let value = defaultValue
  const attributeValue = getAttributeName(value)
  if (value?.startsWith('{{') && attributeValue) {
    value = `@${attributeValue}`
  }

  const attributeValues = values?.map((value) => {
    const attributeValue = getAttributeName(value)
    if (value?.startsWith('{{') && attributeValue) {
      return `@${attributeValue}`
    }
    return value
  })

  const renderOption = (
    props: Omit<React.HTMLAttributes<HTMLLIElement>, 'color'>,
    option: string,
  ) => {
    const addOptionValue = option.replace('__ADD_OPTION__', '')
    const isAttribute = option.startsWith('@')

    return addOptions.length && option.startsWith('__ADD_OPTION__') ? (
      <AutocompleteOption
        {...props}
        sx={{ p: '3px' }}
        onClick={(e) => {
          e.preventDefault()
          onAddOptionClick?.(addOptionValue)
        }}
      >
        {renderAddOption ? (
          renderAddOption(addOptionValue)
        ) : (
          <Typography
            level="title-sm"
            startDecorator={<Add fontSize="small" />}
          >
            <strong>
              Add{' '}
              {<AttributeInline size="s">@{addOptionValue}</AttributeInline>} to
              Template
            </strong>
          </Typography>
        )}
      </AutocompleteOption>
    ) : isAttribute ? (
      <AutocompleteOption {...props} sx={{ p: '3px' }}>
        <AttributeInline size="m" width="100%">
          {option}
        </AttributeInline>
      </AutocompleteOption>
    ) : (
      <AutocompleteOption {...props}>{option}</AutocompleteOption>
    )
  }

  let inputColor: 'warning' | 'danger' | undefined = undefined
  if (errorText) {
    inputColor = 'danger'
  } else if (warningText) {
    inputColor = 'warning'
  }

  const AutocompleteElement = (
    <FormControl>
      {multiple ? (
        <Autocomplete
          placeholder={!value ? label : ''}
          clearOnEscape
          disabled={disabled}
          color={inputColor}
          options={allOptions}
          multiple={multiple}
          value={attributeValues}
          freeSolo={true}
          onBlur={onBlur}
          inputValue={inputValue}
          onInputChange={(e, inputChange) => {
            if (value && !inputValue) {
              setInputValue(value + inputChange)
              onChange?.('')
              return
            }
            setInputValue(inputChange)
          }}
          onChange={(e, newValues) => {
            const updateValues = newValues.map((value): string => {
              if (value?.startsWith('@')) {
                return getAttributeTag(value.replace('@', ''))
              }
              return value
            })
            onSelect?.(updateValues)
          }}
          getOptionLabel={(option) => option}
          renderOption={renderOption}
          renderTags={(tags, getTagProps) =>
            tags.map((item, index) => {
              if (
                item.startsWith('@') &&
                attributeNames?.includes(item.slice(1))
              ) {
                return (
                  <Chip
                    variant="outlined"
                    endDecorator={
                      <SvgIcon fontSize="md">
                        <Close />
                      </SvgIcon>
                    }
                    {...getTagProps({ index })}
                    key={item}
                    sx={{ borderRadius: '12px', minWidth: 0 }}
                  >
                    <strong>{item}</strong>
                  </Chip>
                )
              }

              return (
                <Chip
                  variant="outlined"
                  {...getTagProps({ index })}
                  endDecorator={
                    <SvgIcon fontSize="md">
                      <Close />
                    </SvgIcon>
                  }
                  key={item}
                >
                  {item}
                </Chip>
              )
            })
          }
          endDecorator={endDecorator}
        />
      ) : (
        <Autocomplete
          placeholder={!value ? label : ''}
          clearOnEscape
          freeSolo={true}
          options={allOptions}
          multiple={true}
          slots={{
            clearIndicator: () => <></>,
          }}
          disabled={disabled}
          value={value ? [value] : []}
          onBlur={(e) => {
            if (!value && inputValue) {
              setInputValue('')
              onChange?.(inputValue)
            }
            onBlur?.()
          }}
          color={inputColor}
          onKeyDown={(e) => {
            if (e.key === 'Backspace') {
              if (value && !inputValue) {
                setInputValue(value)
                return
              }
            }
          }}
          inputValue={inputValue}
          onInputChange={(e, inputChange) => {
            if (value && !inputValue) {
              setInputValue(value + inputChange)
              onChange?.('')
              return
            }
            setInputValue(inputChange)
          }}
          onChange={(e, newValue) => {
            const v = newValue?.length ? newValue.pop() : undefined
            if (v?.startsWith('@')) {
              onChange?.(getAttributeTag(v.replace('@', '')))
              return
            }
            onChange?.(v)
          }}
          getOptionLabel={(option) => option}
          renderOption={renderOption}
          renderTags={(tags, getTagProps) =>
            tags.map((item, index) => {
              if (
                item.startsWith('@') &&
                attributeNames?.includes(item.slice(1))
              ) {
                return (
                  <Chip
                    variant="outlined"
                    {...getTagProps({ index })}
                    key={item}
                    sx={{ borderRadius: '6px' }}
                    slotProps={{
                      root: {
                        style: {
                          height: '28px',
                        },
                      },
                    }}
                  >
                    <Typography sx={{ color: inputColor }}>
                      <strong>{item}</strong>
                    </Typography>
                  </Chip>
                )
              }

              return (
                <Typography
                  ml={'7.5px'}
                  mr={'-11px'}
                  sx={{ letterSpacing: 'normal' }}
                  key={item}
                  color={inputColor}
                >
                  {item}
                </Typography>
              )
            })
          }
          endDecorator={endDecorator}
        />
      )}

      {errorText || warningText || helperText ? (
        <FormHelperText>
          <Typography
            ml={1}
            sx={{ mt: '-4px' }}
            level="body-xs"
            color={inputColor}
          >
            {errorText || warningText || helperText}
          </Typography>
        </FormHelperText>
      ) : null}
    </FormControl>
  )

  return (
    <Grid container justifyContent="center" spacing={2}>
      {name && (
        <Grid
          item
          xs={xs?.[0] || 12}
          sm={sm?.[0] || 12}
          md={md?.[0] || 3}
          lg={lg?.[0] || 2}
        >
          {nameEditable ? (
            <FloatLabelInput
              value={name}
              onChange={onNameChange}
              onBlur={onNameBlur}
              label={nameLabel}
              placeholder={namePlaceholder}
              type={type}
              size={size}
              fullWidth={fullWidth}
              required={required}
              disabled={disabled || nameEditDisabled}
              readOnly={readOnly}
              errorText={errorText}
              helperText={helperText}
              autoComplete={autoComplete}
              autoCapitalize={autoCapitalize}
              autoCorrect={autoCorrect}
              format={format}
            />
          ) : (
            <Typography level={nameLevel || 'title-md'} sx={{ pt: '0.5em' }}>
              <strong>{name}</strong>
            </Typography>
          )}
        </Grid>
      )}

      <Grid
        item
        xs={name ? xs?.[1] || 12 : 12}
        sm={name ? sm?.[1] || 12 : 12}
        md={name ? md?.[1] || 9 : 12}
        lg={name ? lg?.[1] || 10 : 12}
      >
        {endElement ? (
          <Box sx={{ display: 'flex', width: '100%', overflow: 'auto' }}>
            <Box sx={{ flexGrow: 1 }}>{AutocompleteElement}</Box>
            <Box sx={{ ml: 1 }}>{endElement}</Box>
          </Box>
        ) : (
          AutocompleteElement
        )}
      </Grid>
    </Grid>
  )
}
