import React from 'react'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/joy/Typography'
import MultipleSelect from '../../../components/common/MultipleSelect'

interface ProductTagsProps {
  tags: string[]
  onChange: (tags: string[]) => void
}

export default function ProductTags({
  tags,
  onChange,
}: ProductTagsProps): JSX.Element {
  return (
    <Grid container spacing={0}>
      <Grid item xs={12} md={3} lg={2}>
        <Stack
          direction="row"
          justifyContent="center"
          sx={{
            position: 'relative',
            height: '100%',
            top: 10,
          }}
        >
          <Typography
            sx={{
              mr: '0.5em',
              mb: '0.5em',
            }}
          >
            <strong>Tags</strong>
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12} md={9} lg={10}>
        <MultipleSelect
          value={tags}
          options={tags}
          freeSolo
          placeholder="Tags"
          onChange={(values) => onChange(values)}
        />
      </Grid>
    </Grid>
  )
}
