import Button from '@mui/joy/Button'
import Grid from '@mui/material/Grid'
import React from 'react'
import { GetProduct } from '../../../../api/product'
import Typography from '@mui/joy/Typography'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges'
interface ListUnlistButtonsProps {
  title?: boolean
  list?: boolean
  hasProductIntegration?: boolean
  product?: GetProduct
  onList?: () => void
  onUnlist?: () => void
}

export default function ListUnlistButtons({
  title,
  list,
  hasProductIntegration,
  product,
  onList,
  onUnlist,
}: ListUnlistButtonsProps): JSX.Element {
  if (!product) return <></>

  return (
    <Grid container justifyContent="space-between" spacing={3}>
      {title && (
        <Grid item xs={12}>
          <Typography level="h4">Listing Actions</Typography>
        </Grid>
      )}
      <Grid item>
        <Button
          sx={{ p: 1, width: '200px' }}
          variant="soft"
          color="danger"
          size="sm"
          onClick={onUnlist}
          disabled={!hasProductIntegration}
          endDecorator={<HighlightOffIcon />}
        >
          Unlist
        </Button>
      </Grid>

      <Grid item>
        <Button
          variant="soft"
          size="sm"
          onClick={onList}
          disabled={!list}
          sx={{ p: 1, width: '200px' }}
          endDecorator={<PublishedWithChangesIcon />}
        >
          List / Sync
        </Button>
      </Grid>
    </Grid>
  )
}
