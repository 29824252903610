import instance from '..'
import { APIResult } from '../../utils/types'
import { getResult } from '../../utils/functions'
import {
  EtsyProductIntegration,
  EtsyShopReturnPolicy,
  EtsyShopShippingProfile,
  EtsyTaxonomyNode,
  EtsyTaxonomyProperty,
  EtsyTemplateIntegration,
} from '../../types/Etsy.types'
import { DataFields } from '../types'
import { GetIntegrationResult, IntegrationName } from '../integration'

export async function getEtsyTaxonomy(): Promise<
  APIResult<EtsyTaxonomyNode[]>
> {
  const taxonomy = await instance
    .get('/api/integration/etsy/taxonomy')
    .then((res) => getResult<EtsyTaxonomyNode[]>(res))
  return taxonomy
}

export async function getEtsyShopShippingProfiles(): Promise<
  APIResult<EtsyShopShippingProfile[]>
> {
  const shippingProfiles = await instance
    .get('/api/integration/etsy/shopShippingProfiles')
    .then((res) => getResult<EtsyShopShippingProfile[]>(res))
  return shippingProfiles
}

export async function getEtsyShopReturnPolicies(): Promise<
  APIResult<EtsyShopReturnPolicy[]>
> {
  const shippingProfiles = await instance
    .get('/api/integration/etsy/shopReturnPolicies')
    .then((res) => getResult<EtsyShopReturnPolicy[]>(res))
  return shippingProfiles
}

export async function getEtsyTaxonomyProperties(
  taxonomyId: number,
): Promise<APIResult<EtsyTaxonomyProperty[]>> {
  const taxonomy = await instance
    .get('/api/integration/etsy/taxonomyProperties', {
      params: { taxonomyId },
    })
    .then((res) => getResult<EtsyTaxonomyProperty[]>(res))
  return taxonomy
}

export async function getEtsyTemplateIntegration(
  integrationId: number,
  templateId: number,
): Promise<APIResult<EtsyTemplateIntegration>> {
  const etsyTemplateIntegration = await instance
    .get('/api/integration/etsy/templateIntegration', {
      params: {
        integrationId,
        templateId,
      },
    })
    .then((res) => getResult<EtsyTemplateIntegration>(res))
  return etsyTemplateIntegration
}

export async function getEtsyTemplateIntegrations(
  integrationId: number,
  templateId: number,
): Promise<APIResult<EtsyTemplateIntegration[]>> {
  const etsyTemplateIntegration = await instance
    .get('/api/integration/etsy/templateIntegrations', {
      params: {
        integrationId,
        templateId,
      },
    })
    .then((res) => getResult<EtsyTemplateIntegration[]>(res))
  return etsyTemplateIntegration
}

export async function setEtsyTemplateIntegration(
  etsyTemplateIntegration: Omit<
    DataFields<EtsyTemplateIntegration>,
    'clientId'
  >,
): Promise<APIResult<EtsyTemplateIntegration>> {
  const setEtsyTemplateIntegration = await instance
    .post('/api/integration/etsy/templateIntegration', {
      etsyTemplateIntegration,
    })
    .then((res) => getResult<EtsyTemplateIntegration>(res))
  return setEtsyTemplateIntegration
}

export async function setEtsyTemplateIntegrations(
  etsyTemplateIntegrations: Omit<
    DataFields<EtsyTemplateIntegration>,
    'clientId'
  >[],
): Promise<APIResult<EtsyTemplateIntegration[]>> {
  const setEtsyTemplateIntegrations = await instance
    .post('/api/integration/etsy/templateIntegrations', {
      etsyTemplateIntegrations,
    })
    .then((res) => getResult<EtsyTemplateIntegration[]>(res))
  return setEtsyTemplateIntegrations
}

export async function deleteEtsyTemplateIntegration(
  id: number,
): Promise<APIResult<void>> {
  return await instance
    .delete('/api/integration/etsy/templateIntegration', {
      params: {
        id,
      },
    })
    .then((res) => getResult<void>(res))
}

export async function getEtsyProductIntegration(
  integrationId: number,
  productId: number,
): Promise<APIResult<EtsyProductIntegration>> {
  const etsyProductIntegration = await instance
    .get('/api/integration/etsy/productIntegration', {
      params: {
        integrationId,
        productId,
      },
    })
    .then((res) => getResult<EtsyProductIntegration>(res))
  return etsyProductIntegration
}

export async function getEtsyProductIntegrations(
  integrationId: number,
  productId: number,
): Promise<APIResult<EtsyProductIntegration[]>> {
  const etsyProductIntegrations = await instance
    .get('/api/integration/etsy/productIntegrations', {
      params: {
        integrationId,
        productId,
      },
    })
    .then((res) => getResult<EtsyProductIntegration[]>(res))
  return etsyProductIntegrations
}

export async function getEtsyIntegrations(
  templateId?: number,
  productId?: number,
): Promise<APIResult<GetIntegrationResult<IntegrationName.ETSY>>> {
  const etsyIntegrations = await instance
    .get('/api/integration/etsy/integrations', {
      params: {
        templateId,
        productId,
      },
    })
    .then((res) => getResult<GetIntegrationResult<IntegrationName.ETSY>>(res))
  return etsyIntegrations
}

export async function setEtsyProductIntegration(
  etsyProductIntegration: DataFields<EtsyProductIntegration>,
): Promise<APIResult<EtsyProductIntegration>> {
  const setEtsyProductIntegration = await instance
    .post('/api/integration/etsy/productIntegration', {
      etsyProductIntegration,
    })
    .then((res) => getResult<EtsyProductIntegration>(res))
  return setEtsyProductIntegration
}

export async function setEtsyProductIntegrations(
  etsyProductIntegrations: DataFields<EtsyProductIntegration>[],
): Promise<APIResult<EtsyProductIntegration[]>> {
  const setEtsyProductIntegrations = await instance
    .post('/api/integration/etsy/productIntegrations', {
      etsyProductIntegrations,
    })
    .then((res) => getResult<EtsyProductIntegration[]>(res))
  return setEtsyProductIntegrations
}
