import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  convertProductTemplate,
  createProduct,
  CreateProductInput,
  deleteProduct,
  duplicateProduct,
  GetProduct,
  getProduct,
  GetProductAttribute,
  GetProductTemplate,
  getProductTemplate,
  getProductTemplates,
  ProductInput,
  SetProductAttribute,
  SetProductVariation,
  updateProduct,
} from '../../../api/product'
import Button from '@mui/joy/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Box from '@mui/material/Box'
import Component from '../../../components/common/Component'
import NavBar from '../../../components/common/NavBar'
import Alert, {
  AlertInput,
  handleAlert,
} from '../../../components/common/Alert'
import JoyAlert from '@mui/joy/Alert'
import { ProductAttributeInputText } from './ProductAttributeInputText'
import { ProductAttributeInputSelect } from './ProductAttributeInputSelect'
import Tabs from '../../../components/common/Tabs'
import ImageUpload from '../../../components/common/ImageUpload'
import { ProductConditions, PRODUCT_CONDITIONS } from '../../../types'
import {
  cloudinaryUploadImages,
  copy,
  createSku,
  err,
  log,
  unique,
} from '../../../utils/functions'
import {
  CloudinarySignature,
  getCloudinarySignature,
} from '../../../api/product'
import ProductIntegrations from '../../../components/product/ProductIntegrations'
import ProductSizeWeightInput, {
  SizeWeightChangeEnum,
} from '../../../components/product/ProductSizeWeightInput'
import ProductTags from './ProductTags'
import ProductAttributeInputField from './ProductAttributeInputField'
import {
  extractAttributeNames,
  getProductAttributeValues,
  parseDescription,
  parseTitle,
} from '../../../classes/Parser'
import { ProductDescriptionEditor } from '../ProductDescriptionEditor'
import { STOCK_ATTRIBUTES } from '../../template/CreateTemplate'
import ProductVariationCollapse from './ProductVariationCollapse'
import { ProductTemplateAttribute } from '../../../api/types'
import {
  RequiredIntegrationTemplateAttributes,
  getEbayAttributeAspectValues,
  getIntegrationRequiredAttributes,
  getPriceSuggestion,
  setEbayPriceSuggestionConfiguration,
} from '../../../api/integrations/ebay'
import {
  EbayAttributeAspectValues,
  PriceSuggestion,
} from '../../../types/Ebay.types'
import EbayPriceSuggestion from '../../../components/product/integrations/ebay/EbayPriceSuggestion'
import Tooltip from '@mui/joy/Tooltip'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Checkbox from '@mui/material/Checkbox'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { ProductListingsTable } from '../../../components/product/ProductListingsTable'
import AccordionGroup from '@mui/joy/AccordionGroup'
import Add from '@mui/icons-material/Add'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import SaveIcon from '@mui/icons-material/Save'
import PublishIcon from '@mui/icons-material/Publish'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import IconButton from '@mui/joy/IconButton'
import SellIcon from '@mui/icons-material/Sell'
import Modal from '@mui/joy/Modal'
import ModalClose from '@mui/joy/ModalClose'
import ModalDialog from '@mui/joy/ModalDialog'
import VisibilityIcon from '@mui/icons-material/Visibility'
import EditIcon from '@mui/icons-material/Edit'
import CachedIcon from '@mui/icons-material/Cached'
import SingleSelect from '../../../components/common/SingleSelect'
import {
  getChannelsProductIntegrations,
  ChannelsProductIntegrations,
  IntegrationDisplayName,
  IntegrationName,
  listToChannels,
  ListToChannelsResponse,
  setChannelsProductIntegrations,
} from '../../../api/integration'
import ButtonGroup from '@mui/joy/ButtonGroup'
import CircularProgress from '@mui/joy/CircularProgress'

import WarningIcon from '@mui/icons-material/Warning'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

import ProductListingSettings from './ProductListingSettings'
import { Sheet } from '@mui/joy'
interface ProductProps {
  newProduct?: boolean
}

const DEFAULT_CONDITION: ProductConditions = 'New'

export default function Product({ newProduct }: ProductProps): JSX.Element {
  const { productId: productIdParam, tab } = useParams<{
    productId?: string
    tab?: string
  }>()
  const { templateId: templateIdParameter } = useParams<{
    templateId?: string
  }>()

  const history = useHistory()

  const setSelectedTab = (tab: string) => {
    history.push(`/product/${productId}/${tab.toLowerCase()}`, {
      shallow: true,
    })
  }

  const [alert, setAlert] = useState<AlertInput>({ open: false })
  const [listToChannelsResponse, setListToChannelsResponse] =
    useState<ListToChannelsResponse>()

  const isMounted = useRef(true)
  const [attributes, setAttributes] = useState<SetProductAttribute[]>([])

  const editableAttributes = attributes.filter(
    (a) => !STOCK_ATTRIBUTES.includes(a.templateAttribute.name),
  )

  const productId = productIdParam ? parseInt(productIdParam) : undefined
  const templateIdParam = templateIdParameter
    ? parseInt(templateIdParameter)
    : undefined

  const [requiredAttributes, setRequiredAttributes] = useState<
    RequiredIntegrationTemplateAttributes[]
  >([])
  const [parseDetails, setParseDetails] = useState<boolean>(false)
  const [richText, setRichText] = useState<boolean>(true)
  const [parsedTitle, setParsedTitle] = useState<string>('')
  const [parsedDescription, setParsedDescription] = useState<string>('')

  const [loading, setLoading] = useState<boolean>(true)
  const [listLoading, setListLoading] = useState<boolean>(false)

  // Product Type Attributes
  const [getProductValue, setGetProductValue] = useState<GetProduct>()
  const [productTemplate, setProductTemplate] = useState<GetProductTemplate>()
  const [productTemplates, setProductTemplates] = useState<
    GetProductTemplate[]
  >([])
  const [title, setTitle] = useState<string | undefined>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [titleBlurred, setTitleBlurred] = useState<boolean>(true)
  const [description, setDescription] = useState<string | undefined>()
  const [tags, setTags] = useState<string[]>([])

  const [weightUnit, setWeightUnit] = useState<string>('')
  const [sizeUnit, setSizeUnit] = useState<string>('')

  // Product Specifics
  const [condition, setCondition] =
    useState<ProductConditions>(DEFAULT_CONDITION)

  const [productImages, setProductImages] = useState<string[]>([])
  const [cost, setCost] = useState<string>('0')
  const [price, setPrice] = useState<string>('0')
  const [msrp, setMsrp] = useState<string>('0')
  const [sku, setSku] = useState<string>(createSku())
  const [notes, setNotes] = useState<string>('')
  const [quantity, setQuantity] = useState<string>('0')
  const [sold, setSold] = useState<string>('0')
  const [weight, setWeight] = useState<string>('0')
  const [width, setWidth] = useState<string>('0')
  const [length, setLength] = useState<string>('0')
  const [height, setHeight] = useState<string>('0')

  const [vendor, setVendor] = useState<string>('')
  const [warehouse, setWarehouse] = useState<string>('')
  const [location, setLocation] = useState<string>('')
  const [bin, setBin] = useState<string>('')

  const [attributeAspectValues, setAttributeAspectValues] = useState<
    EbayAttributeAspectValues | undefined
  >()

  const [priceSuggestion, setPriceSuggestion] = useState<
    PriceSuggestion | undefined
  >(undefined)

  const [priceSuggestionTitle, setPriceSuggestionTitle] = useState<
    string | undefined
  >()

  const [
    selectedPriceSuggestionAttributes,
    setSelectedPriceSuggestionAttributes,
  ] = useState<string[]>([])
  const [priceSuggestionModelOpen, setPriceSuggestionModelOpen] =
    useState<boolean>(false)

  const [convertTemplateModelOpen, setConvertTemplateModelOpen] =
    useState<boolean>(false)

  const [selectedConvertTemplateId, setSelectedConvertTemplateId] = useState<
    number | undefined
  >(undefined)

  const [variations, setVariations] = useState<SetProductVariation[]>([])
  const [templateVariations, setTemplateVariations] = useState<
    ProductTemplateAttribute[]
  >([])

  // cloudinary
  const [selectedImages, setSelectedImages] = useState<File[]>([])
  const [cloudinarySignature, setCloudinarySignature] = useState<
    CloudinarySignature | undefined
  >()

  const [productAttributeNames, setProductAttributeNames] = useState<string[]>(
    [],
  )

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [channelProductIntegrations, setChannelsProductIntegrationsState] =
    useState<ChannelsProductIntegrations | undefined>(undefined)

  useEffect(() => {
    if (!productId) return

    getChannelsProductIntegrations(productId)
      .then((res) => {
        if (res.success && res.data) {
          setChannelsProductIntegrationsState(res.data)
        }
      })
      .catch((e) => err(e))
  }, [productId])

  useEffect(() => {
    if (getProductValue) {
      const attributeValues = getProductAttributeValues(getProductValue)
      const attributeNames = attributeValues.map((a) => a.name)
      setProductAttributeNames(attributeNames)
    } else if (attributes) {
      const attributeNames: string[] = attributes.map(
        (a) => a.templateAttribute.name,
      )
      setProductAttributeNames(attributeNames)
    }
  }, [attributes, getProductValue])

  const handleSavePricingAspects = () => {
    if (!getProductValue?.template.id) return
    setEbayPriceSuggestionConfiguration(
      getProductValue.template.id,
      priceSuggestionTitle,
      selectedPriceSuggestionAttributes,
    )
      .then((res) => {
        handleAlert(setAlert, res, 'Saved Pricing Aspects to Template')
      })
      .catch((e) => err(e))
  }

  const handleFetchPriceSuggestion = () => {
    if (!getProductValue) return
    getPriceSuggestion(
      getProductValue.product.id,
      priceSuggestionTitle,
      selectedPriceSuggestionAttributes,
    )
      .then((res) => {
        if (res.success && res.data) {
          setPriceSuggestion(res.data)
          setPriceSuggestionTitle(res.data.title)

          // set selected attributes
          setSelectedPriceSuggestionAttributes(res.data.filterAspects)
        }
        handleAlert(setAlert, res, 'Updated Price Suggestions')
      })
      .catch((e) => {
        err(e)
      })
  }

  useEffect(() => {
    if (!convertTemplateModelOpen) {
      return
    }
    getProductTemplates()
      .then((res) => {
        if (res.success && res.data) {
          setProductTemplates(res.data)
        }
      })
      .catch((e) => err(e))
  }, [convertTemplateModelOpen])

  useEffect(() => {
    getCloudinarySignature()
      .then((res) => {
        if (res.success && res.data) {
          setCloudinarySignature(res.data)
        } else {
          log(res.message)
        }
      })
      .catch((e) => err(e))
  }, [])

  useEffect(() => {
    const templateId = getProductValue?.template.id || templateIdParam
    if (!templateId) return
    getEbayAttributeAspectValues(templateId)
      .then((res) => {
        if (res.success && res.data) {
          setAttributeAspectValues(res.data)
        }
      })
      .catch((e) => err(e))
  }, [getProductValue?.template.id, templateIdParam])

  useEffect(() => {
    if (!getProductValue) return
    getPriceSuggestion(getProductValue.product.id)
      .then((res) => {
        if (res.data) {
          setPriceSuggestion(res.data)
          setPriceSuggestionTitle(res.data.title)
          setSelectedPriceSuggestionAttributes(res.data.filterAspects)
        }
      })
      .catch((e) => {
        err(e)
      })
  }, [getProductValue])

  const handleSelectPriceSuggestionAttribute = (attributeName: string) => {
    const selected = copy(selectedPriceSuggestionAttributes)
    const index = selected.indexOf(attributeName)
    if (index !== -1) {
      selected.splice(index, 1)
    } else {
      selected.push(attributeName)
    }
    setSelectedPriceSuggestionAttributes(selected)
  }

  const handleSelectAllPriceSuggestionAttribute = () => {
    if (
      getProductValue?.attributes.length ===
      selectedPriceSuggestionAttributes.length
    ) {
      setSelectedPriceSuggestionAttributes([])
    } else {
      const allAttributeNames =
        getProductValue?.attributes.map((a) => a.templateAttribute.name) || []
      setSelectedPriceSuggestionAttributes(allAttributeNames)
    }
  }

  const handleParseDetailsToggle = () => {
    if (productTemplate && !getProductValue) {
      const productValue: GetProduct = {
        product: {
          id: 0,
          templateId: productTemplate.template.id,

          title,
          description,
          condition,
          sku,
          notes,
          tags: tags.join(','),
          cost: parseFloat(cost),
          price: parseFloat(price),
          msrp: parseFloat(msrp),

          quantity: parseInt(quantity),
          sold: parseInt(sold),

          weightUnit,
          sizeUnit,

          weight: parseFloat(weight),
          width: parseFloat(width),
          length: parseFloat(length),
          height: parseFloat(height),
        },
        template: productTemplate.template,
        attributes: attributes as GetProductAttribute[],
        images: [],
        variations: [],
      }

      setParseDetails(!parseDetails)
      setRichText(!richText)
      setParsedTitle(parseTitle(title || '', productValue))
      setParsedDescription(parseDescription(description || '', productValue))
      return
    }

    if (!getProductValue) return
    setParseDetails(!parseDetails)
    setRichText(!richText)
    setParsedTitle(parseTitle(title || '', getProductValue))
    setParsedDescription(parseDescription(description || '', getProductValue))
  }

  const handleAttributeChange = (name: string, value: string, i?: number) => {
    const newAttributes = attributes.slice()
    const existingAttribute = newAttributes.find(
      (a) => a.templateAttribute.name === name,
    )

    // add
    if (existingAttribute) {
      // update
      const index = newAttributes.findIndex(
        (a) => a.templateAttribute.name === name,
      )
      existingAttribute.attribute = {
        value,
        templateAttributeId: existingAttribute.templateAttribute.id,
      }
      newAttributes[index] = existingAttribute
    } else {
      newAttributes.push()
    }

    setAttributes(newAttributes)
  }

  const handleSelectNextInput = (i: number, next?: boolean) => {
    // get next element
    if (i !== undefined) {
      const nextIndex = next === false ? i - 1 : i + 1
      const el = document.getElementById(`attribute-${nextIndex}`)
      el?.focus()
    }
  }

  const handleSetPriceSuggestion = () => {
    setPrice(
      `${
        priceSuggestion?.graphAverage ||
        priceSuggestion?.selectionAverage ||
        parseFloat(price)
      }`,
    )
    setPriceSuggestionModelOpen(false)
  }

  const handleDelete = async () => {
    if (!productId) return
    const res = await deleteProduct(productId)
    if (res.data) {
      history.push('/products')
    }
  }

  const handleDuplicateProduct = async () => {
    if (!productId) return
    const res = await duplicateProduct(productId)
    if (res.data) {
      history.push('/products')
    }
  }

  const handleConvertProductTemplate = async () => {
    if (!selectedConvertTemplateId) {
      setConvertTemplateModelOpen(true)
    }
    if (!productId || !selectedConvertTemplateId) return
    const res = await convertProductTemplate(
      productId,
      selectedConvertTemplateId,
    )
    if (res.data) {
      history.push(`/products/${res.data}`)
    }
  }

  const handleCloseListToChannelResult = (channel: IntegrationName) => {
    const newListToChannelsResponse = copy(listToChannelsResponse)
    if (newListToChannelsResponse?.[channel]) {
      delete newListToChannelsResponse[channel]
    }
    console.log(newListToChannelsResponse)
    setListToChannelsResponse(newListToChannelsResponse)
  }

  const handleSave = async () => {
    setLoading(true)
    // only save attributes with values
    const newAttributes: SetProductAttribute[] = attributes.slice()

    let updatedProductId = productId
    if (!newProduct && productId && productTemplate) {
      const productInput: ProductInput = {
        id: productId,
        templateId: productTemplate.template.id,
        title,
        description,
        sku,
        notes,
        tags: tags.join(','),

        condition,
        cost: parseFloat(cost),
        price: parseFloat(price),
        msrp: parseFloat(msrp),

        quantity: parseInt(quantity),
        sold: parseInt(sold),

        weight: parseFloat(weight),
        width: parseFloat(width),
        length: parseFloat(length),
        height: parseFloat(height),
        weightUnit,
        sizeUnit,
      }

      // if (variations.length) {
      //   console.log(variations)
      //   return
      // }

      if (!cloudinarySignature) return
      // Handle product images
      const uploadedImages = await cloudinaryUploadImages(
        cloudinarySignature,
        productImages,
        selectedImages,
      )

      // Handles variation images
      const updatedVariations = await Promise.all(
        variations.map(async (variation): Promise<SetProductVariation> => {
          if (variation.selectedImages?.length) {
            // if selected images, upload and update images array
            const uploadedImages = await cloudinaryUploadImages(
              cloudinarySignature,
              variation.images.map((img) => img.url),
              variation.selectedImages,
            )

            return {
              ...variation,
              images: uploadedImages || [],
              selectedImages: undefined, // remove field
            }
          }

          // return self, variation is already ready to upload
          return variation
        }),
      )

      const res = await updateProduct(
        productInput,
        newAttributes,
        uploadedImages || [],
        updatedVariations || [],
      )

      handleAlert(setAlert, res, 'Product saved')
    } else if (newProduct && productTemplate) {
      const product: CreateProductInput = {
        templateId: productTemplate.template.id,

        title,
        description,
        condition,
        sku,
        notes,
        tags: tags.join(','),
        cost: parseFloat(cost),
        price: parseFloat(price),
        msrp: parseFloat(msrp),

        quantity: parseInt(quantity),
        sold: parseInt(sold),

        weightUnit,
        sizeUnit,

        weight: parseFloat(weight),
        width: parseFloat(width),
        length: parseFloat(length),
        height: parseFloat(height),
      }

      if (!cloudinarySignature) return
      // Handle product images
      const uploadedImages = await cloudinaryUploadImages(
        cloudinarySignature,
        productImages,
        selectedImages,
      )

      const res = await createProduct(product, newAttributes, uploadedImages)
      if (res.success && res.data) {
        updatedProductId = res.data.product.id
        handleAlert(setAlert, res, 'Product created')
        setGetProductValue(res.data)
        history.push(`/product/${res.data.product.id}`)
      }
    }

    if (updatedProductId && channelProductIntegrations) {
      await setChannelsProductIntegrations(
        channelProductIntegrations,
        updatedProductId,
      )
    }
    setLoading(false)
  }

  const handleList = async () => {
    await handleSave()
    setListLoading(true)
    setAlert({
      open: true,
      severity: 'success',
      message: 'Listing to channels...',
    })
    if (!productId) return
    listToChannels(productId)
      .then((res) => {
        setListLoading(false)
        if (res.success && res.data) {
          setListToChannelsResponse(res.data)
        } else {
          setAlert({
            open: true,
            severity: 'error',
            message: 'Encountered Error listing to channels',
          })
        }
      })
      .catch((e) => err(e))
    // no-op
  }

  const handleViewInventory = () => {
    history.push('/products')
  }

  const handleViewTemplate = () => {
    const templateId = getProductValue?.template.id || templateIdParam
    if (templateId) history.push(`/template/${templateId}`)
  }

  const handleSizeWeightChange = (
    name: SizeWeightChangeEnum,
    value: string | number | undefined,
  ) => {
    switch (name) {
      case SizeWeightChangeEnum.WEIGHT_UNIT:
        setWeightUnit(value + '')
        break
      case SizeWeightChangeEnum.SIZE_UNIT:
        setSizeUnit(value + '')
        break
      case SizeWeightChangeEnum.WEIGHT:
        setWeight(value + '')
        break
      case SizeWeightChangeEnum.L:
        setLength(value + '')
        break
      case SizeWeightChangeEnum.W:
        setWidth(value + '')
        break
      case SizeWeightChangeEnum.H:
        setHeight(value + '')
        break
      default:
        console.log('Not found.')
    }
  }

  const handleAddVariation = () => {
    const index = variations.length + 1
    const newVariation: SetProductVariation = {
      variation: {
        name: 'Variation ' + index,
        sku: sku + '-' + index,
      },
      attributes: [],
      images: [],
    }

    setVariations(variations.concat([newVariation]))
  }

  const handleVariationDelete = (name: string) => {
    const index = variations.findIndex(
      (variation) => variation.variation.name === name,
    )

    const newVariations = variations.slice()
    if (index !== -1) {
      newVariations.splice(index, 1)
      setVariations(newVariations)
    }
  }

  const handleVariationChange = (variation: SetProductVariation) => {
    // add or update variation
    let newVariations = variations.slice()
    const index = newVariations.findIndex(
      (existingVariation) =>
        existingVariation.variation.name === variation.variation.name,
    )
    // if no match, ignore.
    if (index < 0) {
      newVariations.push(variation) // add
    } else {
      // Update index
      const updatedIndex = variation.variation.index
      if (
        updatedIndex !== undefined &&
        index !== updatedIndex &&
        updatedIndex > -1 &&
        updatedIndex < newVariations.length
      ) {
        const tempVariation = newVariations[updatedIndex]
        newVariations[updatedIndex] = variation
        if (tempVariation) newVariations[index] = tempVariation

        // re-index
        newVariations = newVariations.map((v, i) => ({
          ...v,
          variation: { ...v.variation, index: i },
        }))
      } else {
        newVariations[index] = {
          ...variation,
          variation: { ...variation.variation, index: index },
        }
      }
    }
    setVariations(newVariations)
  }

  /* Get Product Types */
  useEffect(() => {
    if (isMounted.current) {
      if (productId && !newProduct) {
        setLoading(true)
        getProduct(productId)
          .then((res) => {
            setLoading(false)
            if (res.success && res.data) {
              setGetProductValue(res.data)
              const {
                product,
                attributes: getAttributes,
                images,
                variations,
              } = res.data

              if (!product) return

              setVariations(variations)

              const productTemplateId = product.templateId
              if (!productTemplateId) return
              getProductTemplate(productTemplateId)
                .then((res) => {
                  if (res.success) {
                    const productTemplate = res.data
                    const template = productTemplate?.template
                    setProductTemplate(productTemplate)

                    if (!template) return

                    setAttributes(getAttributes as SetProductAttribute[])
                    setTitle(product.title || template.title || '')
                    setDescription(
                      product.description || template.description || '',
                    )
                    setTags(product.tags ? product.tags?.split(',') : [])

                    setCondition(
                      product.condition
                        ? (product.condition as ProductConditions)
                        : DEFAULT_CONDITION,
                    )
                    setCost(`${product.cost || template.cost || 0}`)
                    setPrice(`${product.price || template.price || 0}`)
                    setMsrp(`${product.msrp || template.msrp || 0}`)
                    setWeightUnit(
                      product.weightUnit || template.weightUnit || '',
                    )
                    setSizeUnit(product.sizeUnit || template.sizeUnit || '')
                    setProductImages(images.map((i) => i.url))
                    setSku(product.sku || '')
                    setNotes(product.notes || '')
                    setQuantity(product.quantity + '')
                    setSold(product.sold + '')
                    setWeight(`${product.weight || template.weight || 0}`)
                    setWidth(`${product.width || template.width || 0}`)
                    setLength(`${product.length || template.length || 0}`)
                    setHeight(`${product.height || template.height || 0}`)

                    setVendor(product.vendor || '')
                    setWarehouse(product.wharehouse || '')
                    setLocation(product.location || '')
                    setBin(product.bin || '')

                    setTemplateVariations(productTemplate.variations || [])
                  } else {
                    return
                  }
                })
                .catch((e) => err(e))
            } else {
              log(res.message)
            }
          })
          .catch((e) => {
            setLoading(false)
            err(e)
          })
      } else if (newProduct && templateIdParam) {
        setLoading(true)
        getProductTemplate(templateIdParam)
          .then((res) => {
            setLoading(false)
            if (res.success) {
              const productTemplate = res.data
              const template = productTemplate?.template
              setProductTemplate(productTemplate)

              if (!template) return

              const getAttributes: GetProductAttribute[] =
                productTemplate.attributes.map((a) => ({
                  attribute: undefined,
                  templateAttribute: a.attribute,
                  templateAttributeOptions: a.attributeOptions,
                }))

              setAttributes(getAttributes as SetProductAttribute[])
              setTitle(template.title || '')
              setDescription(template.description || '')
              setCost(`${template.cost || 0}`)
              setPrice(`${template.price || 0}`)
              setMsrp(`${template.msrp || 0}`)
              setWeightUnit(template.weightUnit || '')
              setSizeUnit(template.sizeUnit || '')
            } else {
              return
            }
          })
          .catch((e) => err(e))
      }

      return () => {
        isMounted.current = false
      }
    }
  }, [newProduct, productId, templateIdParam])

  useEffect(() => {
    if (!productTemplate) return
    getIntegrationRequiredAttributes(productTemplate.template.id)
      .then((res) => {
        if (res.success && res.data) {
          setRequiredAttributes(res.data)
        } else {
          console.log(res.message)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }, [productTemplate])

  useEffect(() => {
    const requiredFromTitle = extractAttributeNames(title || '')
    const requiredFromDescription = extractAttributeNames(description || '')

    const requiredFromInput = unique(
      requiredFromTitle.concat(requiredFromDescription),
    ).map(
      (attributeName): RequiredIntegrationTemplateAttributes => ({
        templateAttributeNames: [attributeName],
      }),
    )

    const allRequired = requiredAttributes.concat(requiredFromInput)
    setRequiredAttributes(allRequired)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description, title])

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <NavBar />
      <Container sx={{ mt: 4 }}>
        <Component>
          <Grid container alignItems="center" sx={{ position: 'relative' }}>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Button
                    onClick={handleViewInventory}
                    variant="solid"
                    size="sm"
                  >
                    <ArrowBackIcon
                      fontSize="small"
                      sx={{ paddingRight: '0.25em' }}
                    />
                    Products
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={handleViewTemplate}
                    variant="solid"
                    size="sm"
                  >
                    View Template
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} pt={1} pb={3}>
              <Typography level="h3">Product</Typography>
              <Typography level="body-md">
                Template:{' '}
                {getProductValue?.template.name ||
                  productTemplate?.template.name}
              </Typography>
            </Grid>
          </Grid>
          {getProductValue || newProduct ? (
            <Grid container>
              <Grid item xs={12}>
                <Tabs
                  tabs={
                    !newProduct
                      ? ['product', 'channels', 'listings']
                      : ['product']
                  }
                  selected={tab}
                  onSelect={setSelectedTab}
                  panels={[
                    <Grid key={0} container spacing={8} justifyContent="center">
                      <Grid item xs={12}>
                        <Grid container justifyContent="center" spacing={2}>
                          <Grid item xs={12}>
                            <ImageUpload
                              id="product-image-upload"
                              productId={productId}
                              images={productImages}
                              onChange={setProductImages}
                              selectedImages={selectedImages}
                              onSelect={setSelectedImages}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <ProductAttributeInputText
                              label="SKU"
                              value={sku}
                              onChange={(sku) => setSku(sku)}
                              required
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Grid
                              container
                              justifyContent="flex-end"
                              spacing={0}
                              sx={{ mb: '4px' }}
                            >
                              <Grid item>
                                {/* <ParseProductDetailsToggle
                                placement="start"
                                toggled={parseDetails}
                                onChange={handleParseDetailsToggle}
                              /> */}

                                <Button
                                  size="sm"
                                  variant="plain"
                                  color="neutral"
                                  onClick={handleParseDetailsToggle}
                                  endDecorator={
                                    parseDetails ? (
                                      <EditIcon fontSize="small" />
                                    ) : (
                                      <VisibilityIcon fontSize="small" />
                                    )
                                  }
                                >
                                  <Typography level="body-sm">
                                    {parseDetails ? 'Edit' : 'Preview'}
                                  </Typography>
                                </Button>
                              </Grid>
                              {/* <Grid item sx={{ mr: 2 }}>
                              <RichTextToggle
                                placement="start"
                                toggled={richText}
                                onChange={handleSetRichText}
                              />
                            </Grid> */}
                            </Grid>
                            {richText && title !== undefined ? (
                              <ProductDescriptionEditor
                                title="Title"
                                oneLine={true}
                                richText={richText}
                                attributeNames={productAttributeNames}
                                value={parseDetails ? parsedTitle : title}
                                required
                                onChange={(description) =>
                                  setTitle(description)
                                }
                                onUpdate={(description) =>
                                  setTitle(description)
                                }
                                disabled={parseDetails}
                              />
                            ) : (
                              <ProductAttributeInputText
                                label="Title"
                                required
                                value={parseDetails ? parsedTitle : title}
                                disabled={parseDetails}
                                onChange={(title) => setTitle(title)}
                                onFocus={() => setTitleBlurred(false)}
                                onBlur={() => setTitleBlurred(true)}
                              />
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            {description !== undefined && (
                              <ProductDescriptionEditor
                                title="Description"
                                richText={richText}
                                attributeNames={productAttributeNames}
                                value={
                                  parseDetails ? parsedDescription : description
                                }
                                required
                                onChange={(description) =>
                                  setDescription(description)
                                }
                                onUpdate={(description) =>
                                  setTitle(description)
                                }
                                disabled={parseDetails}
                              />
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <ProductAttributeInputSelect
                                  label="Condition"
                                  value={condition}
                                  options={PRODUCT_CONDITIONS}
                                  required
                                  onChange={(condition) =>
                                    setCondition(condition as ProductConditions)
                                  }
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <ProductTags tags={tags} onChange={setTags} />
                              </Grid>

                              <Grid item xs={12}>
                                <ProductAttributeInputText
                                  label="Notes"
                                  value={notes}
                                  onChange={(notes) => setNotes(notes)}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <ProductAttributeInputText
                                  type="number"
                                  label="Quantity"
                                  required
                                  value={quantity}
                                  onChange={(quantity) => setQuantity(quantity)}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <ProductAttributeInputText
                                  type="number"
                                  label="Sold"
                                  value={sold}
                                  onChange={(sold) => setSold(sold)}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <ProductAttributeInputText
                              type="number"
                              label="Cost"
                              value={cost}
                              onChange={(cost) => setCost(cost)}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Grid
                              container
                              justifyContent="flex-end"
                              spacing={1}
                            >
                              <Grid item xs={12}>
                                <ProductAttributeInputText
                                  type="number"
                                  label="Price"
                                  format="USD"
                                  required
                                  value={price}
                                  onChange={(price) => setPrice(price)}
                                  endDecorator={
                                    productId &&
                                    (priceSuggestion?.graphAverage ||
                                      priceSuggestion?.selectionAverage) ? (
                                      <Button
                                        size="sm"
                                        variant="plain"
                                        onClick={() => {
                                          setPriceSuggestionModelOpen(true)
                                        }}
                                        endDecorator={
                                          <SellIcon
                                            fontSize="small"
                                            sx={{ fontSize: '16px' }}
                                          />
                                        }
                                      >
                                        Suggested:{' $'}
                                        {priceSuggestion?.graphAverage ||
                                          priceSuggestion?.selectionAverage}
                                      </Button>
                                    ) : null
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <ProductAttributeInputText
                              type="number"
                              label="MSRP"
                              value={msrp}
                              onChange={(msrp) => setMsrp(msrp)}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <ProductSizeWeightInput
                              width={width}
                              length={length}
                              height={height}
                              weight={weight}
                              sizeUnit={sizeUnit}
                              weightUnit={weightUnit}
                              onChange={handleSizeWeightChange}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <ProductAttributeInputText
                                  label="Vendor"
                                  value={vendor}
                                  onChange={(vendor) => setVendor(vendor)}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <ProductAttributeInputText
                                  label="Warehouse"
                                  value={warehouse}
                                  onChange={(warehouse) =>
                                    setWarehouse(warehouse)
                                  }
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <ProductAttributeInputText
                                  label="Location"
                                  value={location}
                                  onChange={(location) => setLocation(location)}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <ProductAttributeInputText
                                  label="Bin"
                                  value={bin}
                                  onChange={(bin) => setBin(bin)}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                            <Typography level="h4">Attributes</Typography>
                          </Grid>
                          {/* <Grid item xs={12}>
                            <ProductAttributeInputField
                              name="Condition Description"
                              attributes={attributes}
                              requiredAttributes={requiredAttributes}
                              onChange={handleAttributeChange}
                            />
                          </Grid> */}
                          <Grid item xs={12}>
                            <ProductAttributeInputField
                              name="Brand"
                              id={`attribute-0`}
                              attributes={attributes}
                              requiredAttributes={requiredAttributes}
                              onChange={handleAttributeChange}
                              options={attributeAspectValues?.['Brand']}
                              onSelection={(next) =>
                                handleSelectNextInput(0, next)
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <ProductAttributeInputField
                              name="Category"
                              id={`attribute-1`}
                              attributes={attributes}
                              requiredAttributes={requiredAttributes}
                              options={attributeAspectValues?.['Category']}
                              onChange={handleAttributeChange}
                              onSelection={(next) =>
                                handleSelectNextInput(1, next)
                              }
                            />
                          </Grid>
                          {editableAttributes.length ? (
                            editableAttributes
                              .sort(
                                (a, b) =>
                                  a.templateAttribute.index -
                                  b.templateAttribute.index,
                              )
                              .map((attribute, i) => (
                                <Grid
                                  item
                                  xs={12}
                                  key={`${attribute.templateAttribute.name}-${i}`}
                                >
                                  <ProductAttributeInputField
                                    id={`attribute-${i + 2}`}
                                    name={attribute.templateAttribute.name}
                                    attributes={attributes}
                                    options={
                                      attributeAspectValues?.[
                                        attribute.templateAttribute.name
                                      ]
                                    }
                                    requiredAttributes={requiredAttributes}
                                    onChange={(name, value) => {
                                      handleAttributeChange(name, value, i)
                                    }}
                                    onSelection={(next) => {
                                      console.log(next)
                                      handleSelectNextInput(i + 2, next)
                                    }}
                                    onUpdate={() => console.log('Update')}
                                  />
                                </Grid>
                              ))
                          ) : (
                            <Grid item xs={12}></Grid>
                          )}
                        </Grid>
                      </Grid>

                      {productTemplate?.variations?.length &&
                      getProductValue ? (
                        <Grid item xs={12}>
                          <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={12} sx={{ mt: 2, mb: 1 }}>
                              <Grid container justifyContent="center">
                                <Typography
                                  level="h4"
                                  endDecorator={
                                    <IconButton
                                      color="neutral"
                                      sx={{
                                        opacity: 0.5,
                                        borderRadius: '50px',
                                      }}
                                    >
                                      <Add onClick={handleAddVariation}>
                                        Add Variation
                                      </Add>
                                    </IconButton>
                                  }
                                >
                                  Variations
                                </Typography>
                              </Grid>
                            </Grid>
                            {/* TODO Add */}
                            {variations.length ? (
                              <Grid item xs={12}>
                                <AccordionGroup
                                  variant="outlined"
                                  sx={{ borderBottom: 'none' }}
                                >
                                  {variations.map((variation) => (
                                    <ProductVariationCollapse
                                      key={variation.variation.name}
                                      product={getProductValue}
                                      productId={productId}
                                      variation={variation}
                                      templateVariationAttributes={
                                        templateVariations
                                      }
                                      attributes={attributes}
                                      requiredAttributes={requiredAttributes}
                                      onChange={handleVariationChange}
                                      onDelete={handleVariationDelete}
                                    />
                                  ))}
                                </AccordionGroup>
                              </Grid>
                            ) : null}
                          </Grid>
                        </Grid>
                      ) : null}

                      {productId && channelProductIntegrations ? (
                        <Grid item xs={12} mt={6} mb={6}>
                          <ProductListingSettings
                            productId={productId}
                            channelsProductIntegrations={
                              channelProductIntegrations
                            }
                            onChange={setChannelsProductIntegrationsState}
                          />
                        </Grid>
                      ) : null}

                      {listToChannelsResponse &&
                      Object.keys(listToChannelsResponse)?.length ? (
                        <Grid item xs={12}>
                          <Typography p={2} level="h4">
                            Listing Results
                          </Typography>
                          <Sheet
                            variant="outlined"
                            sx={{ borderRadius: '6px' }}
                          >
                            <Grid
                              container
                              justifyContent="center"
                              spacing={2}
                              p={2}
                            >
                              {Object.entries(listToChannelsResponse).map(
                                ([channel, res]) => {
                                  if (!res) return null
                                  const color =
                                    res?.status === 'fulfilled'
                                      ? 'success'
                                      : 'danger'
                                  const icon = res?.status ? (
                                    <CheckCircleIcon />
                                  ) : (
                                    <WarningIcon />
                                  )
                                  return (
                                    <Grid item xs={12} key={channel}>
                                      <JoyAlert
                                        size="md"
                                        color={color}
                                        sx={{ alignItems: 'flex-start' }}
                                        startDecorator={icon}
                                        variant="soft"
                                        endDecorator={
                                          <IconButton
                                            variant="soft"
                                            color={color}
                                            onClick={() =>
                                              handleCloseListToChannelResult(
                                                channel as IntegrationName,
                                              )
                                            }
                                          >
                                            <CloseRoundedIcon />
                                          </IconButton>
                                        }
                                      >
                                        <div style={{ color: 'inherit' }}>
                                          <Typography
                                            level="title-md"
                                            textAlign="left"
                                            sx={{ color: 'inherit' }}
                                          >
                                            <strong>
                                              {IntegrationDisplayName[
                                                channel as IntegrationName
                                              ] || channel}
                                            </strong>
                                          </Typography>
                                          <Typography
                                            level="body-sm"
                                            color={color}
                                          >
                                            {res?.reason
                                              ? res.reason
                                              : 'Listed Successfully'}
                                          </Typography>
                                        </div>
                                      </JoyAlert>
                                    </Grid>
                                  )
                                },
                              )}
                            </Grid>
                          </Sheet>
                        </Grid>
                      ) : null}

                      <Grid item xs={12}>
                        {!newProduct ? (
                          <Box mt={2} py={2}>
                            <Grid container justifyContent="center" spacing={2}>
                              <Grid item xs={12}>
                                <Grid
                                  container
                                  justifyContent="space-between"
                                  spacing={2}
                                >
                                  <Grid item>
                                    <Button
                                      variant="solid"
                                      onClick={handleDelete}
                                      color="danger"
                                      endDecorator={
                                        <DeleteOutlineIcon fontSize="small" />
                                      }
                                    >
                                      Delete
                                    </Button>
                                  </Grid>
                                  <Grid item>
                                    <Tooltip title="Convert Template">
                                      <Button
                                        variant="soft"
                                        onClick={handleConvertProductTemplate}
                                        endDecorator={
                                          <CachedIcon fontSize="small" />
                                        }
                                      >
                                        Convert
                                      </Button>
                                    </Tooltip>
                                  </Grid>
                                  <Grid item>
                                    <Button
                                      variant="soft"
                                      onClick={handleDuplicateProduct}
                                      endDecorator={
                                        <ContentCopyIcon fontSize="small" />
                                      }
                                    >
                                      Duplicate
                                    </Button>
                                  </Grid>

                                  <Grid item>
                                    <ButtonGroup color="primary">
                                      <Tooltip
                                        size="sm"
                                        enterDelay={1000}
                                        arrow
                                        title="Save product details"
                                      >
                                        <Button
                                          variant="soft"
                                          onClick={handleSave}
                                          disabled={loading}
                                          endDecorator={
                                            <SaveIcon fontSize="small" />
                                          }
                                          loading={loading}
                                        >
                                          Save
                                        </Button>
                                      </Tooltip>
                                      <Tooltip
                                        arrow
                                        size="sm"
                                        enterDelay={1000}
                                        title="Save and list to channels. This feature is coming soon."
                                      >
                                        <Box sx={{ cursor: 'pointer' }}>
                                          <Button
                                            variant="solid"
                                            onClick={handleList}
                                            endDecorator={
                                              <PublishIcon fontSize="small" />
                                            }
                                            // disabled={true}
                                            loading={listLoading}
                                          >
                                            List
                                          </Button>
                                        </Box>
                                      </Tooltip>
                                    </ButtonGroup>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Box>
                        ) : (
                          <Button
                            variant="solid"
                            onClick={handleSave}
                            sx={{ float: 'right' }}
                            disabled={loading}
                            endDecorator={<Add fontSize="small"></Add>}
                            loading={loading}
                          >
                            Create
                          </Button>
                        )}
                      </Grid>
                    </Grid>,
                    <Grid key={1} container spacing={4}>
                      <Grid item xs={12}>
                        {getProductValue && productTemplate ? (
                          <ProductIntegrations
                            template={productTemplate}
                            product={getProductValue}
                          />
                        ) : (
                          <Box p={3}>
                            <Typography>
                              Please first save the product before creating
                              integrations.
                            </Typography>
                          </Box>
                        )}
                      </Grid>
                    </Grid>,
                    <Grid key={2} container>
                      {productId ? (
                        <Grid item xs={12}>
                          <ProductListingsTable productId={productId} />
                        </Grid>
                      ) : (
                        <Typography>Not Listings</Typography>
                      )}
                    </Grid>,
                  ]}
                ></Tabs>
              </Grid>
            </Grid>
          ) : (
            <Box p={6}>
              <CircularProgress />
            </Box>
          )}
        </Component>
        <Box sx={{ height: '250px' }} />
      </Container>
      <Modal
        open={convertTemplateModelOpen}
        onClose={() => setConvertTemplateModelOpen(false)}
      >
        <ModalDialog sx={{ overflow: 'scroll' }}>
          <ModalClose />
          <Typography level="title-lg">Duplicate to New Template</Typography>

          <Box p={3}>
            <Grid container justifyContent="center" spacing={3}>
              <Grid item xs={12}>
                <Typography level="title-md">
                  Select the Template to convert this product to
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <SingleSelect
                  placeholder={'New Template'}
                  value={selectedConvertTemplateId ? `${SingleSelect}` : ''}
                  helperText={
                    'Missing attributes will be appended to the Template.'
                  }
                  onChange={(newTemplateName) => {
                    if (!newTemplateName) {
                      setSelectedConvertTemplateId(undefined)
                      return
                    }
                    const newTemplate = productTemplates.find(
                      (t) => t.template.name === newTemplateName,
                    )

                    if (!newTemplate) {
                      setSelectedConvertTemplateId(undefined)
                      return
                    }

                    setSelectedConvertTemplateId(newTemplate.template.id)
                  }}
                  options={productTemplates.map((t) => t.template.name)}
                ></SingleSelect>
              </Grid>
            </Grid>
          </Box>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Button
                sx={{ float: 'right' }}
                onClick={async () => {
                  await handleConvertProductTemplate()
                  setConvertTemplateModelOpen(false)
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </ModalDialog>
      </Modal>
      <Modal
        open={priceSuggestionModelOpen}
        onSubmit={handleSetPriceSuggestion}
        onClose={() => setPriceSuggestionModelOpen(false)}
      >
        <ModalDialog sx={{ overflow: 'scroll', p: 4 }} minWidth="md">
          <ModalClose />
          <Typography level="h3">Price Suggestion</Typography>
          <Typography level="body-sm">
            Suggested price from recently completed Ebay sales
          </Typography>

          <Grid container justifyContent="center" spacing={6}>
            <Grid item xs={12}></Grid>

            <Grid item xs={12}>
              <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={12}>
                  <Typography level="h4">Search Terms</Typography>
                  <Typography level="body-xs" sx={{ mb: 1 }}>
                    Ebay search input value
                  </Typography>
                  <ProductDescriptionEditor
                    oneLine={true}
                    richText={true}
                    placeholder="Ebay Search Term"
                    value={priceSuggestionTitle}
                    onChange={(v) => setPriceSuggestionTitle(v)}
                    onUpdate={(v) => setPriceSuggestionTitle(v)}
                    attributeNames={productAttributeNames}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="center">
                    <List
                      dense
                      sx={{
                        width: '100%',
                        bgcolor: 'background.paper',
                        position: 'relative',
                        overflow: 'auto',
                        maxHeight: 250,
                        mt: 1,
                        p: 1,
                        '& ul': { padding: 0 },
                      }}
                    >
                      <ListItem
                        key="select-all"
                        onClick={handleSelectAllPriceSuggestionAttribute}
                        secondaryAction={
                          <Checkbox
                            edge="end"
                            onChange={handleSelectAllPriceSuggestionAttribute}
                            checked={
                              selectedPriceSuggestionAttributes.length ===
                              getProductValue?.attributes.length
                            }
                            inputProps={{ 'aria-labelledby': 'select-all' }}
                          />
                        }
                        disablePadding
                      >
                        <ListItemButton>
                          <ListItemText
                            id={'checkbox-list-secondary-label-select-all'}
                            primary={<strong>Select All</strong>}
                          />
                        </ListItemButton>
                      </ListItem>
                      {priceSuggestion?.aspects.map((aspect) => {
                        const labelId = `checkbox-list-secondary-label-${aspect}`
                        return (
                          <ListItem
                            key={aspect}
                            onClick={() =>
                              handleSelectPriceSuggestionAttribute(aspect)
                            }
                            secondaryAction={
                              <Checkbox
                                edge="end"
                                onChange={() =>
                                  handleSelectPriceSuggestionAttribute(aspect)
                                }
                                checked={
                                  selectedPriceSuggestionAttributes.indexOf(
                                    aspect,
                                  ) !== -1
                                }
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            }
                            disablePadding
                          >
                            <ListItemButton>
                              <ListItemText id={labelId} primary={aspect} />
                            </ListItemButton>
                          </ListItem>
                        )
                      })}
                    </List>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <EbayPriceSuggestion priceSuggestion={priceSuggestion} />
            </Grid>

            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                <Tooltip title="Save selected pricing configuration to Template">
                  <Button
                    size="sm"
                    variant="outlined"
                    onClick={handleSavePricingAspects}
                  >
                    Save
                  </Button>
                </Tooltip>

                <Button
                  size="sm"
                  variant="outlined"
                  onClick={handleFetchPriceSuggestion}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </ModalDialog>
      </Modal>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </div>
  )
}
