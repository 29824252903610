import * as React from 'react'
import Autocomplete from '@mui/joy/Autocomplete'
import Popper from '@mui/material/Popper'
import { ListChildComponentProps, FixedSizeList } from 'react-window'
import Grid from '@mui/material/Grid'
import Button from '@mui/joy/Button'
import { GetProductTemplateAttribute } from '../../../../api/product'
import { useEffect, useState } from 'react'
import AutocompleteListbox from '@mui/joy/AutocompleteListbox'
import Typography from '@mui/joy/Typography'
import Tooltip from '@mui/joy/Tooltip'
import VerticalIconMenu from '../../../common/VerticalIconMenu'
import Modal from '../../../common/Modal'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Input from '@mui/joy/Input'
import Box from '@mui/joy/Box'

const ADD_ALL_MAX_LENGTH = 25
const ADD_ALL_VALUE = '### ADD_ALL ###'
const LISTBOX_PADDING = 8 // px

type ListComponentDataSet = [
  { key: string; tabIndex: number; role: string },
  ExampleOptionValue,
]

interface ExampleOptionValue {
  value?: string
  isInTemplateOptions: boolean | undefined
  group?: string
  totalOptions?: number
}
interface AspectExamplesProps {
  label: string
  options: string[]
  templateAttribute: GetProductTemplateAttribute | undefined
  onChange: (option: string[]) => void
}

export default function AspectExamples({
  label,
  options,
  templateAttribute,
  onChange,
}: AspectExamplesProps): JSX.Element {
  const [optionValues, setOptionValues] = useState<ExampleOptionValue[]>([])

  const [valueMappingModalOpen, setValueMappingModalOpen] =
    useState<boolean>(false)

  const handleMenuClick = (action: string) => {
    if (action === 'Value Mapping') {
      setValueMappingModalOpen(true)
    }
  }

  useEffect(() => {
    const newOptionValues: ExampleOptionValue[] = options.map((option) => {
      const optionExists = templateAttribute
        ? templateAttribute.attributeOptions?.find(
            (opt) => opt.value === option,
          )
        : true // true because it is "in" undefined attribute. Meaning no reason to add.
      return {
        value: option,
        isInTemplateOptions: !!optionExists,
      }
    })

    const notAllOptionsInTemplateOptions = newOptionValues.some(
      (o) => !o.isInTemplateOptions,
    )

    // If attribute is mapped and length is 25 or less, allow add all
    if (templateAttribute && notAllOptionsInTemplateOptions) {
      // add addAll button
      const addAllHeader = {
        value: ADD_ALL_VALUE,
        totalOptions: newOptionValues.length,
        isInTemplateOptions: undefined,
      }
      newOptionValues.unshift(addAllHeader)
    }

    setOptionValues(newOptionValues)
  }, [options, templateAttribute, templateAttribute?.attributeOptions])

  const handleAddAll = () => {
    onChange(options)
  }

  function renderRow(props: ListChildComponentProps) {
    const { data, index, style } = props

    const inlineStyle = {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
      padding: '0px 12px',
    }

    const datas: ListComponentDataSet = data[index]
    const option = datas[1]

    // Add all button
    if (templateAttribute && option.value === ADD_ALL_VALUE) {
      return (
        <ListItem {...props} style={{ ...inlineStyle, padding: 0 }}>
          {option.totalOptions && option.totalOptions > ADD_ALL_MAX_LENGTH ? (
            <Tooltip title="Too many options">
              <Box width="100%">
                <Button
                  variant="soft"
                  disabled={true}
                  fullWidth
                  color="neutral"
                  sx={{ px: '8px' }}
                >
                  Add to Options
                </Button>
              </Box>
            </Tooltip>
          ) : (
            <Button
              onClick={handleAddAll}
              variant="plain"
              fullWidth
              sx={{ px: '8px' }}
            >
              Add to Options
            </Button>
          )}
        </ListItem>
      )
    }

    return (
      <ListItem {...props} style={inlineStyle}>
        {!option.isInTemplateOptions ? (
          <Grid container justifyContent="space-between">
            <Grid
              item
              sx={{
                width: '75%',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                m: 'auto',
              }}
            >
              {option.value}
            </Grid>
            <Grid
              item
              sx={{
                width: '25%',
              }}
            >
              <Button
                size="sm"
                onClick={() => {
                  onChange([option.value || ''])
                }}
                variant="plain"
              >
                Add
              </Button>
            </Grid>
          </Grid>
        ) : (
          option.value
        )}
      </ListItem>
    )
  }

  // function renderRow(props: ListChildComponentProps) {
  //   const { data, index, style } = props
  //   const dataSet = data[index]
  //   const inlineStyle = {
  //     ...style,
  //     top: (style.top as number) + LISTBOX_PADDING,
  //   }

  //   if (dataSet.hasOwnProperty('group')) {
  //     return (
  //       <ListSubheader key={dataSet.key} component="li" style={inlineStyle}>
  //         {dataSet.group}
  //       </ListSubheader>
  //     )
  //   }

  //   return (
  //     <AutocompleteOption {...dataSet[0]} style={inlineStyle}>
  //       {dataSet[1]}
  //     </AutocompleteOption>
  //   )
  // }

  const OuterElementContext = React.createContext({})

  const OuterElementType = React.forwardRef<HTMLDivElement>(
    function OuterElement(props, ref) {
      const outerProps = React.useContext(OuterElementContext)
      return (
        <AutocompleteListbox
          {...props}
          {...outerProps}
          component="div"
          ref={ref}
          sx={{
            '& ul': {
              padding: 0,
              margin: 0,
              flexShrink: 0,
            },
          }}
        />
      )
    },
  )

  // Adapter for react-window
  const ListboxComponent = React.forwardRef<
    HTMLDivElement,
    {
      anchorEl: never
      open: boolean
      modifiers: never[]
    } & React.HTMLAttributes<HTMLElement>
  >(function ListboxComponent(props, ref) {
    const { children, anchorEl, open, modifiers, ...other } = props
    const itemData: Array<unknown> = []
    ;(
      children as [Array<{ children: Array<React.ReactElement> | undefined }>]
    )[0].forEach((item) => {
      if (item) {
        itemData.push(item)
        itemData.push(...(item.children || []))
      }
    })

    const itemCount = itemData.length
    const itemSize = 40

    return (
      <Popper ref={ref} anchorEl={anchorEl} open={open} modifiers={modifiers}>
        <OuterElementContext.Provider value={other}>
          <FixedSizeList
            itemData={itemData}
            height={itemSize * 8}
            width="100%"
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={itemSize}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </FixedSizeList>
        </OuterElementContext.Provider>
      </Popper>
    )
  })

  return (
    <>
      <Autocomplete
        disableListWrap
        slots={{
          listbox: ListboxComponent,
        }}
        placeholder={label}
        options={optionValues}
        renderOption={(props, option) => {
          return [props, option] as React.ReactNode
        }}
        getOptionLabel={(option) => option.value || ''}
        endDecorator={
          <VerticalIconMenu
            size="small"
            options={['Value Mapping']}
            onClick={handleMenuClick}
          />
        }
      />
      <Modal
        title="Value Mapping"
        open={valueMappingModalOpen}
        onClose={() => setValueMappingModalOpen(false)}
      >
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={6}>
            <Typography>
              <strong>Attribute Value</strong>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>Channel Value</strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <List dense={true}>
              {options?.map((option) => (
                <ListItem key={option}>
                  <Grid container justifyContent="space-between" spacing={3}>
                    <Grid item xs={6}>
                      <Input fullWidth={true} placeholder={option}></Input>
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        fullWidth={true}
                        disabled={true}
                        value={option}
                      ></Input>
                    </Grid>
                  </Grid>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </Modal>
    </>
  )
}
