import React, { ReactNode } from 'react'

export default function AttributeInline({
  children,
  size,
  width,
}: {
  children: ReactNode
  size?: 's' | 'm'
  width?: string
}): JSX.Element {
  return (
    <code
      style={{
        lineHeight: 1.9,
        letterSpacing: 0,
        fontFamily: 'Inter',
        fontWeight: 600,
        display: 'inline-block',
        width: width,
        padding: size !== 's' ? '1px 8px' : '2px 4px',
        color: '#1C2025',
        backgroundColor: '#FBFCFE',
        border: '1px solid',
        borderColor: '#CDD7E1',
        borderRadius: '5px',
        fontSize: size !== 's' ? '.95rem' : '0.8125rem',
        direction: 'ltr',
      }}
    >
      {children}
    </code>
  )
}
